<template>
  <div class="replace-visual">
    <div class="replace-visual-title">
      {{ $t('generate.replaceVisuals') }}
      <div v-if="showPoweredByText" class="sub">
        {{ $t('build.step3.poweredByZenSence') }}
      </div>
    </div>
    <div v-if="isLoading" class="spinner text-center loading-spinner_library">
      <v-progress-circular
        :size="50"
        :width="2"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <template
      v-else-if="
        (allowReplaceImageOverall && allowReplaceImageTabs) ||
        (allowReplaceIconOverall && allowReplaceIconTabs)
      "
    >
      <div class="tab-container">
        <v-btn
          v-if="allowReplaceImageOverall && allowReplaceImageTabs"
          class="replaceAssets"
          :class="isImageReplaceSelected ? 'selected-tab' : ''"
          :disabled="!enableReplaceImage"
          @click="toggleReplaceImage(true)"
        >
          <v-icon>
            {{ 'mdi-image-multiple-outline' }}
          </v-icon>
          Images
        </v-btn>
        <v-btn
          v-if="allowReplaceIconOverall && allowReplaceIconTabs"
          class="replaceAssets"
          :class="isImageReplaceSelected ? '' : 'selected-tab'"
          :disabled="!enableReplaceIcon"
          @click="toggleReplaceImage(false), trackIconReplaceMatomo()"
        >
          <v-img
            class="rep-icon"
            v-if="enableReplaceIcon"
            :src="'/assets/img/prezentations/replace-icons.svg'"
          />
          <v-img
            class="rep-icon"
            v-else
            :src="'/assets/img/prezentations/replace-icons-disabled.svg'"
          />
          Icons
        </v-btn>
      </div>
      <div v-if="showNoData" class="no-image">
        <img
          :src="'/assets/img/generate/slide-with-no-visuals.svg'"
          alt="image"
          contain
        />
        <div class="desc">
          <div>{{ $t('generate.currentSlideHaveNoVisuals') }}</div>
          <div v-if="isShellTypeSlide">
            {{ $t('generate.toAddVisualUseDifferentLayout') }}
          </div>
        </div>
        <v-btn
          elevation="2"
          rounded
          color="primary"
          class="primary-btn"
          @click="changeLayout"
          v-if="isShellTypeSlide"
        >
          {{ $t('generate.changeLayout') }}
        </v-btn>
      </div>
      <div v-else-if="enableReplaceImage || enableReplaceIcon">
        <PrezentationReplaceImage
          v-if="showReplaceImageData"
          :selectedSlide="selectedSlideData"
          :updateReplaceImage="updateReplaceImage"
        />
        <PrezentationReplaceIcon
          v-if="showReplaceIconData"
          :selectedSlide="selectedSlideData"
          :updateReplaceIcon="updateReplaceIcon"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import PrezentationReplaceImage from './PrezentationReplaceImage.vue';
import PrezentationReplaceIcon from './PrezentationReplaceIcon.vue';
import { getSlideDataForDetailView } from '../../../../../utils/api-helper';
import {
  doesPrefsObjectHasImage,
  doesPrefsObjectHasIcon,
} from '../../../../utils';
import { trackIconReplacementEvents } from '../../../../common/Analytics/IconReplacementEvents';
import {
  BUILD,
  TD_PLACEMENT,
  PREZENTATION_PLACEMENT,
  TD_SLIDE_SOURCE,
  NA,
  TD_SLIDE_NAME,
  TD_IDEA,
  TD_CONSTRUCT,
  TD_SLIDEID,
  TD_DECK_ID,
  TD_PREZENTATION_CATEGORY,
  TD_PREZNAME,
  TD_PREZTYPE,
} from '../../../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'ChangeVisual',
  components: {
    PrezentationReplaceImage,
    PrezentationReplaceIcon,
  },
  props: {
    origin: {
      type: String,
      default: '',
    },
    updateSlide: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isImageReplaceSelected: true,
      isLoading: false,
      selectedSlideData: {},
      changeTabState: true,
    };
  },
  async beforeMount() {
    this.fetchSlideMetaData();
    this.resetReplaceVisualTabState(true);
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapState('prezentationDetails', [
      'prezentationData',
      'selectedSlide',
      'selectedSlideIndex',
      'actionCompletedInfo',
      'slideActions',
      'resetReplaceVisualTab',
    ]),
    ...mapGetters('users', [
      'allowReplaceImageOverall',
      'allowReplaceIconOverall',
      'allowReplaceImageTabs',
      'allowReplaceIconTabs',
    ]),
    isShellTypeSlide() {
      let changeLayoutAction = false;
      if (this.slideActions.length) {
        changeLayoutAction = !!this.slideActions.find(
          (action) => action.id === 'changeLayout',
        );
      }
      if (
        this.selectedSlide.isSynthesis ||
        this.selectedSlide.source === 'synthesis'
      ) {
        return (
          this.actionCompletedInfo?.synthesisCompleted &&
          this.selectedSlideIndex === 0 &&
          changeLayoutAction
        );
      }
      return changeLayoutAction;
    },
    showPoweredByText() {
      return (
        this.selectedSlideData &&
        (this.selectedSlideData.isAnonymized ||
          this.selectedSlideData.isSynthesis)
      );
    },
    showNoData() {
      return !(
        (this.allowReplaceImageOverall &&
          this.allowReplaceImageTabs &&
          this.enableReplaceImage) ||
        (this.allowReplaceIconOverall &&
          this.allowReplaceIconTabs &&
          this.enableReplaceIcon)
      );
    },
    showReplaceImageData() {
      return (
        this.enableReplaceImage &&
        this.isImageReplaceSelected &&
        this.allowReplaceImageOverall &&
        this.allowReplaceImageTabs
      );
    },
    showReplaceIconData() {
      return (
        this.enableReplaceIcon &&
        !this.isImageReplaceSelected &&
        this.allowReplaceIconOverall &&
        this.allowReplaceIconTabs
      );
    },
    enableReplaceImage() {
      // if (
      //   !this.isFeatureEnabled('replace_image_adobe_library') &&
      //   !this.isFeatureEnabled('replace_image_brand') &&
      //   !this.isFeatureEnabled('replace_image_custom_upload')
      // ) {
      //   return false;
      // }
      if (this.selectedSlide?.mlTags && this.selectedSlide?.mlTags?.has_image) {
        if (typeof this.selectedSlide.mlTags.has_image === 'string') {
          return this.selectedSlide.mlTags.has_image === 'yes';
        }
        return !!this.selectedSlide.mlTags.has_image;
      }
      if (
        this.selectedSlideData?.asset?.prefs ||
        this.selectedSlideData?.prefs
      ) {
        return doesPrefsObjectHasImage(
          this.selectedSlideData?.asset?.prefs || this.selectedSlideData?.prefs,
        );
      }
      return false;
    },
    enableReplaceIcon() {
      // if (
      //   !this.isFeatureEnabled('replace_icon_brand_library') &&
      //   !this.isFeatureEnabled('replace_icon_user_uploaded_icons') &&
      //   !this.isFeatureEnabled('replace_icon_prezent_library')
      // ) {
      //   return false;
      // }
      if (this.selectedSlide?.mlTags && this.selectedSlide?.mlTags?.has_icons) {
        if (typeof this.selectedSlide.mlTags.has_icons === 'string') {
          return this.selectedSlide.mlTags.has_icons === 'yes';
        }
        return !!this.selectedSlide.mlTags.has_icons;
      }

      if (
        this.selectedSlideData?.asset?.prefs ||
        this.selectedSlideData?.prefs
      ) {
        return doesPrefsObjectHasIcon(
          this.selectedSlideData?.asset?.prefs || this.selectedSlideData?.prefs,
        );
      }
      return false;
    },
  },
  methods: {
    ...mapActions('prezentationDetails', [
      'setIsReplaceImgSelected',
      'setSelectedSlideAction',
      'resetReplaceVisualTabState',
    ]),
    isFeatureEnabled(key) {
      return this.currentUser?.features?.find((f) => f.feature_name === key)
        ?.enabled;
    },
    changeLayout() {
      this.setSelectedSlideAction({
        id: 'changeLayout',
        labelText: 'Change layout',
        tooltipText: 'Change layout',
        icon: 'mdi-view-split-vertical',
        isCustomIcon: false,
      });
    },
    toggleReplaceImage(isReplaceImage) {
      this.isImageReplaceSelected = isReplaceImage;
      this.setIsReplaceImgSelected(isReplaceImage);
    },
    trackIconReplaceMatomo() {
      trackIconReplacementEvents.iconReplacementClickOnReplaceIcon(
        this.selectedSlideData,
        this.currentUser,
        {
          [TD_IDEA]: this.selectedSlideData?.category,
          [TD_CONSTRUCT]:
            this.selectedSlideData?.constructs &&
            this.selectedSlideData?.constructs[0]
              ? this.selectedSlideData?.constructs[0]
              : '',
          [TD_SLIDEID]: this.getSlideId(this.selectedSlideData),
          [TD_DECK_ID]: this.getDeckId(this.selectedSlideData),
          [TD_PREZTYPE]: this.prezentationData?.type,
          [TD_PREZENTATION_CATEGORY]: this.prezentationData?.prezentationType,
          [TD_PREZNAME]: this.prezentationData?.name,
          [TD_PLACEMENT]:
            this.origin === BUILD ? BUILD : PREZENTATION_PLACEMENT,
          [TD_SLIDE_SOURCE]: this.getSlideSource(this.selectedSlideData),
          [TD_SLIDE_NAME]: this.selectedSlideData?.name,
        },
      );
    },
    updateReplaceImage(type, data, callback) {
      this.updateSlide(type, data, callback);
    },
    getSlideSource(slideData) {
      if (!slideData) {
        return NA;
      }
      if (slideData.source !== undefined) {
        return slideData.source;
      }
      if (slideData?.prefs?.source) {
        return slideData?.prefs?.source;
      }
      return 'Source not available';
    },
    getDeckId(slideData) {
      let uniqueID;
      if (!slideData) {
        return NA;
      }

      if (slideData?.prefs?.is_know === 'yes') {
        ({ unique_id: uniqueID } = slideData);
      } else {
        ({ uniqueID } = slideData);
      }
      if (!uniqueID?.includes('deck')) {
        return 'Deck ID not available';
      }
      return uniqueID?.substring(0, uniqueID.indexOf('deck') + 5);
    },
    getSlideId(slideData) {
      let uniqueID;
      if (!slideData) {
        return NA;
      }
      if (slideData?.prefs?.is_know === 'yes') {
        ({ unique_id: uniqueID } = slideData);
      } else {
        ({ uniqueID } = slideData);
      }
      if (['redesign', 'generated', 'uploaded'].includes(slideData?.source))
        return uniqueID;
      return uniqueID?.split('deck1_')[1]?.split('_')[0];
    },
    async fetchSlideMetaData() {
      this.selectedSlideData = {
        ...this.selectedSlide,
      };

      if (
        this.selectedSlide &&
        !(
          this.selectedSlide?.assetId?.includes('public/') ||
          this.selectedSlide?.assetId?.includes('private/') ||
          this.selectedSlide?.assetId?.includes('protected/')
        ) &&
        this.selectedSlide?.prefs?.is_know !== 'yes'
      ) {
        this.isLoading = true;
        let slideExtraData = {};
        let { originalUniqueID } = this.selectedSlide;

        if (
          this.selectedSlide?.assetId?.includes('newImageReplaceOutput/') &&
          originalUniqueID
        ) {
          originalUniqueID = originalUniqueID?.includes('replaced-image/')
            ? originalUniqueID.replaceAll('replaced-image/', '')
            : originalUniqueID;

          if (originalUniqueID) {
            await getSlideDataForDetailView(originalUniqueID)
              .then((res) => {
                slideExtraData = res;
                this.selectedSlideData = {
                  ...this.selectedSlide,
                  prefs: slideExtraData?.data?.prefs,
                };
              })
              .finally(() => {
                this.handleState();
              });
          } else {
            setTimeout(() => {
              this.handleState();
            }, 1000);
          }
        } else if (this.selectedSlide?.uniqueID) {
          await getSlideDataForDetailView(this.selectedSlide?.uniqueID)
            .then((res) => {
              slideExtraData = res;
              this.selectedSlideData = {
                ...this.selectedSlide,
                prefs: slideExtraData?.data?.prefs,
              };
            })
            .finally(() => {
              this.handleState();
            });
        } else {
          setTimeout(() => {
            this.handleState();
          }, 1000);
        }
      } else if (
        this.selectedSlide &&
        (this.selectedSlide?.assetId?.includes('protected/') ||
          this.selectedSlide?.assetId?.includes('private/'))
      ) {
        this.handleState();
      }
    },
    handleState() {
      if (this.changeTabState) {
        if (
          this.allowReplaceImageOverall &&
          this.allowReplaceImageTabs &&
          this.enableReplaceImage
        ) {
          this.toggleReplaceImage(true);
        } else if (this.enableReplaceIcon) {
          this.toggleReplaceImage(false);
        }
      }
      this.isLoading = false;
    },
    updateReplaceIcon(type, data, callback) {
      this.resetReplaceVisualTabState(false);
      this.updateSlide(type, data, callback);
    },
  },
  watch: {
    selectedSlide(val) {
      if (val) {
        this.changeTabState = this.resetReplaceVisualTab;
        this.fetchSlideMetaData();
      }
    },
    resetReplaceVisualTab(val) {
      // To check if selected tab needs to be retained
      this.changeTabState = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.replace-visual {
  height: 100%;

  .loading-spinner {
    height: calc(100% - 42px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .no-image {
    display: flex;
    gap: 24px;
    align-items: center;
    height: calc(100% - 42px);
    flex-direction: column;
    margin-top: 36px;

    .desc {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      color: #000000;
    }

    .primary-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 16px;
      gap: 8px;
      height: 32px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      text-transform: capitalize;
      letter-spacing: normal;
      line-height: 19px;

      /* Color Styles (Old)/White */

      color: #ffffff;

      background: #21a7e0;
      border-radius: 25px;
    }
  }
  .replace-visual-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    .sub {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }

  .replace-visual-verbose {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
  }

  .select-image {
    align-items: center;
    display: flex;
    padding-top: 2px;
    max-width: 100%;

    .select-image__item {
      border: solid $light-gray 1px;
      border-radius: 8px;
      box-shadow: $item-box-shadow;
      min-width: 100px;

      &:hover {
        box-shadow: $item-box-shadow-hover;
        cursor: pointer;
      }
    }

    .selected {
      border: 2px solid $zen-blue-default;
    }
  }
}
.replaceAssets {
  margin: 0px 16px 0px 0px;
  box-shadow: none;
  background-color: transparent !important;
  letter-spacing: normal;
  text-transform: none;
  height: 32px !important;
  //color: #21a7e0;
  padding: 0;
  font-size: 16px !important;
  border: 1px solid #697077;
  .v-btn__content {
    font-size: 14px !important;

    .v-icon {
      font-size: 14px !important;
      padding-right: 5px;
    }
  }
}
::v-deep .v-btn--disabled {
  // background-color: transparent !important;
  border: rgba(0, 0, 0, 0.26) !important;
}
.tab-container {
  padding-top: 20px;
  padding-bottom: 20px;
}
.selected {
  .image-item {
    border: 2px solid #21a7e0;
  }
}

.selected-tab {
  background-color: #e1f5fb !important;
  border: none;
}
.spinner {
  margin-top: 20px;
}
.rep-icon {
  margin-right: 5px;
}
</style>
