<template>
  <div class="synthesize-container">
    <template v-if="isSlidesLimitCrosses">
      <div class="heading-block">
        <div class="heading-block__main">Synthesis</div>
        <div class="heading-block__sub">Powered by ASTRID™ AI</div>
      </div>
      <div
        class="suggest-block__summary-text"
        :style="{
          marginTop: '80px',
        }"
      >
        <img src="/assets/img/compliance-check-failed.svg" alt />
        <br /><br />
        <div class="error-container">
          <div class="error-text">
            Synthesis is optimized for decks with up to 30 slides. Please
            consider removing some slides from your presentation and try again.
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <Tone
        v-if="selectedSlideAction.id === 'changeTone'"
        :tone="execSummary.tone"
        @change="onToneChange"
      />
      <Emotion
        v-else-if="selectedSlideAction.id === 'changeEmotion'"
        :emotion="execSummary.emotion"
        @change="onEmotionChange"
      />
      <DataStyle
        v-else-if="selectedSlideAction.id === 'dataStyle'"
        :dataStyle="defaultDataStyle"
        @change="onDataStyleChange"
      />
      <NodeCount
        v-else-if="selectedSlideAction.id === 'changeNodeCount'"
        :nodeCount="execSummary.nodeCount"
        :siblings="execSummary.siblings"
        :origin="'synthesis'"
        @change="onNodeCountChange"
      />

      <ChangeLayout
        v-else-if="selectedSlideAction.id === 'changeLayout'"
        :relatedSlides="execSummary.relatedSlides"
        @change="handleConfirmation"
        :origin="'Synthesis'"
      />
      <SummaryFormats
        v-else
        :buttonText="
          selectedMainAction.id === 'synthesize' ? 'Synthesize' : undefined
        "
        :selectedSummary="execSummary.format"
        @change="onFormatChange"
      />

      <ConfirmationDialog
        :show="showConfirmation"
        :on-primary-click="onDialogCancel"
        :on-secondary-click="onDialogConfirm"
        :on-close="onDialogCancel"
        :primary-btn-text="replaceVisualCancel"
        :secondary-btn-text="dialogPrimary"
        :title="dialogTitle"
        :text="dialogText"
      />
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  getFeatureUsage,
  postFeatureUsage,
} from '../../../../../utils/api-helper';
import { callMergeSlidesApi } from '../../../../../utils/merge-slide-helper';
import {
  TD_AUD,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_EMOTION_SELECTED,
  TD_FORMAT_SELECTED,
  TD_NODECOUNT,
  TD_NODE_EDITED_TO,
  TD_PLACEMENT,
  TD_PREZNAME,
  TD_REPLACE_IMAGE_SOURCE,
  TD_TEMPLATE,
  TD_TEMPLATE_SWITCHED,
  TD_TONE_SELECTED,
} from '../../../../common/Analytics/MatomoTrackingDataHelper';
import { trackSynthesisEvents } from '../../../../common/Analytics/SynthesisEvents';
import ConfirmationDialog from '../../../../common/ConfirmationDialog.vue';
import ChangeLayout from '../../../GenerateSlides/ChangeLayout.vue';
import NodeCount from '../../../GenerateSlides/NodeCount.vue';
import DataStyle from '../../../Synthesize/DataStyle.vue';
import Emotion from '../../../Synthesize/Emotion.vue';
import SummaryFormats from '../../../Synthesize/SummaryFormats.vue';
import { SynthesizeService } from '../../../Synthesize/SynthesizeService';
import {
  SlideDataStyle,
  SlideEmotion,
  SlideTone,
  SummaryFormat,
  SummaryFormatMetadata,
  SynthesizeOperation,
} from '../../../Synthesize/SynthesizeUtills';
import Tone from '../../../Synthesize/Tone.vue';
import { slideLimit } from '../../config/actionConfig';

export default {
  components: {
    SummaryFormats,
    Tone,
    Emotion,
    DataStyle,
    NodeCount,
    ConfirmationDialog,
    ChangeLayout,
  },
  props: {
    updatePrezentation: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      changeReq: null,
      showConfirmation: false,
      replaceVisualCancel: '',
      dialogPrimary: '',
      dialogTitle: '',
      prezOrSlideId: null,
      dialogText: '',
      summaryFormats: {
        [SummaryFormat.ContextCore]: null,
        [SummaryFormat.CurrentStatus]: null,
        [SummaryFormat.SituationComplication]: null,
        [SummaryFormat.What]: null,
        [SummaryFormat.SinceWeSpoke]: null,
        [SummaryFormat.SimplePointers]: null,
      },
      prezentationPath: null,
      selectedSlides: [],
      slides: [],
    };
  },
  provide() {
    return {
      getSummaryFormats: () => this.summaryFormats,
    };
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'typesenseSelfAudience',
      'limitsInfoObj',
      'themes',
    ]),
    ...mapState('prezentationDetails', [
      'prezentationOriginalData',
      'prezentationData',
      'selectedSlideAction',
      'selectedMainAction',
      'execSummary',
    ]),
    isSlidesLimitCrosses() {
      return this.prezentationData.totalSlides > slideLimit.synthesize;
    },
    synthesizeService() {
      return new SynthesizeService();
    },
    getDefaultAudience() {
      return {
        id: this.typesenseSelfAudience.id,
        num_id: this.typesenseSelfAudience.num_id,
        type: this.typesenseSelfAudience.audienceType,
        ownerID: this.typesenseSelfAudience.ownerID,
        has_image: 1,
        color_type:
          this.typesenseSelfAudience.question2 === 'leftImage' ? 'high' : 'low',
        has_cartoon:
          this.typesenseSelfAudience.question3 === 'leftImage' ? 0 : 1,
        has_tables:
          this.typesenseSelfAudience.question6 === 'leftImage' ? 1 : 0,
        has_graphs:
          this.typesenseSelfAudience.question6 === 'rightImage' ? 1 : 0,
      };
    },
    defaultDataStyle() {
      let dataStyle = '';
      if (this.execSummary?.dataStyle) {
        dataStyle = this.execSummary.dataStyle;
      } else {
        dataStyle = SlideDataStyle.Objective;
      }
      return dataStyle;
    },
  },
  beforeDestroy() {
    const presentation = JSON.parse(JSON.stringify(this.prezentationData));
    const firstSlide = presentation.sections.list[0].slides[0];

    if (firstSlide.isError) {
      presentation.sections.list[0].slides.shift();
    }
    this.updatePrezentationData(presentation);
  },
  methods: {
    ...mapActions('users', [
      'setCurrentUser',
      'setLimitData',
      'setUpgradePopup',
    ]),
    ...mapActions('prezentationDetails', [
      'updatePrezentationData',
      'updatePartialPrezentationData',
      'setLoadingAnimations',
      'setComplyDetails',
      'setPrezentationData',
      'setSelectedMainAction',
      'setExecSummary',
      'resetExecSummary',
      'updateActionCompletedInfo',
    ]),
    getFormat(format, node) {
      if (format === SummaryFormat.SimplePointers) {
        return node ? `${node} ${format}` : `5 ${format}`;
      }
      if (format?.includes(SummaryFormat.SimplePointers)) {
        return node ? `${node} ${format}` : format;
      }
      return format || undefined;
    },
    async startSynthesize(contextOptions = {}) {
      this.setLoadingAnimations({
        isLoading: true,
        animationsData: {
          isVideo: true,
          showBlast: false,
          type: 'synthesizeVideoLoading',
        },
      });
      this.setExecSummary({ ...this.execSummary, loading: true });
      const s = {
        sections: this.prezentationOriginalData.sections,
        outputBucket: process.env.VUE_APP_MVP_ASSETS_BUCKET,
      };
      await callMergeSlidesApi(s)
        .then((res) => {
          this.prezentationPath = `${res.data}`;
        })
        .catch((err) => {
          console.log(err);
        });

      if (
        this.changeReq &&
        this.changeReq.type !== SynthesizeOperation.LayoutChange &&
        this.changeReq.type !== SynthesizeOperation.NodeChange
      ) {
        await this.synthesizeService.cancel();
      }
      let isFirstCall = false;
      if (this.selectedMainAction.id === 'synthesize') {
        isFirstCall = true;
      }
      let slide = null;
      const selectedSlides = [];
      const slides = [];
      let count = 0;
      this.prezentationData.sections.list.forEach((section) => {
        section.slides.forEach((slideData) => {
          selectedSlides.push(count);
          slides.push(slideData);
          if (slideData && !slide) {
            slide = slideData;
          }
          count++;
        });
      });
      //   let otherData = {};
      //   if (!isFirstCall) {
      //     otherData = {
      //       tone: contextOptions.tone || this.execSummary.tone || undefined,
      //       operation: this.changeReq ? this.changeReq.type : undefined,
      //       override: { workflow: this.execSummary.id },
      //     };
      //   }
      this.selectedSlides = selectedSlides;
      this.slides = slides;
      const context = {
        s3_path: this.prezentationPath,
        s3_bucket: process.env.VUE_APP_MVP_ASSETS_BUCKET,
        slide_indexes: selectedSlides,
        company: this.currentUser.company.name,
        template: this.prezentationData.theme,
        audience: this.getDefaultAudience,
        dataStyle: SlideDataStyle.Objective,
        ...(this.changeReq && {
          summary_format:
            isFirstCall &&
            contextOptions.summary_format === SummaryFormat.SimplePointers
              ? undefined
              : this.getFormat(
                  contextOptions.summary_format || this.execSummary.format,
                  contextOptions.nodeCount || this.execSummary.nodeCount,
                ),

          emotion: contextOptions.emotion || this.execSummary.emotion,
          dataStyle:
            contextOptions.dataStyle ||
            this.execSummary?.dataStyle ||
            SlideDataStyle.Objective,
          tone: contextOptions.tone || this.execSummary.tone || undefined,
          operation: this.changeReq ? this.changeReq.type : undefined,
          override: { workflow: this.execSummary.id },
          ...(this.changeReq.type === SynthesizeOperation.NodeChange && {
            node: contextOptions.nodeCount,
            slide_id: contextOptions.slideID,
          }),
          ...(this.changeReq.type === SynthesizeOperation.LayoutChange && {
            slide_id: contextOptions.slideID,
            exec_metadata: {
              ...this.execSummary.meta,
              category: contextOptions.category,
              source_path: contextOptions.source,
            },
          }),
        }),
      };
      try {
        const { jobId, slideOutput, time } = await this.synthesizeService.start(
          context,
          null,
          'presentation_synthesis',
        );
        const execSummary = await this.synthesizeService.fetchSlideMetadata(
          {
            ...slideOutput,
            format:
              context.summary_format ||
              slideOutput.suggested_summary_format ||
              this.execSummary.format,
          },
          this.currentUser,
          this.changeReq,
        );

        const { slideDetails } = execSummary;

        const slideFinalData = {
          ...slideDetails,
          parentID: slideDetails.parent
            ? slideDetails.parent.unique_id
            : slideDetails.unique_id,
          assetId: execSummary.thumbnail,
          filename: execSummary.slide_path,
          outline: slideDetails.title || slideDetails.categories[0],
          thumbnail: execSummary.landscape,
          uniqueID: slideDetails.unique_id,
          isSynthesis: true,
        };
        const presentation = JSON.parse(JSON.stringify(this.prezentationData));
        presentation.action = {
          operation: 'Synthesis',
          level: 'Deck',
        };
        const firstSlide = presentation.sections.list[0].slides[0];

        if (isFirstCall && !firstSlide.isError) {
          presentation.sections.list[0].slides = [
            slideFinalData,
            ...this.prezentationData.sections.list[0].slides,
          ];
          this.resetExecSummary();
        } else {
          presentation.sections.list[0].slides[0] = slideFinalData;
        }
        await this.updatePrezentation(presentation);
        this.updateActionCompletedInfo({
          synthesisCompleted: true,
        });
        if (isFirstCall) {
          this.setSelectedMainAction({
            id: 'editSlide',
            labelText: 'Edit slide',
            tooltipText: 'Edit slide',
            icon: 'mdi-file-edit-outline',
            isCustomIcon: false,
            dataPendoId: 'story-builder-edit-slide',
          });
        }

        execSummary.format = this.getFormat(
          context.summary_format ||
            slideOutput.suggested_summary_format ||
            this.execSummary.format,
        );
        execSummary.tone =
          contextOptions.tone || this.execSummary.tone || SlideTone.Neutral;
        execSummary.emotion =
          contextOptions.emotion ||
          this.execSummary.emotion ||
          SlideEmotion.Optimistic;
        execSummary.dataStyle =
          contextOptions.dataStyle || this.defaultDataStyle;
        // execSummary.audience = contextOptions.audience;
        execSummary.id = jobId;
        if (this.changeReq?.type === SynthesizeOperation.LayoutChange) {
          execSummary.relatedSlides = this.execSummary.relatedSlides;
        }

        this.setExecSummary({ ...execSummary, loading: false });
        this.$set(this.summaryFormats, execSummary.format, execSummary);
        const payload = {
          identifiedTemplate: this.execSummary.slideDetails?.prefs?.theme,
          initialUserSelectedTemplate: this.prezentationData.theme,
          initialAudience: this.getDefaultAudience.id,
          initialSummaryFormat: this.execSummary.format,
          initialSlideTone: this.execSummary.tone,
          initialContentEmotion: this.execSummary.emotion,
          initialNodeCount: Number(this.execSummary.nodeCount),
          timeTakenSeconds: time,
          initialExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
          initialExecutiveSummarySlidePptx: this.execSummary.slide_path,
          intialDataStyle:
            this.execSummary.dataStyle || SlideDataStyle.Objective,
          synthesisStatus: 'Success',
          totalSlideCount: this.selectedSlides.length || undefined,
          totalSlideInDeck: this.selectedSlides.length || undefined,
        };

        try {
          if (!this.recordId) {
            await this.handleCreateRecord(payload);
          } else if (!this.changeReq?.type) {
            await this.handleUpdateRecord(payload);
          } else if (
            [
              SynthesizeOperation.NodeChange,
              SynthesizeOperation.ToneChange,
              SynthesizeOperation.EmotionChange,
            ].includes(this.changeReq?.type)
          ) {
            const payloads = {
              finalExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
              finalExecutiveSummarySlidePptx: this.execSummary.slide_path,
              synthesisStatus: 'Success',
            };
            switch (this.changeReq?.type) {
              case SynthesizeOperation.ToneChange: {
                payloads.changeToneStatus = true;
                break;
              }
              case SynthesizeOperation.EmotionChange: {
                payloads.changeEmotionStatus = true;
                break;
              }
              case SynthesizeOperation.NodeChange: {
                payloads.editNodeCountStatus = true;
                break;
              }
              default:
            }

            await this.handleUpdateRecord(payloads);
          } else {
            this.updateStatsOnResynthesize();
          }
        } catch (error) {
          console.log(error);
        }

        this.setLoadingAnimations({
          isLoading: false,
          animationsData: {},
        });
      } catch (error) {
        console.log(error);
        const presentation = JSON.parse(JSON.stringify(this.prezentationData));
        const firstSlide = presentation.sections.list[0].slides[0];

        if (isFirstCall && !firstSlide.isError) {
          presentation.sections.list[0].slides = [
            {
              assetId: '/assets/img/synthesize/synthesis_failed.svg',
              thumbnail: '/assets/img/synthesize/synthesis_failed.svg',
              isError: true,
            },
            ...this.prezentationData.sections.list[0].slides,
          ];
        } else {
          presentation.sections.list[0].slides[0] = {
            ...this.prezentationData.sections.list[0].slides[0],
            thumbnail: '/assets/img/synthesize/synthesis_failed.svg',
            isError: true,
          };
        }
        this.updatePrezentationData(presentation);

        this.setLoadingAnimations({
          isLoading: false,
          animationsData: {},
        });
      }
    },

    async updateStatsOnResynthesize() {
      switch (this.changeReq.type) {
        case SynthesizeOperation.AudienceChange: {
          await this.handleUpdateRecord({
            changeAudienceStatus: true,
            finalUserSelectedAudience: this.getDefaultAudience.id,
            finalExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
            finalExecutiveSummarySlidePptx: this.execSummary.slide_path,
          });
          break;
        }
        case SynthesizeOperation.ToneChange: {
          await this.handleUpdateRecord({
            changeToneStatus: true,
            finalExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
            finalExecutiveSummarySlidePptx: this.execSummary.slide_path,
          });
          break;
        }
        case SynthesizeOperation.EmotionChange: {
          await this.handleUpdateRecord({
            changeEmotionStatus: true,
            finalExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
            finalExecutiveSummarySlidePptx: this.execSummary.slide_path,
          });
          break;
        }
        case SynthesizeOperation.FormatChange: {
          await this.handleUpdateRecord({
            changeFormatStatus: true,
            finalFormatSelectedByUser: this.execSummary.format,
            finalExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
            finalExecutiveSummarySlidePptx: this.execSummary.slide_path,
          });
          break;
        }
        case SynthesizeOperation.ReplaceVisualChange: {
          await this.handleUpdateRecord({
            replaceVisualStatus: true,
            finalExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
            finalExecutiveSummarySlidePptx: this.execSummary.slide_path,
          });
          break;
        }
        case SynthesizeOperation.TemplateChange: {
          await this.handleUpdateRecord({
            switchTemplateStatus: true,
            finalUserSelectedTemplate: this.selectedTemplate.code,
            finalExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
            finalExecutiveSummarySlidePptx: this.execSummary.slide_path,
          });
          break;
        }
        case SynthesizeOperation.NodeChange: {
          await this.handleUpdateRecord({
            editNodeCountStatus: true,
            finalExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
            finalExecutiveSummarySlidePptx: this.execSummary.slide_path,
          });
          break;
        }
        case SynthesizeOperation.LayoutChange: {
          await this.handleUpdateRecord({
            changeLayoutStatus: true,
            finalExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
            finalExecutiveSummarySlidePptx: this.execSummary.slide_path,
          });
          break;
        }
        case SynthesizeOperation.DataStyleChange:
          await this.handleUpdateRecord({
            changeDataStyleStatus: true,
            finalDataStyle: this.execSummary.dataStyle,
            finalExecutiveSummarySlideThumbnail: this.execSummary.thumbnail,
            finalExecutiveSummarySlidePptx: this.execSummary.slide_path,
          });
          break;
        default:
      }
    },
    async handleCreateRecord(data = {}) {
      this.form = {
        isLiked: null,
        feedback: '',
      };
      const payload = {
        origin: 'Prezentation',
        uploadedPrezentation: this.prezentationPath,
        uploadedPrezentationThumbnail: this.selectedSlides?.length
          ? JSON.stringify(this.slides.map((slide) => slide.thumbnail))
          : undefined,
        totalSlideCount: this.selectedSlides.length || undefined,
        totalSlideInDeck: this.selectedSlides.length || undefined,
        ...data,
      };
      const id = await this.synthesizeService.createStats(payload);
      this.recordId = id;
    },
    async handleUpdateRecord(updates) {
      await this.synthesizeService.updateStats(this.recordId, updates);
    },

    async handleConfirmation(event) {
      let replaceSource = '';
      let matomoReplaceSource = '';
      this.changeReq = event;
      if (
        this.changeReq.type === SynthesizeOperation.AudienceChange ||
        this.changeReq.type === SynthesizeOperation.TemplateChange
      ) {
        this.summaryFormats = {
          [SummaryFormat.ContextCore]: null,
          [SummaryFormat.CurrentStatus]: null,
          [SummaryFormat.SituationComplication]: null,
          [SummaryFormat.What]: null,
          [SummaryFormat.SinceWeSpoke]: null,
          [SummaryFormat.SimplePointers]: null,
        };
      }
      let slide = null;
      this.prezentationData.sections.list.forEach((section) => {
        section.slides.forEach((slideData) => {
          if (slideData && !slide) {
            slide = slideData;
          }
        });
      });

      switch (event.type) {
        case SynthesizeOperation.AudienceChange: {
          this.audience = event.val;
          this.startSynthesize({ audience: event.val });
          const otherData = {
            [TD_PREZNAME]: slide.name,
            [TD_TEMPLATE]: this.prezentationData.theme,
            [TD_AUD]: this.audience.email,
            [TD_PLACEMENT]: this.placement,
          };
          trackSynthesisEvents.synthesisAudienceChanged(
            this.currentUser,
            otherData,
          );
          break;
        }
        case SynthesizeOperation.ToneChange: {
          this.execSummary.tone = event.data.id;
          this.startSynthesize({ tone: event.data.id });
          const otherData = {
            [TD_PREZNAME]: slide.name,
            [TD_TEMPLATE]: this.prezentationData.theme,
            [TD_AUD]: this.audience
              ? this.audience.email
              : this.prezentationData.audienceID,
            [TD_PLACEMENT]: this.placement,
            [TD_COMMON_COLUMN_NAME]: TD_TONE_SELECTED,
            [TD_COMMON_COLUMN_VALUE]: event.data.id,
          };
          trackSynthesisEvents.synthesisToneChanged(
            this.currentUser,
            otherData,
          );
          break;
        }
        case SynthesizeOperation.EmotionChange: {
          this.execSummary.emotion = event.data.id;
          this.startSynthesize({ emotion: event.data.id });
          const otherData = {
            [TD_PREZNAME]: slide.name,
            [TD_TEMPLATE]: this.prezentationData.theme,
            [TD_AUD]: this.audience
              ? this.audience.email
              : this.prezentationData.audienceID,
            [TD_PLACEMENT]: this.placement,
            [TD_COMMON_COLUMN_NAME]: TD_EMOTION_SELECTED,
            [TD_COMMON_COLUMN_VALUE]: event.data.id,
          };
          trackSynthesisEvents.synthesisEmotionChanged(
            this.currentUser,
            otherData,
          );
          break;
        }
        case SynthesizeOperation.FormatChange: {
          this.checkingUsage = true;
          await getFeatureUsage()
            .then((response) => {
              if (response?.data) {
                this.setLimitData(response.data);
              }
              this.checkingUsage = false;
            })
            .catch((error) => {
              this.checkingUsage = false;
              console.log(error);
            });
          if (
            !this.limitsInfoObj?.synthesis?.isLimitCrossed ||
            this.limitsInfoObj?.synthesis?.isFullAccess
          ) {
            postFeatureUsage({ feature: 'synthesis' })
              .then((response) => {
                console.log(response);
                if (response?.data) {
                  this.setLimitData(response.data);
                }
              })
              .catch((error) => console.log(error));
            if (this.summaryFormats[event.value]) {
              this.setExecSummary({
                ...this.execSummary,
                ...this.summaryFormats[event.value],
                loading: false,
              });
            } else {
              this.startSynthesize({ summary_format: event.value });
            }
          } else {
            this.setSelectedMainAction({});
            this.setUpgradePopup({
              upgradePopup: true,
              popupType: 'synthesis',
            });
          }
          const otherData = {
            [TD_PREZNAME]: slide.name,
            [TD_TEMPLATE]: this.prezentationData.theme,
            [TD_AUD]: this.audience
              ? this.audience.email
              : this.prezentationData.audienceID,
            [TD_PLACEMENT]: this.placement,
            [TD_COMMON_COLUMN_NAME]: TD_FORMAT_SELECTED,
            [TD_COMMON_COLUMN_VALUE]: SummaryFormatMetadata[event.value].label
              .split(',')
              .join('_'),
          };
          trackSynthesisEvents.synthesisFormatChanged(
            this.currentUser,
            otherData,
          );
          break;
        }
        case SynthesizeOperation.ReplaceVisualChange: {
          this.setExecSummary({
            ...this.execSummary,
            slide_path: this.changeReq.path,
            thumbnail: this.changeReq.thumbnail,
            landscape: this.changeReq.landscape,
            loading: false,
          });
          this.updateStatsOnResynthesize();
          this.$set(
            this.summaryFormats,
            this.execSummary.format,
            this.execSummary,
          );
          // setTimeout(() => {
          //   this.loading = null;
          // }, 3500);
          replaceSource = this.$refs.replaceVisual?.imageToReplace?.source;
          if (replaceSource === 'adobe' || replaceSource === 'freepik')
            matomoReplaceSource = 'Library';
          if (replaceSource === 'brand-images')
            matomoReplaceSource = this.currentUser?.company?.displayName;
          if (replaceSource === 'upload') matomoReplaceSource = 'Upload';
          const otherData = {
            [TD_PREZNAME]: slide.name,
            [TD_TEMPLATE]: this.prezentationData.theme,
            [TD_REPLACE_IMAGE_SOURCE]: matomoReplaceSource,
            [TD_AUD]: this.audience
              ? this.audience.email
              : this.prezentationData.audienceID,
            [TD_PLACEMENT]: this.placement,
          };
          trackSynthesisEvents.synthesisVisualsReplaced(
            this.currentUser,
            otherData,
          );
          this.isAddedToLibrary = false;
          this.isAddedAsFavorite = false;
          break;
        }
        case SynthesizeOperation.TemplateChange: {
          const otherData = {
            [TD_PREZNAME]: slide.name,
            [TD_TEMPLATE]: this.previousTemplate,
            [TD_AUD]: this.audience
              ? this.audience.email
              : this.prezentationData.audienceID,
            [TD_PLACEMENT]: this.placement,
            [TD_COMMON_COLUMN_NAME]: TD_TEMPLATE_SWITCHED,
            [TD_COMMON_COLUMN_VALUE]: event.val.code,
          };
          trackSynthesisEvents.synthesisTemplateSwitched(
            this.currentUser,
            otherData,
          );
          this.startSynthesize({ template: event.val });
          break;
        }
        case SynthesizeOperation.NodeChange: {
          this.startSynthesize({
            nodeCount: event.slide.prefs.node,
            slideID: event.slide.unique_id,
          });
          const otherData = {
            [TD_PREZNAME]: slide.name,
            [TD_TEMPLATE]: this.prezentationData.theme,
            [TD_AUD]: this.audience
              ? this.audience.email
              : this.prezentationData.audienceID,
            [TD_PLACEMENT]: this.placement,
            [TD_NODECOUNT]: this.execSummary.nodeCount,
            [TD_COMMON_COLUMN_NAME]: TD_NODE_EDITED_TO,
            [TD_COMMON_COLUMN_VALUE]: event.slide.prefs.node,
          };
          trackSynthesisEvents.synthesisNodeEdited(this.currentUser, otherData);
          break;
        }
        case SynthesizeOperation.LayoutChange: {
          this.startSynthesize({
            slideID: event.slide.unique_id,
            category: event.slide.categories[0],
            source: event.slide.filename,
          });
          const otherData = {
            [TD_PREZNAME]: slide.name,
            [TD_TEMPLATE]: this.prezentationData.theme,
            [TD_AUD]: this.audience
              ? this.audience.email
              : this.prezentationData.audienceID,
            [TD_PLACEMENT]: this.placement,
          };
          trackSynthesisEvents.synthesisLayoutChanged(
            this.currentUser,
            otherData,
          );
          break;
        }
        case SynthesizeOperation.DataStyleChange:
          this.execSummary.dataStyle = event.data.id;
          this.startSynthesize({ dataStyle: event.data.id });
          break;
        default:
      }
    },

    onToneChange(change) {
      this.showConfirmation = true;
      this.changeReq = change;
      this.dialogTitle = this.$t('synthesize.tone.popupTitle');
      this.dialogText = this.$t('synthesize.tone.popupText');
      this.dialogPrimary = this.$t('synthesize.tone.popupTitle');
      this.replaceVisualCancel = this.$t('common.cancel');
    },
    onEmotionChange(change) {
      this.showConfirmation = true;
      this.changeReq = change;
      this.dialogTitle = this.$t('synthesize.emotion.popupTitle');
      this.dialogText = this.$t('synthesize.emotion.popupText');
      this.dialogPrimary = this.$t('synthesize.emotion.popupTitle');
      this.replaceVisualCancel = this.$t('common.cancel');
    },
    onNodeCountChange(change) {
      this.showConfirmation = true;
      this.changeReq = change;
      this.dialogTitle = this.$t('synthesize.nodeCount.popupTitle');
      this.dialogText = this.$t('synthesize.nodeCount.popupText');
      this.dialogPrimary = this.$t('synthesize.nodeCount.popupTitle');
      this.replaceVisualCancel = this.$t('common.cancel');
    },
    onFormatChange(change) {
      if (this.selectedMainAction.id === 'synthesize') {
        this.changeReq = change;
        this.handleConfirmation(change);
      } else {
        this.showConfirmation = true;
        this.changeReq = change;
        this.dialogTitle = this.$t('synthesize.formats.popupTitle');
        this.dialogText = this.$t('synthesize.formats.popupText');
        this.dialogPrimary = this.$t('synthesize.formats.popupTitle');
        this.replaceVisualCancel = this.$t('common.cancel');
      }
    },
    onDataStyleChange(change) {
      this.showConfirmation = true;
      this.changeReq = change;
      this.dialogTitle = this.$t('synthesize.dataStyle.popupTitle');
      this.dialogText = this.$t('synthesize.dataStyle.popupText');
      this.dialogPrimary = this.$t('synthesize.dataStyle.popupTitle');
      this.replaceVisualCancel = this.$t('common.cancel');
    },
    onDialogCancel() {
      this.changeReq = null;
      this.showConfirmation = false;
    },
    onDialogConfirm() {
      this.showConfirmation = false;
      this.handleConfirmation(this.changeReq);
    },
  },
};
</script>

<style lang="scss" scoped>
.synthesize-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;

  ::v-deep .sf,
  ::v-deep .node-count-container,
  ::v-deep .ds,
  ::v-deep .se,
  ::v-deep .st,
  ::v-deep .change-layout-container {
    padding: 0 !important;

    .sf__body,
    .se__body {
      max-height: calc(100% - 230px) !important;
      overflow-y: scroll !important;
      overflow-x: hidden;
      //   margin-bottom: -20px;
    }
    .se__body {
      max-height: 100% !important;
    }

    .sf__footer,
    .se__footer {
      position: absolute;
      bottom: 20px;
      left: 20px;
      z-index: 10;
      background-color: white;
      right: 0;
    }
  }
  .heading-block {
    &__main {
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &__sub {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }

  .error-container {
    text-align: center;

    .error-text {
      margin: 20px auto;
      font-size: 14px;
    }
  }
}
</style>
