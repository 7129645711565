var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quick-access-actions",style:({ 'margin-top': ("" + (_vm.isFullScreenMode ? '8px' : '5px')) })},[(_vm.isUserUploadedPrezentation || _vm.isGenerated)?_c('div',{staticClass:"best-practices-input",attrs:{"data-pendo-id":"best-practices-toggle"}},[(_vm.isPrezentationOwner)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"tooltip-activator"},'div',attrs,false),on),[_c('span',[(_vm.isConvertTemplateOpen)?_c('v-switch',{attrs:{"label":_vm.$t('build.step3.bestPracticeSwitchLabel'),"hide-details":"","disabled":true}}):_c('v-switch',{attrs:{"label":_vm.$t('build.step3.bestPracticeSwitchLabel'),"hide-details":"","disabled":_vm.isEditBestPracticesOpen || !_vm.isOwnerOfPrezentation},on:{"change":_vm.handleBestPracticeToggle},model:{value:(_vm.showBestPractices),callback:function ($$v) {_vm.showBestPractices=$$v},expression:"showBestPractices"}})],1)])]}}],null,false,679886400)},[(_vm.isPrezentationOwner)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('build.step3.bestPracticeSwitchTooltip'))+" ")]):_vm._e()]):_vm._e()],1):_vm._e(),(_vm.isBestPractices)?_c('div',{staticClass:"best-practices-input",attrs:{"data-pendo-id":"best-practices-toggle"}},[_c('span',[(_vm.isConvertTemplateOpen)?_c('v-switch',{attrs:{"label":_vm.$t('build.step3.bestPracticeSwitchLabel'),"hide-details":"","disabled":true}}):_c('v-switch',{attrs:{"input-value":_vm.getShowBestPracticesForBpDecks,"label":_vm.$t('build.step3.bestPracticeSwitchLabel'),"hide-details":"","disabled":!_vm.currentPrezentation ||
          !_vm.currentPrezentation.bestPractices ||
          !_vm.currentPrezentation.bestPractices.slideToolTips ||
          !_vm.currentPrezentation.bestPractices.slideToolTips.length > 0},on:{"change":function (e) { return _vm.handleBestPracticeToggleForBpDecks(e); }}})],1)]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:("view-mode list " + (_vm.inListViewMode ? 'active' : '')),on:{"click":function($event){return _vm.handleViewMode('list')}}},'div',attrs,false),on),[_c('img',{staticClass:"normal",attrs:{"src":"/assets/img/list_view_normal.svg","alt":""}}),_c('img',{staticClass:"active",attrs:{"src":"/assets/img/list_view_solid.svg","alt":""}})])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('build.step3.listViewText')))])]),(!_vm.isOpPrezentation)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:("view-mode mosaic " + (!_vm.inListViewMode ? 'active' : '')),on:{"click":function($event){return _vm.handleViewMode('mosaic')}}},'div',attrs,false),on),[_c('img',{staticClass:"normal",attrs:{"src":"/assets/img/mosaic_view_normal.svg","alt":""}}),_c('img',{staticClass:"active",attrs:{"src":"/assets/img/mosaic_view_solid.svg","alt":""}})])]}}],null,false,4100701307)},[_c('span',[_vm._v(_vm._s(_vm.$t('build.step3.mosaicViewText')))])]):_vm._e(),(!_vm.isOpPrezentation && !_vm.isViewAccess)?_c('div',{staticClass:"tutorial-container"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","small":"","icon":"","ripple":false,"color":_vm.showTutorialMenu || hover ? '#21A7E0' : '#000'},on:{"click":_vm.showTutorial}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"name":"tutorials-icon","data-pendo-id":"prezentation-tutorials"}},[_vm._v(" mdi-television-play ")])],1)]}}],null,true)})]}}],null,false,1359514085)},[_c('span',[_vm._v(_vm._s(_vm.$t('generate.tutorials')))])]),_c('v-menu',{attrs:{"attach":true,"absolute":"","offset-y":"","position-x":-400,"position-y":30,"nudge-width":460,"close-on-click":true,"close-on-content-click":false,"content-class":"tutorial-menu"},model:{value:(_vm.showTutorialMenu),callback:function ($$v) {_vm.showTutorialMenu=$$v},expression:"showTutorialMenu"}},[_c('TutorialMenu',{attrs:{"category":_vm.handleCategory}})],1)],1):_vm._e(),_c('div',{staticClass:"expand",attrs:{"data-pendo-id":"story-builder-expand-btn"}},[_c('v-btn',{attrs:{"depressed":"","small":"","icon":"","ripple":false},on:{"click":_vm.toggleView}},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"tooltip-activator"},'div',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.isFullScreenMode ? 'mdi-arrow-collapse' : 'mdi-arrow-expand')+" ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.isFullScreenMode ? _vm.$t('prezentation.collapse') : _vm.$t('prezentation.expand'))+" ")])])],1)],1),(_vm.isFullScreenMode)?[_c('v-menu',{key:_vm.menuKey,attrs:{"bottom":"","offset-y":"","offset-x":"","close-on-click":true,"close-on-content-click":_vm.closeEllipsisMenu,"position-x":_vm.menuPos.x,"position-y":_vm.menuPos.y},scopedSlots:_vm._u([{key:"activator",fn:function(menu){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(tooltip){return [_c('v-icon',_vm._g({attrs:{"color":"black"}},Object.assign({}, menu.on, tooltip.on)),[_vm._v(" mdi-dots-vertical ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('slides.moreActions')))])])]}}],null,false,3381158517)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[(_vm.isChangingFavorite === true)?_c('div',{staticClass:"dropdown-progress fav-fixed"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":22}})],1):[(_vm.isFavorite)?_c('div',{staticClass:"list-icon-item fav-fixed",on:{"click":function($event){return _vm.handleRemovePrezAsFav(true)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-heart ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('bestPracticesGuide.knowSlideCard.removeFavorites'))+" ")])],1):_c('div',{staticClass:"list-icon-item fav-fixed",on:{"click":function($event){return _vm.handleAddPrezAsFav(true)}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-heart-outline ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('bestPracticesGuide.knowSlideCard.addFavorites'))+" ")])],1)]],2)],1),(!_vm.isPrezentSourceBestPractice)?_c('v-list-item',{class:{
            'no-click':
              _vm.currentUser.user.id !== _vm.prezentationData.ownerID ||
              _vm.isTemplateRemoved(_vm.themes),
          }},[_c('v-list-item-title',[_c('div',{staticClass:"list-icon-item",attrs:{"data-pendo-id":"story-builder-share-btn"},on:{"click":function($event){return _vm.share(true)}}},[_c('v-btn',{staticClass:"rounded-circle",attrs:{"disabled":_vm.currentUser.user.id !== _vm.prezentationData.ownerID ||
                  _vm.isTemplateRemoved(_vm.themes),"depressed":"","small":"","icon":"","ripple":false}},[_c('div',{staticClass:"tooltip-activator"},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-account-multiple-plus")])],1)]),_vm._v(" "+_vm._s(_vm.$t('common.share'))+" ")],1)])],1):_vm._e(),_c('v-list-item',[_c('v-list-item-title',[_c('div',{staticClass:"list-copy-item",on:{"click":function($event){return _vm.copyPrezentationLink(true)}}},[_c('div',{staticClass:"icon"},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-link-variant")])],1),_c('div',{staticClass:"copy-title"},[_vm._v(" "+_vm._s(_vm.$t('userUploadPrezentation.copyLink'))+" "),(_vm.showCopied === true)?_c('span',{staticClass:"copiedStatus"},[_c('v-icon',{attrs:{"small":"","color":"#21a7e0"}},[_vm._v("mdi-check")]),_c('span',[_vm._v(_vm._s(_vm.$t('common.copied')))])],1):_vm._e()])])])],1),(
            !_vm.isPrezentSourceBestPractice && _vm.isPrezentationCommentFullAccess
          )?_c('v-list-item',[_c('v-list-item-title',[_c('div',{staticClass:"extras-item last-item list-icon-item comment-height",attrs:{"data-pendo-id":"prezentations-comment-action"},on:{"click":_vm.handleCommentMode}},[_c('v-btn',{staticClass:"rounded-circle",attrs:{"depressed":"","small":"","icon":"","id":"comment-panel-icon--auto"}},[_c('v-badge',{attrs:{"content":_vm.currentPrezentation.countNewComments,"value":_vm.currentPrezentation.countNewComments,"color":"primary","overlap":"","size":"20"}},[_c('v-icon',{attrs:{"color":_vm.isCommentMode ? 'primary' : 'black'}},[_vm._v(" mdi-comment-outline ")])],1)],1),_vm._v(" "+_vm._s(_vm.$t('build.step3.commentsTooltip'))+" ")],1)])],1):_vm._e()],1)],1)]:[_c('div',{staticClass:"fav"},[(_vm.isChangingFavorite)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":22}}):[_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(!_vm.isFavorite)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"black"},on:{"click":_vm.handleAddPrezAsFav}},'v-icon',attrs,false),on),[_vm._v(" mdi-heart-outline ")]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":_vm.handleRemovePrezAsFav}},'v-icon',attrs,false),on),[_vm._v(" mdi-heart ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(("" + (_vm.isFavorite ? _vm.$t('bestPracticesGuide.knowSlideCard.removeFavorites') : _vm.$t('bestPracticesGuide.knowSlideCard.addFavorites'))))+" ")])])]],2),(!_vm.isPrezentSourceBestPractice)?_c('div',{staticClass:"share",attrs:{"data-pendo-id":"prez-share-btn"}},[_c('v-btn',{staticClass:"rounded-circle",attrs:{"disabled":_vm.currentUser.user.id !== _vm.prezentationData.ownerID ||
          _vm.isTemplateRemoved(_vm.themes),"depressed":"","small":"","icon":"","ripple":false},on:{"click":function($event){return _vm.share(false)}}},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"tooltip-activator"},'div',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-account-multiple-plus")])],1)]}}],null,false,857032480)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.share')))])])],1)],1):_vm._e(),_c('div',{staticClass:"copy-link",on:{"click":_vm.copyPrezentationLink}},[(_vm.showCopyLink)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('div',_vm._g({staticClass:"copyLink"},on),[_c('div',{staticClass:"copyIconAndTitle"},[_c('div',{staticClass:"icon"},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-link-variant")])],1)]),(_vm.showCopied === true)?_c('div',{staticClass:"copiedStatus bg-copied-text"},[_c('v-icon',{attrs:{"small":"","color":"#21a7e0"}},[_vm._v("mdi-check")]),_c('div',[_vm._v(_vm._s(_vm.$t('common.copied')))])],1):_vm._e()])]}}],null,false,676838225)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('userUploadPrezentation.copyLink'))+" ")])]):_vm._e()],1),(!_vm.isPrezentSourceBestPractice && _vm.isPrezentationCommentFullAccess)?_c('div',{staticClass:"comment extras-item last-item",attrs:{"data-pendo-id":"prezentations-comment-action"}},[_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t('build.step3.commentsTooltip'),
          placement: 'bottom',
        }),expression:"{\n          content: $t('build.step3.commentsTooltip'),\n          placement: 'bottom',\n        }"}],staticClass:"rounded-circle",attrs:{"depressed":"","small":"","icon":"","id":"comment-panel-icon--auto"},on:{"click":_vm.handleCommentMode}},[_c('v-badge',{attrs:{"content":_vm.currentPrezentation.countNewComments,"value":_vm.currentPrezentation.countNewComments,"color":"primary","overlap":"","size":"20"}},[_c('v-icon',{attrs:{"color":_vm.isCommentMode ? 'primary' : 'black'}},[_vm._v(" mdi-comment-outline ")])],1)],1)],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }