<template>
  <div class="prez-wrapper">
    <div
      class="prezentations-container"
      :class="showQuickSelection ? 'quick-panel-active' : ''"
    >
      <div class="prezentations-type-wrapper">
        <v-sheet max-width="100%" :key="prezlistKey">
          <v-chip-group
            show-arrows
            mandatory
            cols="1"
            data-pendo-id="prezentations-storyline-chips"
          >
            <v-chip
              :class="getOutlineChipsClasss(outline)"
              outlined
              :id="outline.name === 'All' ? 'allChip' : ''"
              v-for="(outline, index) in trendingOutlines"
              :key="index"
              exact-active-class=""
              @click="handleOutlinesChipsClick(outline.name)"
              :disabled="areFiltersDisabled"
              @mouseenter="
                handleOutlineHovered(outline.name, index, trendingOutlines)
              "
              @mouseleave="handleOutlineLeave()"
            >
              <!-- Name attribute has been added for automation testing purpose !-->
              <div class="textWrapper" :name="outline.name">
                {{ outline.name }}
              </div>
            </v-chip>
          </v-chip-group>
        </v-sheet>
      </div>
      <div class="filtersWithAdvaced">
        <div class="sort-and-search-section left-filters">
          <div class="prezentations-search-bar">
            <v-text-field
              :value="searchModel"
              append-icon=""
              single-line
              hide-details
              :placeholder="$t('prezentationsList.searchPrezentations')"
              class="prezentations-search-bar__input pl-4 prezentations-search-bar--auto"
              @input="(e) => handleSearchChange(e)"
              :disabled="areFiltersDisabled"
              @focus="searchWithTextBoxActive = true"
              @blur="searchWithTextBoxActive = false"
            >
              <template #append>
                <v-icon
                  class="icon"
                  v-text="`mdi-close`"
                  @click="clearTextFieldSearch"
                  name="prez-cross-icon"
                  color="#21a7e0"
                  v-show="searchModel.length > 0"
                />
                <v-icon
                  class="icon"
                  v-text="`mdi-magnify`"
                  name="prez-magnify-glass"
                  :color="
                    searchWithTextBoxActive === true ? '#21a7e0' : 'inherit'
                  "
                />
              </template>
            </v-text-field>
          </div>

          <div v-if="checkForAddMoreFeatureAvailable()">
            <v-dialog width="900px" border-radius="15px">
              <template v-slot:activator="{ attrs }">
                <NewFeatureBadge
                  module-name="upload_prezentations"
                  :feature-id="'upload_prezentations_id'"
                  :offsetX="'0'"
                  :offsetY="'-2'"
                  :size="'large'"
                >
                  <v-btn
                    class="primary-button py-2 add-btn"
                    rounded
                    color="#21a7e0"
                    button-height="32px"
                    min-width="126px"
                    v-bind="attrs"
                    @click="addNewPrezentation"
                  >
                    <v-icon
                      color="#FFFFFF"
                      class="add-icon"
                      v-text="`mdi-plus`"
                    />
                    {{ $t('prezentationsList.addMoreBtn') }}
                  </v-btn>
                </NewFeatureBadge>
              </template>
            </v-dialog>
          </div>
        </div>

        <div class="right-filters">
          <div class="quick-action-cta">
            <template v-if="showQuickSelection || !leftSideBarStatus">
              <v-tooltip
                top
                max-width="250"
                content-class="tooltip-content"
                fixed
                open-delay="500"
                close-delay="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <button
                    class="filter-btn"
                    :name--auto="`prezentations-template-filter-btn${
                      toolType === 'template' ? '--active' : ''
                    }`"
                    data-pendo-id="prez-template-selection"
                    :class="toolType === 'template' ? 'filter-btn-active' : ''"
                    :disabled="isActionDisabled"
                    @click="handleQuickSelection('template')"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <img
                      :src="
                        isActionDisabled
                          ? '/assets/img/template-disabled.svg'
                          : '/assets/img/template.svg'
                      "
                      alt
                    />
                    <span>Template</span>
                  </button>
                </template>
                <span>{{ quickSelectionTemplates }}</span>
              </v-tooltip>
            </template>
            <template v-else>
              <button
                class="filter-btn"
                :name--auto="`prezentations-template-filter-btn${
                  toolType === 'template' ? '--active' : ''
                }`"
                data-pendo-id="prez-template-selection"
                :class="toolType === 'template' ? 'filter-btn-active' : ''"
                :disabled="isActionDisabled"
                @click="handleQuickSelection('template')"
              >
                <img
                  :src="
                    isActionDisabled
                      ? '/assets/img/template-disabled.svg'
                      : '/assets/img/template.svg'
                  "
                  alt
                />
                <span>Template: {{ quickSelectionTemplates }}</span>
              </button>
            </template>
            <button
              class="filter-btn"
              :name--auto="`prezentations-filter-filter-btn${
                toolType === 'filter' ? '--active' : ''
              }`"
              data-pendo-id="prez-filter-selection"
              :class="toolType === 'filter' ? 'filter-btn-active' : ''"
              :disabled="isActionDisabled"
              @click="handleQuickSelection('filter')"
            >
              <img
                :src="
                  isActionDisabled
                    ? '/assets/img/filter-sort-disabled.svg'
                    : '/assets/img/filter-sort.svg'
                "
                alt
              />
              Filter & Sort
            </button>
          </div>

          <div
            class="uploadCTA"
            v-if="isUploadFullAccess"
            data-pendo-id="upload-prezentations"
          >
            <NewFeatureBadge
              v-if="!isRoleBusinessStandared"
              module-name="upload"
              :feature-id="'upload_landing_id'"
              :additionalFeatureId="'release_2210'"
              :offsetX="'60'"
              :offsetY="'-5'"
              :size="'small'"
            ></NewFeatureBadge>
            <v-btn @click="handleUploadNavigation">
              <v-icon :size="20">{{ 'mdi-upload-outline' }}</v-icon>
              {{ $t('uploadFlow.navigationCTA') }}
            </v-btn>
          </div>
        </div>
      </div>
      <div class="prez-card-list-wrapper">
        <div
          class="prezList"
          :style="{ width: `${advancedFilterOpened === true ? 80 : 100}%` }"
        >
          <PrezentationCardList
            :waitForAllPrezToLoad="waitForAllPrezToLoad"
            :load-prezentations-fn="loadPrezentationsByOwner"
            :is-editable="true"
            :isAdvancedFilterChanged="isAdvancedFilterChanged"
            :loaded-prezentations-by-owner="prezentationsByOwner"
            :loaded-first-call="loadedFirstCall"
            :origin="'my_prezentation'"
            :advancedFilterActive="advancedFilterOpened"
            :snapshot="snapshot.PrezentationCardList"
            :isResetClicked="isResetClicked"
            @select="onPrezentationClick($event)"
            :key="prezlistKey"
          >
            <template v-slot:empty-state>
              <EmptyState
                img-url="/assets/img/view-prez-no-prezentations-yet.svg"
                :description="'prezentationsList.noPrezentationsFound'"
                :is-btn-visible="true"
                :handleCTA="goToNewPrezentation"
              />
            </template>
          </PrezentationCardList>
        </div>
      </div>
      <div
        id="go-top"
        class="go-top"
        v-show="showGoToTop"
        v-scroll="onScroll"
        @click="toTop"
      >
        <v-icon color="#fff" size="15" class="top-arrow">mdi-arrow-up</v-icon>
        <span>{{ $t('common.backToTop') }}</span>
      </div>
    </div>

    <QuickSelectionCntr
      class="quick-panel"
      v-if="showQuickSelection"
      :allThemes="allThemes"
      :handleSearchWithTemplateModelChange="handleSearchWithTemplateModelChange"
      :handleAllTemplatesClick="handleAllTemplatesClick"
      :toolType="toolType"
      @close="handleQuickSelection"
    >
      <PrezentationAdvancedFilters :key="prezlistKey" />
    </QuickSelectionCntr>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions, mapGetters } from 'vuex';
import { sortBy, debounce } from 'lodash';
import EventBus from '../../common/event-bus';
import PrezentationCardList from './PrezentationCardList.vue';
// import Loading from '../../common/Loading.vue';
import EmptyState from '../../common/EmptyState.vue';
import {
  getPrezentationsForListView,
  getFrequentlyUserOutlinesByUser,
  logSearchQuery,
} from '../../../utils/api-helper';
import { PrezentationViewTypes } from '../../../utils/constants';
import { trackPrezentationEvents } from '../../common/Analytics/PrezentationEvents';
import { trackUploadEvents } from '../../common/Analytics/UploadEvents';
import {
  TD_COMMENT,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_CREATED_ON,
  TD_FROM_TO,
  TD_ISDOWNLOAD,
  TD_ISFAVORITED,
  TD_MY_DOWNLOADS,
  TD_MY_FAVORITES,
  TD_PREZENTATIONAUTHOR,
  TD_PREZENTATIONUSERACCESS,
  TD_PREZENTATION_ACCESS,
  TD_PREZENTATION_AUTHOR,
  TD_PREZENTATION_TYPE,
  TD_PREZTYPE,
  TD_PREZ_ADVANCED_FILTER_MY_DOWNLOADS,
  TD_PREZ_ADVANCED_FILTER_MY_FAVORITES,
  TD_PREZ_ADVANCED_FILTER_REVIEW,
  TD_QUERY,
  TD_SECTION_CHANGED,
  TD_TEMPLATE,
  TD_REPLACE_IMAGE_SOURCE,
  TD_PREZENTATIONRESULTS,
  TD_CREATED_FROM,
  TD_SORTTYPE,
  TD_PLACEMENT,
  TD_PREZENTATION_LIBRARY,
} from '../../common/Analytics/MatomoTrackingDataHelper';
// import { capitalizeFirstLetter } from '../../../utils/common';
import AddPrezentation from '../../navbar/AddPrezentation.vue';
import NewFeatureBadge from '../../common/NewFeatureBadge.vue';
import QuickSelectionCntr from '../../common/QuickSelectionTools/QuickSelectionCntr.vue';
import PrezentationAdvancedFilters from '../../common/QuickSelectionTools/Filter&Sort/PrezentationAdvancedFilters.vue';

// const ADD_MORE_FEATURE_NAME = 'add-more-prezentations';

const handleDebounce = debounce((callback) => {
  callback();
}, 1000);

const customTemplate = {
  code: 'custom',
  name: 'Uploaded by me',
  thumbnailURL: '/assets/img/Custom_Template.svg',
  checked: true,
  disabled: true,
};

export default {
  name: 'Prezentations',
  data() {
    return {
      waitForAllPrezToLoad: false, // perform search only after all prez are loaded
      dialog: false,
      searchModel: '',
      datePickerWidth: '',
      loadedFirstCall: true,
      viewType: 1, // 0 = List, 1 + Mosiac
      prezentations: [],
      isAdvacnedFilterActive: true,
      viewTypes: PrezentationViewTypes,
      prezentationsByOwner: [],
      isActionDisabled: false,
      searchTerm: '',
      filterMap: {
        'Most recent': 'recent',
        'Oldest first': 'oldest',
        'A-Z': 'AZ',
        'Z-A': 'ZA',
      },
      trendingOutlines: [],
      defaultOutlines: [
        { name: 'Business Case' },
        { name: 'All-hands' },
        { name: 'Project Update' },
        { name: 'Communication Plan' },
        { name: 'Knowledge Transfer' },
        { name: 'Strategy Plan' },
        { name: 'Business Plan' },
        { name: 'Brand Plan' },
        { name: 'Leadership Meeting' },
        { name: 'Team Meeting' },
      ],
      outlineChipClicked: ['All'],
      templateAllFilter: { name: 'All', checked: true, disabled: false },
      selectedTemplates: [{ name: 'All', checked: true, disabled: false }],
      allThemes: [],
      advancedFilterOpened: false,
      offsetTop: 0,
      isResetClicked: false,
      searchWithTextBoxActive: false,
      outlineHovered: '',
      showQuickSelection: false,
      toolType: null,
      loadingPrezByOwner: false,
      prezlistKey: 0,
    };
  },
  components: {
    PrezentationCardList,
    EmptyState,
    NewFeatureBadge,
    QuickSelectionCntr,
    PrezentationAdvancedFilters,
    // Loading,
  },
  watch: {
    // eslint-disable-next-line func-names
    'currentUser.user.language': function () {
      this.setPrezFilterItems([
        {
          display_title: 'prezentation.mostRecent',
          title: 'Most recent',
          field: 'createdAt',
          comparator: 'lt',
        },
        {
          display_title: 'prezentation.oldestFirst',
          title: 'Oldest first',
          field: 'createdAt',
          comparator: 'gt',
        },
        {
          display_title: 'prezentation.aToZ',
          title: 'A-Z',
          field: 'name',
          comparator: 'gt',
        },
        {
          display_title: 'prezentation.zToA',
          title: 'Z-A',
          field: 'name',
          comparator: 'lt',
        },
      ]);
      const selectedSortByFilter = this.filterItems.find(
        (item) => item.title === this.selectedFilter.title,
      );
      this.setPrezSelectedFilter(selectedSortByFilter);
    },
    reloadOnLastPrezDelete(newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) {
        this.outlineChipClicked = ['All'];
        this.setPrezFilterItems([
          {
            display_title: this.$t('prezentation.mostRecent'),
            title: 'Most recent',
            field: 'createdAt',
            comparator: 'lt',
          },
          {
            display_title: this.$t('prezentation.oldestFirst'),
            title: 'Oldest first',
            field: 'createdAt',
            comparator: 'gt',
          },
          {
            display_title: this.$t('prezentation.aToZ'),
            title: 'A-Z',
            field: 'name',
            comparator: 'gt',
          },
          {
            display_title: this.$t('prezentation.zToA'),
            title: 'Z-A',
            field: 'name',
            comparator: 'lt',
          },
        ]);
        this.setPrezSelectedFilter({
          display_title: this.$t('prezentation.mostRecent'),
          title: 'Most recent',
          field: 'createdAt',
          comparator: 'lt',
        });
        this.templateAllFilter = {
          name: 'All',
          checked: true,
          disabled: false,
        };
        this.selectedTemplates = [
          { name: 'All', checked: true, disabled: false },
        ];
        this.allThemes = [
          this.templateAllFilter,
          ...this.checkAndDisableAllValues([customTemplate, ...this.themes]),
        ];
        this.searchModel = '';
        this.setPrezResetAllFilters();
        this.setReloadLastPrezOnDelete(false);
      }
    },
    themes: {
      handler(val) {
        this.allThemes = [
          this.templateAllFilter,
          customTemplate,
          ...this.checkAndDisableAllValues(val),
        ];
      },
      deep: true,
      immediate: true,
    },
    leftSideBarStatus(val) {
      if (!val) {
        this.showQuickSelection = false;
        this.toolType = '';
      }
    },
    selectedTemplates: {
      handler() {
        this.getSelectedTemplatesLabel();
      },
      deep: true,
      immediate: true,
    },
    // companyData(newVal) {
    //   if (newVal) {
    //     const { company } = this.currentUser;
    //     const companyName = company.displayName
    //       ? newVal.displayName
    //       : newVal.name;
    //     if (newVal.name && companyName) {
    //       this.advancedItems[0].items[1].name = newVal.name;
    //       this.advancedItems[0].items[1].title =
    //         capitalizeFirstLetter(companyName);
    //       advancedFilters[0].items[1].name = newVal.name;
    //       advancedFilters[0].items[1].title =
    //         capitalizeFirstLetter(companyName);
    //     }
    //   }
    // },

    getNavFromDownloadSnackbarPrezs(val) {
      if (val.navigated && this.$route.fullPath === '/home/prezentations') {
        this.loadedFirstCall = true;
        this.showQuickSelection = false;
        this.toolType = null;
        this.setPrezResetAllFilters();
        this.prezlistKey++;
      }
    },

    getNavFromAddToLibOrFavSnackbar(val) {
      if (
        val.navigated &&
        val.type === 'addToFavPrez' &&
        val.origin === 'fav_prez' &&
        this.$route.fullPath === '/home/prezentations'
      ) {
        this.loadedFirstCall = true;
        this.showQuickSelection = false;
        this.toolType = null;
        this.setPrezResetAllFilters();
        this.prezlistKey++;
      }
    },
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'themes',
      'startLoadingSlidesFlag',
      'companyData',
      'leftSideBarStatus',
      'quickSelectionTemplates',
    ]),
    ...mapState('prezentations', [
      'allPrezentations',
      'filteredAndSearchedPrezentations',
      'reloadOnLastPrezDelete',
      'snapshot',
      'prezentationBackClicked',
    ]),
    ...mapGetters('users', [
      'getOPAccessLevel',
      'isUploadLimittedAccess',
      'isUploadFullAccess',
    ]),

    ...mapGetters('commonDownloads', [
      'getNavFromDownloadSnackbarPrezs',
      'getNavFromAddToLibOrFavSnackbar',
    ]),

    activePanels: {
      get() {
        return this.$store.state.advancedFilterStore.prezentations.activePanels;
      },
      set() {},
    },
    advancedFilters: {
      get() {
        return this.$store.state.advancedFilterStore.prezentations
          .advancedFilters;
      },
      set() {},
    },
    advancedItems: {
      get() {
        return this.$store.state.advancedFilterStore.prezentations
          .advancedItems;
      },
      set() {},
    },
    fromDateMenu: {
      get() {
        return this.$store.state.advancedFilterStore.prezentations.fromDateMenu;
      },
      set(value) {
        this.$store.dispatch('advancedFilterStore/setPrezFromDateMenu', value);
      },
    },
    toDateMenu: {
      get() {
        return this.$store.state.advancedFilterStore.prezentations.toDateMenu;
      },
      set(value) {
        this.$store.dispatch('advancedFilterStore/setPrezToDateMenu', value);
      },
    },
    fromDateStr: {
      get() {
        return this.$store.state.advancedFilterStore.prezentations.fromDateStr;
      },
      set() {},
    },
    toDateStr: {
      get() {
        return this.$store.state.advancedFilterStore.prezentations.toDateStr;
      },
      set() {},
    },
    fromDate: {
      get() {
        return this.$store.state.advancedFilterStore.prezentations.fromDate;
      },
      set(value) {
        const payload = {
          type: 'from',
          date: value,
        };
        this.$store.dispatch(
          'advancedFilterStore/setPrezDatePickerTextChange',
          payload,
        );
      },
    },
    toDate: {
      get() {
        return this.$store.state.advancedFilterStore.prezentations.toDate;
      },
      set(value) {
        const payload = {
          type: 'to',
          date: value,
        };
        this.$store.dispatch(
          'advancedFilterStore/setPrezDatePickerTextChange',
          payload,
        );
      },
    },
    pickerDate: {
      get() {
        return this.$store.state.advancedFilterStore.prezentations.pickerDate;
      },
      set() {},
    },
    filterItems: {
      get() {
        return this.$store.state.advancedFilterStore.prezentations.filterItems;
      },
      set() {},
    },
    selectedFilter: {
      get() {
        return this.$store.state.advancedFilterStore.prezentations
          .selectedFilter;
      },
      set(value) {
        this.$store.dispatch(
          'advancedFilterStore/setPrezSelectedFilter',
          value,
        );
      },
    },
    isRoleBusinessStandared() {
      return this.currentUser?.subscriptions[0]?.planID === '12348';
    },
    userFeatureAccessDate() {
      let userFeatureAccessDate = null;
      const overnightPresentationFeature = this.currentUser?.features?.find(
        (feature) => feature.feature_name === 'overnight-prezentations',
      );

      if (overnightPresentationFeature) {
        userFeatureAccessDate = overnightPresentationFeature.updated_at;
      }
      return userFeatureAccessDate;
    },
    areFiltersDisabled() {
      return !(
        this.allPrezentations &&
        this.allPrezentations.length > 0 &&
        this.isAdvacnedFilterActive
      );
    },
    showGoToTop() {
      return this.offsetTop > 200;
    },
    isAdvancedFilterChanged() {
      return this.advancedItems.some(
        (item) =>
          !item.checkAll ||
          (item.includeAllOption &&
            item.items &&
            item.items.some((itm) => !itm.active)) ||
          (!item.includeAllOption &&
            item.items &&
            item.items.some((itm) => itm.active)) ||
          (!item.includeAllOption && item.isDateChanged),
      );
    },
    isRedesignEnabled() {
      const redesignFeature = this.currentUser?.features?.find(
        (itm) => itm.feature_name === 'redesign',
      );
      return (
        (redesignFeature &&
          redesignFeature.enabled &&
          redesignFeature.access_level &&
          redesignFeature.access_level === 'full_access') ||
        (redesignFeature &&
          redesignFeature.access_level &&
          (redesignFeature.access_level === 'limited_access' ||
            redesignFeature.access_level === 'restricted_access'))
      );
    },
    isComplyEnabled() {
      const redesignFeature = this.currentUser?.features?.find(
        (itm) => itm.feature_name === 'comply',
      );
      return (
        (redesignFeature &&
          redesignFeature.enabled &&
          redesignFeature.access_level &&
          redesignFeature.access_level === 'full_access') ||
        (redesignFeature &&
          redesignFeature.access_level &&
          (redesignFeature.access_level === 'limited_access' ||
            redesignFeature.access_level === 'restricted_access'))
      );
    },
    isGenerateEnabled() {
      const genFeature = this.currentUser?.features?.find(
        (itm) => itm.feature_name === 'slide-generate',
      );
      return (
        (genFeature &&
          genFeature.enabled &&
          genFeature.access_level &&
          genFeature.access_level === 'full_access') ||
        (genFeature &&
          genFeature.access_level &&
          (genFeature.access_level === 'limited_access' ||
            genFeature.access_level === 'restricted_access'))
      );
    },
  },
  methods: {
    ...mapActions('prezentations', [
      'setUserSelectedPrezentationView',
      'setAllPrezentationsForLandingPage',
      'setFilterAndSearchedPrezentations',
      'setReloadLastPrezOnDelete',
      'setPrezentationStateSnapshot',
      'setPrezentationViewOrigin',
      'setPrezsIntermittentlyOnPrezentationsLanding',
    ]),
    ...mapActions('users', [
      'setFilteredThemes',
      'setUploadFlowCTAOrigin',
      'setTemplateToolPlaceHolder',
    ]),
    ...mapActions('upload', ['setUploadPlacement']),
    ...mapActions('advancedFilterStore', [
      'setPrezAdvancedItems',
      'setPrezAdvancedFilters',
      'setPrezSelectedFilter',
      'setPrezResetAllFilters',
      'setPrezPickerDate',
      'setPrezFilterItems',
    ]),

    ...mapActions('commonDownloads', [
      'setNavFromDownloadSnackbarPrezs',
      'setNavFromAddToLibOrFavSnackbar',
    ]),

    handleLoadWhenNavFromLibOrFavSnackbarForPrezs(filterType) {
      let tempAdvancedItems = [];
      if (filterType === 'fav_prez') {
        tempAdvancedItems = [...this.advancedItems].map((itm) => {
          if (itm.title === 'Favorites') {
            return {
              ...itm,
              items: [
                {
                  active: true,
                  display_title: 'filters.myFavorites',
                  title: 'My Favorites',
                },
              ],
            };
          }
          return itm;
        });

        this.setPrezAdvancedItems(tempAdvancedItems);

        this.showQuickSelection = true;
        this.toolType = 'filter';

        this.onAdvancedFilterChange({
          active: true,
          checkAll: true,
          display_title: 'slides.favourites',
          icon: 'favorites',
          includeAllOption: false,
          items: [
            {
              active: true,
              display_title: 'filters.myFavorites',
              title: 'My Favorites',
            },
          ],
          title: 'Favorites',
        });
      } else {
        const createdFromItem = [...this.advancedItems].find(
          (itm) => itm.title.toLowerCase() === 'created from',
        );

        tempAdvancedItems = [...this.advancedItems].map((item) => {
          if (item.title.toLowerCase() === 'created from') {
            return {
              ...item,
              checkAll: false,
              items: [...item.items].map((itm) => {
                if (filterType.includes(itm.name)) {
                  return {
                    ...itm,
                    active: true,
                  };
                }
                return {
                  ...itm,
                  active: false,
                };
              }),
            };
          }
          return item;
        });

        this.setPrezAdvancedItems(tempAdvancedItems);

        this.showQuickSelection = true;
        this.toolType = 'filter';

        this.onAdvancedFilterChange({
          ...createdFromItem,
          checkAll: false,
          items: [...createdFromItem.items].map((itm) => {
            if (filterType.name.includes(itm.name)) {
              return {
                ...itm,
                active: true,
              };
            }
            return {
              ...itm,
              active: false,
            };
          }),
        });
      }
    },

    handleLoadWhenNavFromDownloadSnackbarForPrezs() {
      const tempAdvancedItems = [...this.advancedItems].map((itm) => {
        if (itm.title === 'Downloads') {
          return {
            ...itm,
            items: [
              {
                active: true,
                display_title: 'filters.myDownloads',
                title: 'My Downloads',
              },
            ],
          };
        }
        return itm;
      });
      this.setPrezAdvancedItems(tempAdvancedItems);

      this.showQuickSelection = true;
      this.toolType = 'filter';

      this.onAdvancedFilterChange({
        active: true,
        checkAll: true,
        display_title: 'slides.downloads',
        icon: 'downloads',
        includeAllOption: false,
        items: [
          {
            active: true,
            display_title: 'filters.myDownloads',
            title: 'My Downloads',
          },
        ],
        title: 'My Downloads',
      });
    },

    getSelectedTemplatesLabel() {
      let placeholderText = '';
      if (this.selectedTemplates.length === 0) {
        placeholderText = '';
        this.setTemplateToolPlaceHolder('None');
      } else {
        if (
          this.selectedTemplates.length === 1 &&
          this.selectedTemplates[0].checked
        )
          placeholderText = this.selectedTemplates[0].name;
        if (this.selectedTemplates.length > 1)
          placeholderText = `${this.selectedTemplates[0].name} (+ ${
            this.selectedTemplates.length - 1
          } more)`;
        this.setTemplateToolPlaceHolder(placeholderText);
      }
      return placeholderText;
    },
    // populateCompanyInAdvFilters() {
    //   const { company } = this.currentUser;
    //   const companyName = company.displayName
    //     ? company.displayName
    //     : company.name;
    //   if (company.name && companyName) {
    //     const capitalizedCompanyName = capitalizeFirstLetter(companyName);
    //     this.advancedItems[0].items[1].name = company.name;
    //     this.advancedItems[0].items[1].title = capitalizedCompanyName;
    //     this.advancedItems[0].items[1].display_title = capitalizedCompanyName;
    //     advancedFilters[0].items[1].name = company.name;
    //     advancedFilters[0].items[1].title = capitalizedCompanyName;
    //     advancedFilters[0].items[1].display_title = capitalizedCompanyName;
    //   }
    // },
    handleUploadNavigation() {
      this.setUploadFlowCTAOrigin('prezentation_exchange');
      this.$router.push('/home/upload');
      this.setUploadPlacement('Prezentations Library');
      trackUploadEvents.uploadIconClick(this.currentUser, {
        [TD_PLACEMENT]: TD_PREZENTATION_LIBRARY,
      });
    },
    handleOutlineHovered(outlineName, index, trendingOutlines) {
      this.outlineHovered = outlineName;
      if (index + 1 === trendingOutlines.length) {
        document.querySelector('.v-slide-group__next').click();
      }
    },
    getDatePickerWidth() {
      if (window.innerWidth > 1300 && window.innerWidth <= 1400) {
        this.datePickerWidth = 250;
      } else if (window.innerWidth > 1200 && window.innerWidth <= 1300) {
        this.datePickerWidth = 218;
      } else {
        this.datePickerWidth = 280;
      }
    },
    isPrezentationOwner(prezentation) {
      return (
        prezentation.ownerID &&
        this.currentUser &&
        prezentation.ownerID.toLowerCase() ===
          this.currentUser.user.id.toLowerCase()
      );
    },
    getCurrentDateFormatted(origin) {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');

      this.SET_PREZ_PICKER_DATE(`${year}-${month}-${day}`);
      if (origin === 'from' && this.fromDate)
        this.SET_PREZ_PICKER_DATE(this.fromDate);
      if (origin === 'to' && this.toDate)
        this.SET_PREZ_PICKER_DATE(this.toDate);
    },
    onPrezentationClick($event) {
      const { listComponentStateSnapshot } = $event;
      const snapshot = {
        Prezentations: this.$data,
        PrezentationCardList: {
          ...listComponentStateSnapshot,
          allPrezentations: this.allPrezentations,
        },
      };
      this.setPrezentationStateSnapshot(snapshot);
      this.setPrezentationViewOrigin('Prezentations');
      console.log('Prezentations Snapshot Saved:', snapshot);
    },

    handleOutlineLeave() {
      this.outlineHovered = '';
    },

    clearTextFieldSearch() {
      this.searchModel = '';
      this.handleSearchChange(this.searchModel);
    },

    handleAllOptionClickForCategories(item) {
      this.searchModel = '';
      this.outlineChipClicked = ['All'];
      this.setPrezFilterItems([
        {
          display_title: this.$t('prezentation.mostRecent'),
          title: 'Most recent',
          field: 'createdAt',
          comparator: 'lt',
        },
        {
          display_title: this.$t('prezentation.oldestFirst'),
          title: 'Oldest first',
          field: 'createdAt',
          comparator: 'gt',
        },
        {
          display_title: this.$t('prezentation.aToZ'),
          title: 'A-Z',
          field: 'name',
          comparator: 'gt',
        },
        {
          display_title: this.$t('prezentation.zToA'),
          title: 'Z-A',
          field: 'name',
          comparator: 'lt',
        },
      ]);
      this.setPrezSelectedFilter({
        display_title: this.$t('prezentation.mostRecent'),
        title: 'Most recent',
        field: 'createdAt',
        comparator: 'lt',
      });
      this.templateAllFilter = { name: 'All', checked: true, disabled: false };
      this.selectedTemplates = [
        { name: 'All', checked: true, disabled: false },
      ];
      this.allThemes = [
        this.templateAllFilter,
        ...this.checkAndDisableAllValues([customTemplate, ...this.themes]),
      ];

      if (item.title === 'Author') {
        if (item.checkAll === true) {
          const tempAdvancedItems = [...this.advancedItems].map((itm) => {
            if (itm.title === 'Author') {
              return {
                ...item,
                items: item.items.map((it) => ({ ...it, active: true })),
              };
            }
            return {
              ...itm,
            };
          });
          this.setPrezAdvancedItems(tempAdvancedItems);
        } else {
          const tempAdvancedItems = [...this.advancedItems].map((itm) => {
            if (itm.title === 'Author') {
              return {
                ...item,
                items: item.items.map((it) => ({ ...it, active: false })),
              };
            }
            return {
              ...itm,
            };
          });
          this.setPrezAdvancedItems(tempAdvancedItems);
        }
      } else if (item.title === 'Access') {
        if (item.checkAll === true) {
          const tempAdvancedItems = [...this.advancedItems].map((itm) => {
            if (itm.title === 'Author') {
              return {
                ...this.advancedItems.find((ele) => ele.title === 'Author'),
              };
            }
            if (itm.title === 'Access') {
              return {
                ...item,
                items: [
                  ...this.getAccessItemsWithAuthorPermissions(
                    this.advancedItems.find((ele) => ele.title === 'Author'),
                  ),
                ],
              };
            }
            return {
              ...itm,
            };
          });
          this.setPrezAdvancedItems(tempAdvancedItems);
        } else {
          const tempAdvancedItems = [...this.advancedItems].map((itm) => {
            if (itm.title === 'Author') {
              return {
                ...this.advancedItems.find((ele) => ele.title === 'Author'),
              };
            }
            if (itm.title === 'Access') {
              return {
                ...item,
                items: [
                  ...this.getAccessItemsWithAuthorPermissions(
                    this.advancedItems.find((ele) => ele.title === 'Author'),
                  ).map((accessItem) => ({
                    ...accessItem,
                    active: false,
                  })),
                ],
              };
            }
            return {
              ...itm,
            };
          });
          this.setPrezAdvancedItems(tempAdvancedItems);
        }
      } else if (item.title === 'Source') {
        item.items.forEach((i) => {
          i.active = item.checkAll;
        });
        if (item.checkAll === true) {
          const tempAdvancedItems = [...this.advancedItems].map((itm) => {
            if (itm.title === 'Author' || itm.title === 'Access') {
              itm.items = itm.items.map((i) => {
                i.active = true;
                i.disabled = false;
                return i;
              });
              itm.disableAll = false;
              itm.checkAll = true;
            }
            return itm;
          });
          this.setPrezAdvancedItems(tempAdvancedItems);
        }
      } else {
        item.items.forEach((i) => {
          i.active = item.checkAll;
        });
      }

      this.getCurrentAdvancedFilterValuesAndSearch();
    },

    handleClearDate(type) {
      if (type === 'from') {
        this.fromDate = '';
        this.fromDateStr = '';
      } else if (type === 'to') {
        this.toDate = '';
        this.toDateStr = '';
      }
    },

    allowedToDates(val) {
      if (this.fromDate === '') {
        return moment(val).isSameOrBefore(new Date());
      }
      return (
        moment(val).isSameOrAfter(this.fromDate) &&
        moment(val).isSameOrBefore(new Date())
      );
    },

    allowedFromDates(val) {
      return moment(val).isSameOrBefore(new Date());
    },

    handleChangeFilter(value) {
      if (value === 'click')
        trackPrezentationEvents.prezentationsSortBy(this.currentUser, {
          [TD_SORTTYPE]: this.selectedFilter?.title,
        });
      const prezentations = [...this.filteredAndSearchedPrezentations];
      Object.values(this.filterItems).forEach((filterBy) => {
        if (this.selectedFilter.title === filterBy.title) {
          let sortedSlides = sortBy(prezentations, [filterBy.field]);
          if (filterBy.comparator === 'lt') {
            sortedSlides = sortedSlides.reverse();
          }
          this.setFilterAndSearchedPrezentations(sortedSlides);
        }
      });
    },
    resetAllFiltersOfAdvancedFilters() {
      this.searchModel = '';
      this.outlineChipClicked = ['All'];
      this.setPrezFilterItems([
        {
          display_title: this.$t('prezentation.mostRecent'),
          title: 'Most recent',
          field: 'createdAt',
          comparator: 'lt',
        },
        {
          display_title: this.$t('prezentation.oldestFirst'),
          title: 'Oldest first',
          field: 'createdAt',
          comparator: 'gt',
        },
        {
          display_title: this.$t('prezentation.aToZ'),
          title: 'A-Z',
          field: 'name',
          comparator: 'gt',
        },
        {
          display_title: this.$t('prezentation.zToA'),
          title: 'Z-A',
          field: 'name',
          comparator: 'lt',
        },
      ]);
      this.setPrezSelectedFilter({
        display_title: this.$t('prezentation.mostRecent'),
        title: 'Most recent',
        field: 'createdAt',
        comparator: 'lt',
      });
      this.templateAllFilter = { name: 'All', checked: true, disabled: false };
      this.selectedTemplates = [
        { name: 'All', checked: true, disabled: false },
      ];
      this.allThemes = [
        this.templateAllFilter,
        ...this.checkAndDisableAllValues([customTemplate, ...this.themes]),
      ];
      this.setPrezResetAllFilters();
      this.isResetClicked = true;
      setTimeout(() => {
        this.isResetClicked = false;
      }, 1000);
      const prezs = [...this.allPrezentations];
      this.setFilterAndSearchedPrezentations(prezs);
      trackPrezentationEvents.prezentationsResetfilters(this.currentUser);
    },

    handleDatePickerTextChange(type) {
      if (type === 'from') {
        const fromDate = moment(this.fromDate);
        this.fromDateStr = fromDate.format('MM/DD/YYYY');
        if (this.toDate && moment(this.toDate).isBefore(fromDate)) {
          this.toDate = '';
          this.toDateStr = '';
        }
      } else if (type === 'to') {
        this.toDateStr = moment(this.toDate).format('MM/DD/YYYY');
      }
    },

    getAccessItemsWithAuthorPermissions(item) {
      const items = [...item.items];
      const selections = items
        .filter((sel) => sel.active === true)
        .map((itm) => itm.title);
      let res = [];
      if (selections.includes('Me') && selections.includes('My Colleagues')) {
        res = [
          {
            title: 'Restricted',
            display_title: 'prezentationShareStatus.restricted',
            active: true,
            disabled: false,
          },
          {
            title: 'Viewer',
            display_title: 'prezentationShareStatus.viewer',
            active: true,
            disabled: false,
          },
          {
            title: 'Reviewer',
            display_title: 'prezentationShareStatus.reviewer',
            active: true,
            disabled: false,
          },
          {
            title: 'Editor',
            display_title: 'prezentationShareStatus.editor',
            active: true,
            disabled: false,
          },
          {
            title: 'Shared',
            display_title: 'prezentationShareStatus.shared',
            active: true,
            disabled: false,
          },
          {
            title: 'Private',
            display_title: 'prezentationShareStatus.private',
            active: true,
            disabled: false,
          },
        ];
      }
      if (selections.includes('Me') && !selections.includes('My Colleagues')) {
        res = [
          {
            title: 'Restricted',
            display_title: 'prezentationShareStatus.restricted',
            active: true,
            disabled: false,
          },
          {
            title: 'Viewer',
            display_title: 'prezentationShareStatus.viewer',
            active: false,
            disabled: true,
          },
          {
            title: 'Reviewer',
            display_title: 'prezentationShareStatus.reviewer',
            active: false,
            disabled: true,
          },
          {
            title: 'Editor',
            display_title: 'prezentationShareStatus.editor',
            active: false,
            disabled: true,
          },
          {
            title: 'Shared',
            display_title: 'prezentationShareStatus.shared',
            active: true,
            disabled: false,
          },
          {
            title: 'Private',
            display_title: 'prezentationShareStatus.private',
            active: true,
            disabled: false,
          },
        ];
      }
      if (!selections.includes('Me') && selections.includes('My Colleagues')) {
        res = [
          {
            title: 'Restricted',
            display_title: 'prezentationShareStatus.restricted',
            active: true,
            disabled: false,
          },
          {
            title: 'Viewer',
            display_title: 'prezentationShareStatus.viewer',
            active: true,
            disabled: false,
          },
          {
            title: 'Reviewer',
            display_title: 'prezentationShareStatus.reviewer',
            active: true,
            disabled: false,
          },
          {
            title: 'Editor',
            display_title: 'prezentationShareStatus.editor',
            active: true,
            disabled: false,
          },
          {
            title: 'Shared',
            display_title: 'prezentationShareStatus.shared',
            active: false,
            disabled: true,
          },
          {
            title: 'Private',
            display_title: 'prezentationShareStatus.private',
            active: false,
            disabled: true,
          },
        ];
      }
      if (!selections.includes('Me') && !selections.includes('My Colleagues')) {
        res = [
          {
            title: 'Restricted',
            display_title: 'prezentationShareStatus.restricted',
            active: true,
            disabled: false,
          },
          {
            title: 'Viewer',
            display_title: 'prezentationShareStatus.viewer',
            active: false,
            disabled: true,
          },
          {
            title: 'Reviewer',
            display_title: 'prezentationShareStatus.reviewer',
            active: false,
            disabled: true,
          },
          {
            title: 'Editor',
            display_title: 'prezentationShareStatus.editor',
            active: false,
            disabled: true,
          },
          {
            title: 'Shared',
            display_title: 'prezentationShareStatus.shared',
            active: false,
            disabled: true,
          },
          {
            title: 'Private',
            display_title: 'prezentationShareStatus.private',
            active: false,
            disabled: true,
          },
        ];
      }

      if (selections.includes('Brand Team')) {
        res.forEach((row) => {
          if (row.title === 'Viewer') {
            row.active = true;
          }
        });
      }

      return res;
    },

    checkOrUncheckAllOfCurrentAdvancedFilter(item) {
      return (
        item.items.filter((itm) => itm.active === true).length ===
        item.items.length
      );
    },

    updateCurrentAdvancedFilter(item) {
      const tempItems = JSON.parse(JSON.stringify(this.advancedItems));
      const itemIndx = tempItems.findIndex(
        (ele) => ele.display_title === item.display_title,
      );

      try {
        const otherData = {};
        let authors;
        let authorsInString;
        let prezs;
        let prezsInString;
        const isSingleCheckBoxItemSetTrue =
          tempItems[itemIndx]?.items?.[0]?.active;
        switch (tempItems[itemIndx].icon) {
          case 'author':
            // eslint-disable-next-line no-case-declarations
            authors = tempItems[itemIndx].items.filter(
              (advItem) => advItem.active,
            );
            // eslint-disable-next-line no-case-declarations
            authorsInString = authors
              .map((auth) => auth.title)
              .toString()
              .replaceAll(',', '_');
            otherData[TD_PREZENTATIONAUTHOR] = authorsInString;
            otherData[TD_COMMON_COLUMN_VALUE] = TD_PREZENTATION_AUTHOR;
            break;
          case 'access':
            //
            // eslint-disable-next-line no-case-declarations
            authors = tempItems[itemIndx].items.filter(
              (advItem) => advItem.active,
            );
            // eslint-disable-next-line no-case-declarations
            authorsInString = authors
              .map((auth) => auth.title)
              .toString()
              .replaceAll(',', '_');
            otherData[TD_PREZENTATIONUSERACCESS] = authorsInString;
            otherData[TD_COMMON_COLUMN_VALUE] = TD_PREZENTATION_ACCESS;
            break;
          case 'comment-mentions':
            otherData[TD_COMMENT] = `${
              isSingleCheckBoxItemSetTrue ? '' : '-'
            }${TD_PREZ_ADVANCED_FILTER_REVIEW}`;
            otherData[TD_COMMON_COLUMN_VALUE] = TD_PREZ_ADVANCED_FILTER_REVIEW;
            break;
          case 'favorites':
            otherData[TD_ISFAVORITED] = `${
              isSingleCheckBoxItemSetTrue ? '' : '-'
            }${TD_PREZ_ADVANCED_FILTER_MY_FAVORITES}`;
            otherData[TD_COMMON_COLUMN_VALUE] = TD_MY_FAVORITES;
            break;
          case 'downloads':
            otherData[TD_ISDOWNLOAD] = `${
              isSingleCheckBoxItemSetTrue ? '' : '-'
            }${TD_PREZ_ADVANCED_FILTER_MY_DOWNLOADS}`;
            otherData[TD_COMMON_COLUMN_VALUE] = TD_MY_DOWNLOADS;
            break;
          case 'created-on':
            if (this.fromDateStr || this.toDateStr) {
              otherData[TD_FROM_TO] = `${this.fromDateStr}-${this.toDateStr}`;
            }
            otherData[TD_COMMON_COLUMN_VALUE] = TD_CREATED_ON;
            break;
          case 'prezentation-type':
            // eslint-disable-next-line no-case-declarations
            prezs = tempItems[itemIndx].items.filter(
              (advItem) => advItem.active,
            );
            // eslint-disable-next-line no-case-declarations
            prezsInString = prezs
              .map((prez) => prez.title)
              .toString()
              .replaceAll(',', '_');
            otherData[TD_PREZTYPE] = prezsInString;
            otherData[TD_COMMON_COLUMN_VALUE] = TD_PREZENTATION_TYPE;
            break;
          case 'created-from':
            prezs = tempItems[itemIndx].items.filter(
              (advItem) => advItem.active,
            );
            prezsInString = prezs
              .map((prez) => prez.title)
              .toString()
              .replaceAll(',', '_');
            otherData[TD_PREZENTATIONRESULTS] = prezsInString;
            otherData[TD_COMMON_COLUMN_VALUE] = TD_CREATED_FROM;
            break;
          default:
            break;
        }
        otherData[TD_COMMON_COLUMN_NAME] = TD_SECTION_CHANGED;
        let sourceSelected = '';
        if (tempItems[itemIndx]?.title === 'Source') {
          tempItems[itemIndx].items.forEach((value) => {
            if (value.active) {
              sourceSelected += value.title;
              sourceSelected += '_';
            }
          });
          otherData[TD_REPLACE_IMAGE_SOURCE] = sourceSelected;
          otherData[TD_COMMON_COLUMN_VALUE] = tempItems[itemIndx]?.title;
        }
        trackPrezentationEvents.prezentationsAdvanceFiltersSelected(
          this.currentUser,
          otherData,
        );
      } catch (e) {
        console.log(e);
      }
      // checking all option accordingly
      if (tempItems[itemIndx].includeAllOption === true) {
        tempItems[itemIndx] = {
          ...tempItems[itemIndx],
          checkAll:
            this.checkOrUncheckAllOfCurrentAdvancedFilter(
              tempItems[itemIndx],
            ) === true,
        };
      }

      // syncing checkboxes
      if (tempItems[itemIndx].title === 'Author') {
        tempItems.map((itm) => {
          if (itm.title === 'Author') {
            return {
              ...tempItems[itemIndx],
            };
          }
          if (itm.title === 'Access') {
            const activeItems = [
              ...this.getAccessItemsWithAuthorPermissions(tempItems[itemIndx]),
            ];
            return {
              items: activeItems,
              checkAll:
                activeItems.filter((it) => it.active === true).length ===
                activeItems.length,
              title: 'Access',
              display_title: 'filters.access',
              active: true,
              disableAll:
                activeItems.filter((it) => it.active === true).length <
                activeItems.length,
              includeAllOption: true,
              icon: 'access',
            };
          }
          return {
            ...itm,
          };
        });
        this.setPrezAdvancedItems(tempItems);
      } else if (tempItems[itemIndx].title === 'Access') {
        tempItems.map((itm) => {
          if (itm.title === 'Author') {
            return {
              ...this.advancedItems.find((ele) => ele.title === 'Author'),
            };
          }
          if (itm.title === 'Access') {
            return {
              ...tempItems[itemIndx],
            };
          }
          return {
            ...itm,
          };
        });
        this.setPrezAdvancedItems(tempItems);
      } else if (tempItems[itemIndx].title === 'Source') {
        tempItems.map((itm) => {
          if (itm.title === tempItems[itemIndx].title) {
            return {
              ...tempItems[itemIndx],
            };
          }
          if (itm.title === 'Author' || itm.title === 'Access') {
            const isPrezentSourceSelected =
              !tempItems[itemIndx].checkAll &&
              tempItems[itemIndx].items
                .filter((i) => i.active)
                .map((i) => i.title)
                .includes('Prezent');
            if (isPrezentSourceSelected) {
              itm.items = itm.items.map((i) => {
                i.active = true;
                i.disabled = true;
                return i;
              });
              itm.checkAll = true;
              itm.disableAll = true;
            } else {
              itm.items = itm.items.map((i) => {
                i.active = true;
                i.disabled = false;
                return i;
              });
              itm.checkAll = true;
              itm.disableAll = false;
            }
          }
          return {
            ...itm,
          };
        });
        this.setPrezAdvancedItems(tempItems);
      } else {
        tempItems.map((itm) => {
          if (itm.title === tempItems[itemIndx].title) {
            return {
              ...tempItems[itemIndx],
            };
          }
          return {
            ...itm,
          };
        });

        this.setPrezAdvancedItems(tempItems);
      }
    },

    applyDateFiltersWithSearch(prezs) {
      if (this.fromDateStr !== '' || this.toDateStr !== '') {
        if (this.fromDateStr !== '' && this.toDateStr !== '') {
          const currToDate = new Date(this.toDate);
          currToDate.setDate(currToDate.getDate() + 1);
          prezs = [...prezs].filter(
            (prz) =>
              moment(prz.createdAt).isSameOrAfter(this.fromDate) &&
              moment(prz.createdAt).isSameOrBefore(currToDate),
          );
        } else if (this.fromDateStr !== '' && this.toDateStr === '') {
          prezs = [...prezs].filter((prz) =>
            moment(prz.createdAt).isSameOrAfter(this.fromDate),
          );
        } else if (this.fromDateStr === '' && this.toDateStr !== '') {
          const currToDate = new Date(this.toDate);
          currToDate.setDate(currToDate.getDate() + 1);
          prezs = [...prezs].filter((prz) =>
            moment(prz.createdAt).isSameOrBefore(currToDate),
          );
        }
      }
      return prezs;
    },

    onAdvancedFilterChange(item) {
      if (item.display_title === 'filters.filterByDate') {
        const tempItems = JSON.parse(JSON.stringify(this.advancedItems));
        tempItems.map((itm) => {
          itm.isDateChanged = true;
          return itm;
        });
        this.setPrezAdvancedItems(tempItems);
      }
      // resetting all other filter categories of the page
      this.searchModel = '';
      this.outlineChipClicked = ['All'];
      this.setPrezFilterItems([
        {
          display_title: this.$t('prezentation.mostRecent'),
          title: 'Most recent',
          field: 'createdAt',
          comparator: 'lt',
        },
        {
          display_title: this.$t('prezentation.oldestFirst'),
          title: 'Oldest first',
          field: 'createdAt',
          comparator: 'gt',
        },
        {
          display_title: this.$t('prezentation.aToZ'),
          title: 'A-Z',
          field: 'name',
          comparator: 'gt',
        },
        {
          display_title: this.$t('prezentation.zToA'),
          title: 'Z-A',
          field: 'name',
          comparator: 'lt',
        },
      ]);
      this.setPrezSelectedFilter({
        display_title: this.$t('prezentation.mostRecent'),
        title: 'Most recent',
        field: 'createdAt',
        comparator: 'lt',
      });
      this.templateAllFilter = { name: 'All', checked: true, disabled: false };
      this.selectedTemplates = [
        { name: 'All', checked: true, disabled: false },
      ];
      this.allThemes = [
        this.templateAllFilter,
        ...this.checkAndDisableAllValues([customTemplate, ...this.themes]),
      ];
      this.updateCurrentAdvancedFilter(item);
      this.getCurrentAdvancedFilterValuesAndSearch();
    },

    getCurrentAdvancedFilterValuesAndSearch() {
      const authorResults = [];
      const authorWithAccessResults = [];
      // const sources = [];
      // const sources = this.advancedItems
      //   .find((ele) => ele.title === 'Source')
      //   .items.filter((itm) => itm.active === true)
      //   .map((i) => i.title.toLowerCase());
      const types = this.advancedItems.find(
        (ele) => ele.title === 'Prezentation Type',
      )
        ? this.advancedItems
            .find((ele) => ele.title === 'Prezentation Type')
            .items.filter((itm) => itm.active === true)
            .map((i) => i.value)
        : [];
      const createdFrom = this.advancedItems.find(
        (ele) => ele.title === 'Created from',
      )
        ? this.advancedItems
            .find((ele) => ele.title === 'Created from')
            .items.filter((itm) => itm.active === true)
            .map((i) => i.value)
            .reduce((a, b) => [...a, ...b], [])
        : [];
      const authors = this.advancedItems
        .find((ele) => ele.title === 'Author')
        .items.filter((it) => it.active === true)
        .map((itm) => itm.title);
      const permissions = this.advancedItems
        .find((ele) => ele.title === 'Access')
        .items.filter((itm) => itm.active === true)
        .map((at) => at.title);

      let prezentationTypeResults = [...this.allPrezentations];

      if (
        this.advancedItems.find((ele) => ele.title === 'Prezentation Type') &&
        !this.advancedItems.find((ele) => ele.title === 'Prezentation Type')
          .checkAll
      ) {
        prezentationTypeResults = [...prezentationTypeResults].filter((prz) =>
          types.includes(prz.prezentationType.toLowerCase()),
        );
      }
      if (
        this.advancedItems.find((ele) => ele.title === 'Created from') &&
        !this.advancedItems.find((ele) => ele.title === 'Created from').checkAll
      ) {
        prezentationTypeResults = [...prezentationTypeResults].filter((prz) =>
          createdFrom.includes(prz.prezentationSource.toLowerCase()),
        );
      }
      // search with author and access combinations
      if (!this.advancedItems.find((ele) => ele.title === 'Author').checkAll) {
        authors.forEach((at) => {
          if (at === 'Me') {
            const myPrezs = [...prezentationTypeResults].filter((prz) =>
              this.isPrezentationOwner(prz),
            );
            authorResults.push(...myPrezs);
          }
          if (at === 'My Colleagues') {
            const colleaguesPrezs = [...prezentationTypeResults].filter(
              (prz) => !this.isPrezentationOwner(prz),
            );
            authorResults.push(...colleaguesPrezs);
          }
          if (at === 'Brand Team') {
            const brandTeamPrezs = [...prezentationTypeResults].filter(
              (prz) =>
                prz.author &&
                prz.author === 'Brand team' &&
                prz.source !== 'prezent',
            );
            authorResults.push(...brandTeamPrezs);
          }
        });
      } else {
        authorResults.push(...prezentationTypeResults);
      }

      // search with allowed permissions based on author selected
      if (!this.advancedItems.find((ele) => ele.title === 'Access').checkAll) {
        permissions.forEach((prm) => {
          if (prm === 'Restricted') {
            const viewer = [...authorResults].filter(
              (prz) =>
                !this.isPrezentationOwner(prz) &&
                (prz.permission_level === 0 || prz.isRestricted),
            );
            authorWithAccessResults.push(...viewer);
          }
          if (prm === 'Viewer') {
            const viewer = [...authorResults].filter(
              (prz) =>
                !this.isPrezentationOwner(prz) && prz.permission_level === 1,
            );
            authorWithAccessResults.push(...viewer);
          }
          if (prm === 'Reviewer') {
            const reviewer = [...authorResults].filter(
              (prz) =>
                !this.isPrezentationOwner(prz) && prz.permission_level === 2,
            );
            authorWithAccessResults.push(...reviewer);
          }
          if (prm === 'Editor') {
            const editor = [...authorResults].filter(
              (prz) =>
                !this.isPrezentationOwner(prz) && prz.permission_level === 3,
            );
            authorWithAccessResults.push(...editor);
          }
          if (prm === 'Shared') {
            const shared = [...authorResults].filter(
              (prz) => this.isPrezentationOwner(prz) && prz.shared === true,
            );
            authorWithAccessResults.push(...shared);
          }
          if (prm === 'Private') {
            const priv = [...authorResults].filter(
              (prz) => this.isPrezentationOwner(prz) && !prz.shared,
            );
            authorWithAccessResults.push(...priv);
          }
        });
      } else {
        authorWithAccessResults.push(...authorResults);
      }

      // search with other other sub filters independently on author and access filtered data
      let prezs = [...authorWithAccessResults];

      const includeCommentMentions = this.advancedItems.find(
        (ele) => ele.title === 'Comment Mentions',
      ).items[0].active;
      const includeFavorites = this.advancedItems.find(
        (ele) => ele.title === 'Favorites',
      ).items[0].active;
      const includeDownloads = this.advancedItems.find(
        (ele) => ele.title === 'Downloads',
      ).items[0].active;

      if (includeCommentMentions) {
        prezs = prezs.filter(
          (prz) => prz.isReviewer && prz.isReviewer === true,
        );
      }

      if (includeFavorites) {
        prezs = prezs.filter((prz) => prz.isFavorite);
      }

      if (includeDownloads) {
        prezs = prezs.filter((prz) => prz.isDownloaded);
      }

      if (this.fromDateStr !== '' && this.toDateStr !== '') {
        const currToDate = new Date(this.toDate);
        currToDate.setDate(currToDate.getDate() + 1);
        prezs = [...prezs].filter(
          (prz) =>
            moment(prz.createdAt).isSameOrAfter(this.fromDate) &&
            moment(prz.createdAt).isSameOrBefore(currToDate),
        );
      } else if (this.fromDateStr !== '' && this.toDateStr === '') {
        prezs = [...prezs].filter((prz) =>
          moment(prz.createdAt).isSameOrAfter(this.fromDate),
        );
      } else if (this.fromDateStr === '' && this.toDateStr !== '') {
        const currToDate = new Date(this.toDate);
        currToDate.setDate(currToDate.getDate() + 1);
        prezs = [...prezs].filter((prz) =>
          moment(prz.createdAt).isSameOrBefore(currToDate),
        );
      }

      this.setFilterAndSearchedPrezentations(prezs);
      this.handleChangeFilter();
    },

    onScroll(event) {
      this.offsetTop = event.target.scrollingElement.scrollTop;
    },

    toTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },

    hideAdvancedFilter() {
      this.advancedFilterOpened = false;
    },

    handleOpenAdvancedFilter() {
      this.advancedFilterOpened = !this.advancedFilterOpened;
      if (this.advancedFilterOpened) {
        trackPrezentationEvents.prezentationsAdvanceFiltersClick(
          this.currentUser,
        );
        EventBus.$emit('MINIMIZE_LEFTNAV');
      }
    },

    handleSearchWithTemplateModelChange(e) {
      this.selectedTemplates = e;
      if (e.length > 0) {
        this.templatesSelectedSearchResetFilters();
        let prezs = [];
        const themeCodes = e.map((tmp) => tmp.code);
        if (e.length === 1 && e[0].name === 'All') {
          prezs = this.allPrezentations;
          const filteredThemes = [customTemplate, ...this.themes].map(
            (thm) => ({
              ...thm,
              checked: true,
              disabled: true,
            }),
          );
          this.allThemes = [
            { name: 'All', checked: true, disabled: false },
            ...filteredThemes,
          ];
        } else {
          prezs = this.allPrezentations.filter(
            (prz) =>
              themeCodes.includes(prz.theme) ||
              (themeCodes.includes('custom') &&
                prz.prezentationSource === 'OP' &&
                prz.theme === null),
          );
          const filteredThemes = [customTemplate, ...this.themes].map((thm) => {
            if (themeCodes.includes(thm.code)) {
              return {
                ...thm,
                checked: true,
                disabled: false,
              };
            }
            return {
              ...thm,
              checked: false,
              disabled: false,
            };
          });
          this.allThemes = [
            { name: 'All', checked: false, disabled: false },
            ...filteredThemes,
          ];
          trackPrezentationEvents.prezentationsTemplateDropdownClick(
            this.currentUser,
            {
              [TD_TEMPLATE]: themeCodes?.toString()?.replaceAll(',', '_'),
            },
          );
        }
        this.setFilterAndSearchedPrezentations(prezs);
      } else {
        this.setFilterAndSearchedPrezentations([]);
        const filteredThemes = [customTemplate, ...this.themes].map((thm) => ({
          ...thm,
          checked: false,
          disabled: false,
        }));
        this.allThemes = [
          { name: 'All', checked: false, disabled: false },
          ...filteredThemes,
        ];
      }
      this.handleChangeFilter();
    },

    checkAndDisableAllValues(data) {
      return data.map((item) => ({
        ...item,
        checked: true,
        disabled: true,
      }));
    },

    handleAllTemplatesClick(item) {
      console.log('handleAllTemplatesClick', item);
      if (!item.checked) {
        this.selectedTemplates = [];
        this.templateAllFilter = {
          name: 'All',
          checked: false,
          disabled: false,
        };
      } else {
        this.selectedTemplates = [
          { name: 'All', checked: true, disabled: false },
        ];
        this.templateAllFilter = {
          name: 'All',
          checked: true,
          disabled: false,
        };
      }
      const filteredThemes = [customTemplate, ...this.themes].map((thm) => ({
        ...thm,
        checked: item.checked,
        disabled: item.checked,
      }));
      this.allThemes = [this.templateAllFilter, ...filteredThemes];
    },

    // handleOtherTemplatesClick(e) {
    //   this.allThemes = [...this.allThemes].map((thm) => {
    //     if (e.code === thm.code) {
    //       if (e.checked) {
    //         return {
    //           ...thm,
    //           checked: false,
    //           disabled: false,
    //         };
    //       }
    //       return {
    //         ...thm,
    //         checked: true,
    //         disabled: false,
    //       };
    //     }
    //     return {
    //       ...thm,
    //     };
    //   });

    //   const areAllTemplatesSelected =
    //     [...this.allThemes].filter(
    //       (itm) => itm.name !== 'All' && !itm.disabled && itm.checked === true,
    //     ).length ===
    //     this.allThemes.length - 1;
    //   if (areAllTemplatesSelected === true) {
    //     // console.log(areAllTemplatesSelected);
    //     this.selectedTemplates = [
    //       { name: 'All', checked: true, disabled: false },
    //     ];
    //     this.templateAllFilter = {
    //       name: 'All',
    //       checked: true,
    //       disabled: false,
    //     };
    //     const filteredThemes = [customTemplate, ...this.themes].map((thm) => ({
    //       ...thm,
    //       checked: true,
    //       disabled: true,
    //     }));
    //     this.allThemes = [this.templateAllFilter, ...filteredThemes];
    //     this.handleSearchWithTemplateModelChange(this.selectedTemplates);
    //   }
    // },

    filterPrezentationsWithTextField: debounce((e, thisInstance) => {
      console.log(e);
      thisInstance.searchTerm = e;
      let prezs = [];
      // prezs = [...thisInstance.allPrezentations].filter(
      //   (przs) =>
      //     przs?.name.toLowerCase().includes(e.toLowerCase()) ||
      //     przs?.ownerID.toLowerCase().includes(e.toLowerCase()) ||
      //     przs?.type?.toLowerCase().includes(e.toLowerCase()) ||
      //     przs?.author?.toLowerCase().includes(e.toLowerCase()),
      // );
      // PM-9677 Search sync
      const searchTerms = e.toLowerCase().split(' ');
      prezs = [...thisInstance.allPrezentations].filter((deck) => {
        const filterByFields = [
          deck?.name,
          deck?.ownerID,
          deck?.type,
          deck?.author,
        ];
        // return filterByFields.some(
        //   (field) =>
        //     field &&
        //     searchTerms.every((term) => field.toLowerCase().includes(term)),
        // );
        return searchTerms.every((term) =>
          filterByFields.some(
            (field) => field && field.toLowerCase().includes(term),
          ),
        );
      });
      thisInstance.setFilterAndSearchedPrezentations(prezs);
      thisInstance.handleChangeFilter();
      thisInstance.waitForAllPrezToLoad = false;
    }, 500),

    handleSearchChange(e) {
      this.setPrezResetAllFilters();
      this.outlineChipClicked = ['All'];
      this.setPrezFilterItems([
        {
          display_title: this.$t('prezentation.mostRecent'),
          title: 'Most recent',
          field: 'createdAt',
          comparator: 'lt',
        },
        {
          display_title: this.$t('prezentation.oldestFirst'),
          title: 'Oldest first',
          field: 'createdAt',
          comparator: 'gt',
        },
        {
          display_title: this.$t('prezentation.aToZ'),
          title: 'A-Z',
          field: 'name',
          comparator: 'gt',
        },
        {
          display_title: this.$t('prezentation.zToA'),
          title: 'Z-A',
          field: 'name',
          comparator: 'lt',
        },
      ]);
      this.setPrezSelectedFilter({
        display_title: this.$t('prezentation.mostRecent'),
        title: 'Most recent',
        field: 'createdAt',
        comparator: 'lt',
      });
      this.templateAllFilter = { name: 'All', checked: true, disabled: false };
      this.selectedTemplates = [
        { name: 'All', checked: true, disabled: false },
      ];
      this.allThemes = [
        this.templateAllFilter,
        ...this.checkAndDisableAllValues([customTemplate, ...this.themes]),
      ];
      this.searchModel = e;
      this.filterPrezentationsWithTextField(this.searchModel, this);
      handleDebounce(() =>
        trackPrezentationEvents.prezentationsSearch(this.currentUser, {
          [TD_QUERY]: e,
        }),
      );
    },

    handleSearchWithOutlineChips() {
      this.setPrezResetAllFilters();
      this.searchModel = '';
      this.setPrezFilterItems([
        {
          display_title: this.$t('prezentation.mostRecent'),
          title: 'Most recent',
          field: 'createdAt',
          comparator: 'lt',
        },
        {
          display_title: this.$t('prezentation.oldestFirst'),
          title: 'Oldest first',
          field: 'createdAt',
          comparator: 'gt',
        },
        {
          display_title: this.$t('prezentation.aToZ'),
          title: 'A-Z',
          field: 'name',
          comparator: 'gt',
        },
        {
          display_title: this.$t('prezentation.zToA'),
          title: 'Z-A',
          field: 'name',
          comparator: 'lt',
        },
      ]);
      this.setPrezSelectedFilter({
        display_title: this.$t('prezentation.mostRecent'),
        title: 'Most recent',
        field: 'createdAt',
        comparator: 'lt',
      });
      this.templateAllFilter = { name: 'All', checked: true, disabled: false };
      this.selectedTemplates = [
        { name: 'All', checked: true, disabled: false },
      ];
      this.allThemes = [
        this.templateAllFilter,
        ...this.checkAndDisableAllValues([customTemplate, ...this.themes]),
      ];
      let prezs = [];
      const val = this.outlineChipClicked;
      if (val.length === 1 && val[0] === 'All') {
        prezs = this.allPrezentations;
      } else {
        prezs = this.allPrezentations.filter((prz) => val.includes(prz.type));
      }
      this.setFilterAndSearchedPrezentations(prezs);
      this.handleChangeFilter();
    },

    handleOutlinesChipsClick(outline) {
      trackPrezentationEvents.prezentationsStorylinechipclick(
        this.currentUser,
        {
          [TD_QUERY]: outline,
        },
      );
      if (outline !== 'All') {
        this.outlineChipClicked = this.outlineChipClicked.filter(
          (otl) => otl !== 'All',
        );
        if (this.outlineChipClicked.indexOf(outline) === -1) {
          this.outlineChipClicked.push(outline);
        } else {
          this.outlineChipClicked = this.outlineChipClicked.filter(
            (otl) => otl !== outline,
          );
          if (this.outlineChipClicked.length <= 0) {
            this.outlineChipClicked = ['All'];
          }
        }
        if (this.isChipActive(outline)) this.logIdeaChipClick(outline);
      } else {
        this.outlineChipClicked = ['All'];
      }
      this.handleSearchWithOutlineChips();
    },
    isChipActive(outline) {
      if (this.outlineChipClicked.indexOf(outline) !== -1) {
        return true;
      }
      return false;
    },
    logIdeaChipClick(item) {
      const itemClicked = item.toLowerCase();
      const payload = {
        searchTermResult: !!this.prezentationsByOwner.length,
        type: 'Prezentation Chip',
        searchTerm: itemClicked,
        searchTermTranslated: itemClicked,
      };
      logSearchQuery(payload)
        .then((resp) => {
          console.log(resp);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    templatesSelectedSearchResetFilters() {
      this.setPrezResetAllFilters();
      this.searchModel = '';
      this.setPrezFilterItems([
        {
          display_title: this.$t('prezentation.mostRecent'),
          title: 'Most recent',
          field: 'createdAt',
          comparator: 'lt',
        },
        {
          display_title: this.$t('prezentation.oldestFirst'),
          title: 'Oldest first',
          field: 'createdAt',
          comparator: 'gt',
        },
        {
          display_title: this.$t('prezentation.aToZ'),
          title: 'A-Z',
          field: 'name',
          comparator: 'gt',
        },
        {
          display_title: this.$t('prezentation.zToA'),
          title: 'Z-A',
          field: 'name',
          comparator: 'lt',
        },
      ]);
      this.setPrezSelectedFilter({
        display_title: this.$t('prezentation.mostRecent'),
        title: 'Most recent',
        field: 'createdAt',
        comparator: 'lt',
      });
      this.outlineChipClicked = ['All'];
    },

    async loadPrezentationsByOwner() {
      this.loadingPrezByOwner = true;
      let lastSortValue = false;
      let lastNumId = false;
      this.isActionDisabled = true;
      while (this.loadingPrezByOwner) {
        const response = await getPrezentationsForListView(
          lastSortValue,
          lastNumId,
          this.getOPAccessLevel !== 'no_access',
        );
        this.prezentationsByOwner = [
          ...this.prezentationsByOwner,
          ...this.formatPresentations(response.data.items),
        ];
        if (!this.isAdvancedFilterChanged) {
          this.setPrezsIntermittentlyOnPrezentationsLanding(
            this.formatPresentations(response.data.items),
          );
        }
        // if (this.searchTerm) {
        //   this.filterPrezentationsWithTextField(this.searchTerm, this);
        // let prezs = [];
        // prezs = [...this.allPrezentations].filter(
        //   (przs) =>
        //     przs.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
        //     //  przs.audience.toLowerCase().includes(e.toLowerCase()) ||
        //     przs.type
        //       ?.toLowerCase()
        //       .includes(this.searchTerm.toLowerCase()) ||
        //     przs.author
        //       ?.toLowerCase()
        //       .includes(this.searchTerm.toLowerCase()),
        // );
        // this.setFilterAndSearchedPrezentations(prezs);
        // }
        if (!response.data.nextToken.next) {
          this.isActionDisabled = false;
          this.loadingPrezByOwner = false;
        } else {
          lastSortValue = response.data.nextToken.last_sort_value;
          lastNumId = response.data.nextToken.last_num_id;
        }
        this.loadedFirstCall = false;
      }
      this.isAdvacnedFilterActive = true;
      if (this.searchTerm) {
        this.filterPrezentationsWithTextField(this.searchTerm, this);
      }
      // handling scenario where navigation is from snackbar items
      if (this.getNavFromDownloadSnackbarPrezs.navigated) {
        this.handleLoadWhenNavFromDownloadSnackbarForPrezs();
        this.setNavFromDownloadSnackbarPrezs({ navigated: false, type: '' });
      } else if (
        this.getNavFromAddToLibOrFavSnackbar.navigated &&
        ['addToLibPrez', 'addToFavPrez'].includes(
          this.getNavFromAddToLibOrFavSnackbar.type,
        )
      ) {
        this.handleLoadWhenNavFromLibOrFavSnackbarForPrezs(
          this.getNavFromAddToLibOrFavSnackbar.origin,
        );
        this.setNavFromAddToLibOrFavSnackbar({
          navigated: false,
          type: '',
          origin: '',
        });
      }

      return this.prezentationsByOwner;
      // );
      // return Promise.all(prezsPromises).then((resp) => {
      //   const { companyData } = this;
      //   const companyName = companyData.displayName
      //     ? companyData.displayName
      //     : companyData.name;
      //   if (companyData.name && companyName) {
      //     this.advancedItems[0].items[1].name = companyData.name;
      //     this.advancedItems[0].items[1].title =
      //       capitalizeFirstLetter(companyName);
      //   }
      //   console.log('response ->', resp);
      //   return resp;
      // });
    },

    formatPresentations(presentations) {
      for (let i = 0; i < presentations.length; i++) {
        presentations[i].sharedWith = presentations[i].shared
          ? 'Shared'
          : 'Private';
      }
      return presentations;
    },

    goToNewPrezentation() {
      this.$router.push(`/home/build`, () => {});
    },

    removeDuplicatesOutlinesFromDefault(trends, defaults) {
      const trendOutlinesNames = trends.map((tr) => tr.type);
      const defaultTrends = defaults.filter(
        (df) => !trendOutlinesNames.includes(df.name),
      );
      return defaultTrends;
    },

    getOutlineChipsClasss(outline) {
      let cls = '';
      if (this.outlineChipClicked.indexOf(outline.name) !== -1) {
        cls = 'outlineChipActive';
      } else {
        cls = 'outlineChipAvailable';
      }
      return outline.name === 'All' ? `${cls}All` : cls;
    },
    closeDatePickersOnScroll() {
      this.fromDateMenu = false;
      this.toDateMenu = false;
    },
    addNewPrezentation() {
      this.showAddlicenseModal();
      trackPrezentationEvents.prezentationsAddMoreClick(this.currentUser);
    },
    showAddlicenseModal() {
      this.$modal.show(
        AddPrezentation,
        { allThemes: this.allThemes },
        {
          name: 'AddPrezentation',
          width: '558px',
          height: 'auto',
          clickToClose: false,
          styles: {
            borderRadius: '15px',
            overflowY: 'auto',
          },
        },
      );
    },
    checkForAddMoreFeatureAvailable() {
      // No longer in use, replaced by 'upload to anonymize(sanitize)'
      return false;
    },
    handleQuickSelection(action) {
      EventBus.$emit('MINIMIZE_LEFTNAV');
      if (action && (this.toolType !== action || !this.toolType)) {
        this.toolType = action;
        this.showQuickSelection = true;
      } else if (this.toolType === action || !action) {
        this.toolType = null;
        this.showQuickSelection = false;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      EventBus.$emit('MINIMIZE_LEFTNAV');
    }, 50);
    EventBus.$on('GOTO_PREZENTATIONS', (query) => {
      this.searchTerm = query;
      this.searchModel = query;
      this.waitForAllPrezToLoad = true;
    });
    if (this.prezentationBackClicked && this.snapshot.Prezentations) {
      EventBus.$on('PREZ_ON_FILTER_CHANGE', this.onAdvancedFilterChange);
      EventBus.$on(
        'PREZENTATIONS_CLICK_ALL_CATEGORY',
        this.handleAllOptionClickForCategories,
      );
      EventBus.$on(
        'PREZ_RESET_ALL_FILTERS',
        this.resetAllFiltersOfAdvancedFilters,
      );
      EventBus.$on('PREZ_IS_FILTER_CHANGED', this.isAdvancedFilterChanged);
      EventBus.$on('PREZ_CHANGE_SORT', this.handleChangeFilter);
      Object.assign(this.$data, this.snapshot.Prezentations);
      setTimeout(() => {
        window.scrollTo({
          top: this.offsetTop,
          behaviour: 'smooth',
        });
      }, 200);
      return;
    }

    this.$store.dispatch('advancedFilterStore/setPrezAdvancedFilters', [
      {
        items: [
          {
            title: 'Prezentation Shells',
            display_title: 'filters.presentationShells',
            active: true,
            value: 'shell',
            key: 'prezentation-shells',
          },
          {
            title: 'Best-Practice Decks',
            display_title: 'filters.partiallyAnonymized',
            active: true,
            value: 'best practices',
            key: 'best-practice-guides',
          },
          {
            title: 'Original Decks',
            display_title: 'filters.original',
            active: true,
            value: 'original deck',
            key: 'original-deck',
          },
          // { title: 'Original Decks', active: false },
        ],
        checkAll: true,
        includeAllOption: true,
        title: 'Prezentation Type',
        display_title: 'filters.prezentationType',
        active: true,
        icon: 'prezentation-type',
      },
      {
        items: [
          {
            title: 'Build',
            display_title: 'advancedFiltersFeatureNames.story_builder',
            name: 'build',
            active: true,
            value: ['product'],
          },
        ],
        checkAll: true,
        includeAllOption: true,
        title: 'Created from',
        display_title: 'filters.createdFrom',
        active: true,
        icon: 'created-from',
      },
      {
        items: [
          {
            title: 'Me',
            display_title: 'fingerprint.compareFingAud.me',
            active: true,
            value: 'get_my_prezentations',
          },
          {
            title: 'My Colleagues',
            display_title: 'filters.myColleagues',
            active: true,
            value: 'get_team_prezentations',
          },
          {
            title: 'Brand Team',
            display_title: 'filters.brandTeam',
            active: true,
            value: 'get_team_prezentations',
          },
        ],
        checkAll: true,
        title: 'Author',
        display_title: 'build.step3.author',
        active: true,
        includeAllOption: true,
        icon: 'author',
      },
      {
        items: [
          {
            title: 'Restricted',
            display_title: 'prezentationShareStatus.restricted',
            active: true,
            disabled: false,
          },
          {
            title: 'Viewer',
            display_title: 'prezentationShareStatus.viewer',
            active: true,
          },
          {
            title: 'Reviewer',
            display_title: 'prezentationShareStatus.reviewer',
            active: true,
          },
          {
            title: 'Editor',
            display_title: 'prezentationShareStatus.editor',
            active: true,
          },
          {
            title: 'Shared',
            display_title: 'prezentationShareStatus.shared',
            active: true,
          },
          {
            title: 'Private',
            display_title: 'prezentationShareStatus.private',
            active: true,
          },
          // { title: 'Original Decks', active: false },
        ],
        checkAll: true,
        title: 'Access',
        display_title: 'filters.access',
        active: true,
        includeAllOption: true,
        icon: 'access',
      },
      {
        items: [
          {
            title: 'Prezentations to Review',
            display_title: 'filters.prezentationReview',
            active: false,
          },
        ],
        checkAll: true,
        title: 'Comment Mentions',
        display_title: 'filters.commentMention',
        active: true,
        includeAllOption: false,
        icon: 'comment-mentions',
      },
      {
        items: [
          {
            title: 'My Favorites',
            display_title: 'filters.myFavorites',
            active: false,
          },
        ],
        checkAll: true,
        title: 'Favorites',
        display_title: 'slides.favourites',
        active: true,
        includeAllOption: false,
        icon: 'favorites',
      },
      {
        items: [
          {
            title: 'My Downloads',
            display_title: 'filters.myDownloads',
            active: false,
          },
        ],
        checkAll: true,
        title: 'Downloads',
        display_title: 'slides.downloads',
        active: true,
        includeAllOption: false,
        icon: 'downloads',
      },
      {
        checkAll: true,
        title: 'Created/Uploaded on',
        display_title: 'filters.filterByDate',
        active: true,
        includeAllOption: false,
        icon: 'created-on',
        isCustomGroup: true,
        isDateChanged: false,
      },
    ]);
    this.setPrezAdvancedItems(JSON.parse(JSON.stringify(this.advancedFilters)));
    if (this.isGenerateEnabled) {
      const generateFilter = {
        title: 'Generate',
        display_title: 'advancedFiltersFeatureNames.auto_generator',
        name: 'generate',
        active: true,
        value: ['generated'],
      };
      const tempAdvancedItems = JSON.parse(JSON.stringify(this.advancedItems));
      tempAdvancedItems
        .find((item) => item.title === 'Created from')
        .items.push(generateFilter);
      this.setPrezAdvancedItems(tempAdvancedItems);

      const tempAdvancedFilters = JSON.parse(
        JSON.stringify(this.advancedFilters),
      );
      tempAdvancedFilters
        .find((item) => item.title === 'Created from')
        .items.push({ ...generateFilter });
      this.setPrezAdvancedFilters(tempAdvancedFilters);
    }
    if (this.isRedesignEnabled) {
      const generateFilter = {
        title: 'Redesign',
        display_title: 'advancedFiltersFeatureNames.redesign',
        name: 'redesign',
        active: true,
        value: ['redesign'],
      };
      const tempAdvancedItems = JSON.parse(JSON.stringify(this.advancedItems));
      tempAdvancedItems
        .find((item) => item.title === 'Created from')
        .items.push(generateFilter);
      this.setPrezAdvancedItems(tempAdvancedItems);

      const tempAdvancedFilters = JSON.parse(
        JSON.stringify(this.advancedFilters),
      );
      tempAdvancedFilters
        .find((item) => item.title === 'Created from')
        .items.push({ ...generateFilter });
      this.setPrezAdvancedFilters(tempAdvancedFilters);
    }

    if (this.isComplyEnabled) {
      const generateFilter = {
        title: 'Comply',
        display_title: 'advancedFiltersFeatureNames.comply',
        name: 'comply',
        active: true,
        value: ['comply'],
      };
      const tempAdvancedItems = JSON.parse(JSON.stringify(this.advancedItems));
      tempAdvancedItems
        .find((item) => item.title === 'Created from')
        .items.push(generateFilter);
      this.setPrezAdvancedItems(tempAdvancedItems);

      const tempAdvancedFilters = JSON.parse(
        JSON.stringify(this.advancedFilters),
      );
      tempAdvancedFilters
        .find((item) => item.title === 'Created from')
        .items.push({ ...generateFilter });
      this.setPrezAdvancedFilters(tempAdvancedFilters);
    }
    if (this.isUploadLimittedAccess) {
      const uploadFilter = {
        title: 'Upload',
        display_title: 'advancedFiltersFeatureNames.uploader',
        name: 'upload',
        active: true,
        value: ['iocupload', 'user uploaded'],
      };
      const tempAdvancedItems = JSON.parse(JSON.stringify(this.advancedItems));
      tempAdvancedItems
        .find((item) => item.title === 'Created from')
        .items.push(uploadFilter);
      this.setPrezAdvancedItems(tempAdvancedItems);

      const tempAdvancedFilters = JSON.parse(
        JSON.stringify(this.advancedFilters),
      );
      tempAdvancedFilters
        .find((item) => item.title === 'Created from')
        .items.push({ ...uploadFilter });
      this.setPrezAdvancedFilters(tempAdvancedFilters);
    }
    if (this.getOPAccessLevel !== 'no_access') {
      const tempAdvancedItems = JSON.parse(JSON.stringify(this.advancedItems));
      tempAdvancedItems
        .find((item) => item.title === 'Created from')
        .items.push({
          title: 'Overnight Prezentations',
          display_title: 'overnightPresentations.overnightPresentation',
          name: 'overnight',
          active: true,
          value: ['op'],
        });
      this.setPrezAdvancedItems(tempAdvancedItems);

      const tempAdvancedFilters = JSON.parse(
        JSON.stringify(this.advancedFilters),
      );
      tempAdvancedFilters
        .find((item) => item.title === 'Created from')
        .items.push({
          title: 'Overnight Prezentations',
          display_title: 'overnightPresentations.overnightPresentation',
          name: 'overnight',
          active: true,
          value: ['op'],
        });
      this.setPrezAdvancedFilters(tempAdvancedFilters);
    }
    if (!this.isUploadLimittedAccess && this.isGenerateEnabled) {
      const tempAdvancedItems = JSON.parse(JSON.stringify(this.advancedItems));
      tempAdvancedItems
        .find((item) => item.title === 'Prezentation Type')
        .items.filter((item) => item.title !== 'Best-Practice Decks');

      const tempAdvancedFilters = JSON.parse(
        JSON.stringify(this.advancedFilters),
      );
      this.setPrezAdvancedItems(tempAdvancedItems);

      tempAdvancedFilters
        .find((item) => item.title === 'Prezentation Type')
        .items.filter((item) => item.title !== 'Best-Practice Decks');
      this.setPrezAdvancedFilters(tempAdvancedFilters);
    }
    if (
      !this.isUploadLimittedAccess &&
      !this.isGenerateEnabled &&
      !this.isComplyEnabled
    ) {
      const tempAdvancedItems = JSON.parse(JSON.stringify(this.advancedItems));
      const tempAdvancedFilters = JSON.parse(
        JSON.stringify(this.advancedFilters),
      );
      tempAdvancedItems.splice(0, 1);
      tempAdvancedFilters.splice(0, 1);

      this.setPrezAdvancedItems(tempAdvancedItems);
      this.setPrezAdvancedFilters(tempAdvancedFilters);
    }
    if (
      !this.isUploadLimittedAccess &&
      !this.isGenerateEnabled &&
      !this.isComplyEnabled &&
      this.getOPAccessLevel === 'no_access'
    ) {
      const tempAdvancedItems = JSON.parse(JSON.stringify(this.advancedItems));
      const tempAdvancedFilters = JSON.parse(
        JSON.stringify(this.advancedFilters),
      );
      tempAdvancedItems.splice(0, 1);
      tempAdvancedFilters.splice(0, 1);

      this.setPrezAdvancedItems(tempAdvancedItems);
      this.setPrezAdvancedFilters(tempAdvancedFilters);
    }
    if (this.themes.length) {
      this.setFilteredThemes(this.themes);
    }
    // this.populateCompanyInAdvFilters();
    getFrequentlyUserOutlinesByUser()
      .then((resp) => {
        const outlinesFrequencies = resp?.data?.items ? resp.data.items : [];
        if (outlinesFrequencies.length < 10) {
          const frequenciesSize = outlinesFrequencies.length;
          const itemsToSubstitute = 10 - frequenciesSize;
          this.trendingOutlines = [
            ...outlinesFrequencies.map((outl) => ({
              ...outl,
              name: outl.type,
            })),
          ].concat(
            this.removeDuplicatesOutlinesFromDefault(
              outlinesFrequencies,
              this.defaultOutlines,
            ).slice(0, itemsToSubstitute),
          );
        } else {
          this.trendingOutlines = [
            ...outlinesFrequencies.map((outl) => ({
              ...outl,
              name: outl.type,
            })),
          ];
        }
        this.trendingOutlines = [{ name: 'All' }, ...this.trendingOutlines];
      })
      .catch((err) => {
        console.log(err);
        this.trendingOutlines = [{ name: 'All' }, ...this.defaultOutlines];
      });
    this.setUserSelectedPrezentationView(this.viewTypes.MY_PREZENTATIONS);
    this.prezentationsByOwner = [];
    window.addEventListener('scroll', this.closeDatePickersOnScroll);
    this.getDatePickerWidth();
    window.addEventListener('resize', this.getDatePickerWidth);
    EventBus.$on('PREZ_ON_FILTER_CHANGE', this.onAdvancedFilterChange);
    EventBus.$on(
      'PREZENTATIONS_CLICK_ALL_CATEGORY',
      this.handleAllOptionClickForCategories,
    );
    EventBus.$on(
      'PREZ_RESET_ALL_FILTERS',
      this.resetAllFiltersOfAdvancedFilters,
    );
    EventBus.$on('PREZ_IS_FILTER_CHANGED', this.isAdvancedFilterChanged);
    EventBus.$on('PREZ_CHANGE_SORT', this.handleChangeFilter);
  },
  beforeDestroy() {
    this.loadingPrezByOwner = false;
    this.setAllPrezentationsForLandingPage([]);
    this.setFilterAndSearchedPrezentations([]);
    EventBus.$off('PREZ_ON_FILTER_CHANGE', this.onAdvancedFilterChange);
    EventBus.$off(
      'PREZENTATIONS_CLICK_ALL_CATEGORY',
      this.handleAllOptionClickForCategories,
    );
    EventBus.$off(
      'PREZ_RESET_ALL_FILTERS',
      this.resetAllFiltersOfAdvancedFilters,
    );
    EventBus.$off('PREZ_IS_FILTER_CHANGED', this.isAdvancedFilterChanged);
    EventBus.$off('PREZ_CHANGE_SORT', this.handleChangeFilter);
    EventBus.$off('GOTO_PREZENTATIONS');
  },
  destroyed() {
    this.setAllPrezentationsForLandingPage([]);
    this.setFilterAndSearchedPrezentations([]);
    window.removeEventListener('scroll', this.closeDatePickersOnScroll);
    window.removeEventListener('resize', this.getDatePickerWidth);
  },
};
</script>

<style lang="scss" scoped>
.prez-wrapper {
  display: flex;
}

.quick-selection-wrapper {
  ::v-deep .quick-selection-cntr {
    .templates-cntr {
      max-height: calc(100vh - 250px);
    }
  }
}
.quick-panel-active {
  width: calc(100% - 550px) !important;
}
.quick-panel {
  width: 550px;
}
.filter-op-title__wrapper {
  align-items: baseline;
  display: flex;
  .badge-container {
    display: flex;
    position: absolute;
    right: 0;
    top: -4px;
    z-index: 4;
  }
}

.prezentations-container {
  width: 100%;
  padding: 20px 30px;

  .prezentations-type-wrapper {
    display: flex;
    width: 100%;

    ::v-deep .v-slide-group__prev {
      justify-content: left !important;
      align-items: left !important;
      min-width: 40px !important;
    }
    ::v-deep .v-slide-group__next {
      justify-content: right !important;
      align-items: right !important;
      min-width: 40px !important;
    }

    .v-sheet {
      .v-item-group {
        .v-slide-group__wrapper {
          .v-slide-group__content {
            .outlineChipActiveAll,
            .outlineChipAvailableAll {
              border-radius: 40px;
              font-size: 16px;
              font-weight: 500;
              height: 40px;
              margin-right: 18px !important;
              padding: 0 25px !important;
            }
            .outlineChipActiveAll {
              background-color: #21a7e0 !important;
              color: white !important;
            }
            .outlineChipAvailableAll {
              background-color: white !important;
              color: #21a7e0 !important;
              border-color: #21a7e0 !important;
            }
            .outlineChipActive,
            .outlineChipAvailable {
              border-radius: 40px;
              font-size: 16px;
              font-weight: 500;
              height: 40px;
              margin-right: 18px !important;
              padding: 0 25px !important;
              width: 210px !important;
              min-width: 210px !important;
              ::v-deep .v-chip__content {
                width: 100% !important;
                min-width: 100% !important;
                max-width: 100% !important;
                .textWrapper {
                  width: 100%;
                  text-align: center;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              &:hover {
                width: auto !important;
                transition: width 1s, visibility 1s, opacity 1s ease-out;
              }
              &:before {
                opacity: 0 !important;
              }
            }
            .outlineChipActive {
              background-color: #21a7e0 !important;
              color: white !important;
            }
            .outlineChipAvailable {
              background-color: white !important;
              color: #21a7e0 !important;
              border-color: #21a7e0 !important;
            }
          }
        }
      }
    }
  }
  .filtersWithAdvaced {
    margin: 24px 0px 24px 0px;
    padding: 0px 0px 0px 8px;
    display: flex;
    justify-content: space-between;

    .sort-and-search-section {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .prezentations-search-bar {
        ::placeholder {
          color: #757575 !important;
        }
        .prezentations-search-bar__input {
          background-color: #fff;
          border: 1px solid transparent;
          border-radius: 24px;
          box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
          display: flex;
          font-size: 14px;
          height: 40px !important;
          padding: 2px 14px 8px 20px;
          width: 380px;
          margin: 0px 42px 0px 0px;
          z-index: 3;
          ::v-deep .v-input__control {
            .v-input__slot {
              .v-input__append-inner {
                margin: 0;
                padding: 4px 0px 0px 4px;
              }
              .v-text-field__slot {
                input {
                  caret-color: black !important;
                }
              }
            }
          }

          ::v-deep .v-input__slot::before {
            border-style: none !important;
            display: none;
          }
          ::v-deep .v-input__icon {
            margin-right: -8px;
          }
        }
      }
      .slides-filter__sort,
      .slides-filter__template {
        display: flex;
        align-items: center;
        margin-right: 10px;
        flex-direction: row;
        .label {
          padding: 12px 12px 12px 0px;
        }
      }
      .slides-filter__sort {
        .filter-by {
          flex: none;
          width: 200px;
          font-size: 14px;
          ::v-deep .v-text-field__details {
            display: none;
          }
          ::v-deep .v-input__slot {
            border-bottom: 0.8px solid #b6b6b6;
            box-shadow: none !important;
            .v-select__slot {
              input {
                padding: 8px 0px 0px 0px !important;
              }
            }
          }
        }
        .label {
          margin-right: 0.5rem;
        }
      }
      .slides-filter__template {
        .templates-dropdown {
          flex: none;
          width: 200px;
          font-size: 14px !important;
          font-weight: 400;
          line-height: 19px;
          flex: none;
          margin: 0;
          ::v-deep .v-input__control {
            min-height: 38px !important;
            .v-input__slot {
              margin: 0;
              border-bottom: 0.8px solid #b6b6b6 !important;
              border-radius: 0px 0px 4px 4px !important;
              padding: 0 12px !important;
              .v-select__slot {
                .v-select__selections {
                  padding: 8px 0px 0px 0px;
                  .selected-type {
                    max-width: 57% !important;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  }
                }
                .v-input__append-inner {
                  padding: 10px 0px 0px 4px !important;
                }
              }
              &:before {
                border: none !important;
              }
              &:after {
                border: none !important;
              }
            }
            .v-text-field__details {
              display: none !important;
            }
          }
          ::v-deep .v-menu__content {
            .v-list {
              .v-list-item {
                padding: 0;
                min-height: auto;
                margin: 0px 0px 4px 0px;
                .template-list-item {
                  width: 100% !important;
                  .allOption {
                    width: 100% !important;
                    margin: 0 !important;
                    padding: 0 !important;
                    .v-input__control {
                      .v-input__slot {
                        padding: 0 !important;
                        border-bottom: none !important;
                        .v-input--selection-controls__input {
                          margin: 16px 0px 16px 16px;
                        }
                        .v-label {
                          margin: 0px 0px 10px 8px;
                        }
                      }
                    }
                  }
                  .row {
                    width: 100%;
                    margin: 0 !important;
                    .checkboxWithName {
                      display: flex;
                      width: 100%;
                      .v-input {
                        margin: 0;
                        padding: 0;
                        .v-input__control {
                          .v-input__slot {
                            border-bottom: none !important;
                            padding: 0 !important;
                            .v-input--selection-controls__input {
                              margin: 0px 0px 0px 16px;
                            }
                            .v-label {
                              margin: 0px 0px 6px 8px;
                            }
                          }
                        }
                      }
                      .template-title {
                        margin: 8px 0px 0px 8px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      }
                    }
                    .imagesRow {
                      width: 100%;
                      display: flex !important;
                      height: 104px !important;
                      padding: 0 12px;
                      .available-theme-img {
                        padding: 8px 6px 8px 8px;
                      }
                      .example-slide-img {
                        padding: 8px 8px 8px 6px;
                      }
                      .available-theme-img,
                      .example-slide-img {
                        width: 50%;
                        height: 100%;
                        .thumbnailWrapper {
                          width: 100%;
                          height: 100%;
                          border-radius: 4px;
                          box-shadow: 0.413462px 0.413462px 1.65385px
                              rgb(0 0 0 / 10%),
                            -0.413462px -0.413462px 1.65385px;
                          .v-image {
                            width: 100%;
                            height: 100%;
                            border-radius: 4px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .v-select-list {
              .no-templates-found {
                .no-result-text {
                  margin: 0 !important;
                  padding: 20px 0px 20px 20px !important;
                  color: #000000 !important;
                  font-family: 'Lato' !important;
                  font-style: normal !important;
                  font-weight: 400 !important;
                  font-size: 16px !important;
                  line-height: 19px !important;
                }
              }
            }
          }
        }
      }
      .add-btn {
        color: #fff;
        font-size: 16px;
        margin-top: -3px !important;
        margin-left: 12%;
        letter-spacing: normal;
      }
    }

    .right-filters {
      display: flex;
      align-items: center;
      gap: 0.75rem;

      .quick-action-cta {
        display: flex;
        gap: 0.75rem;
      }

      .filter-btn {
        color: #21a7e0;
        border-radius: 10px;
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        letter-spacing: 0.01em;
        text-align: center;
        height: 100%;
        min-width: 100px;
        padding: 0.5rem;
        display: flex;
        gap: 0.5rem;
        justify-content: space-between;
        align-items: center;
        &:disabled {
          color: rgba(0, 0, 0, 0.26) !important;
        }
      }

      .filter-btn-active {
        background-color: #e1f5fb;
      }

      .uploadCTA {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .v-btn {
          background-color: #21a7e0;
          color: #ffffff;
          text-transform: none;
          letter-spacing: normal;
          border-radius: 20px;
          box-shadow: none;
          font-weight: 600;
        }
      }

      .advanced-filter {
        width: 5%;
        .advancedActive,
        .advancedInActive {
          cursor: pointer;
        }
        .advancedActive {
          color: #21a7e0 !important;
        }
      }
    }
  }
  .prez-card-list-wrapper {
    display: flex;
    ::v-deep .prezList {
      width: 100%;
      .spinning-loading-wrapper {
        position: relative !important;
      }
    }
    .advanced-filter-wrapper {
      box-shadow: -10px 0px 16px -6px rgba(0, 0, 0, 0.15);
      width: 360px;
      z-index: 4;
      height: calc(100vh - 70px);
      top: 70px;
      bottom: 0px;
      position: sticky;
      transition: width 1s;
      width: 20%;

      ::v-deep .exp-filter-header {
        display: flex;
        align-items: center;
        gap: 5px;

        .filter-icon {
          width: 24px;
          height: 24px;
        }
        .badge-container {
          position: absolute;
          z-index: 4;
          left: 130px;
          bottom: 25px;
        }
        .badge-container-author {
          position: absolute;
          z-index: 4;
          left: 90px;
          bottom: 25px;
        }
      }
      .customGroupWrapper {
        margin-left: 4px;
        padding: 15px;
        .fromDateWrapper,
        .toDateWrapper {
          .label {
            font-size: 16px;
            font-weight: 600;
            color: black;
          }
          .datePicker {
            .v-input {
              .v-input__control {
                .v-input__slot {
                  .v-input__append-inner {
                    .clearIcon,
                    .calendarIcon {
                      &:hover {
                        color: #21a7e0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .applyDatesCTA {
          width: 60%;
          margin: 0 auto;
          .v-btn {
            background: transparent;
            border: none;
            box-shadow: none;
            letter-spacing: normal;
            text-transform: none;
            font-size: 16px;
            color: #21a7e0;
            width: 100%;
            background-color: transparent !important;
          }
        }
      }
      ::v-deep .v-expansion-panels {
        height: calc(100% - 100px);
        overflow: auto;
        .v-expansion-panel {
          flex: none;
          width: 100%;
          &::before {
            box-shadow: none;
          }

          &::after {
            opacity: 0 !important;
          }
        }

        .v-expansion-panel-header {
          padding: 0 16px;
          min-height: 47px !important;
        }
        .v-expansion-panel-content__wrap {
          padding: 0;
        }
        .v-list-item {
          min-height: 30px !important;
          padding: 0;
          padding-left: 60px;
          .v-list-item__action {
            margin: 0px 7px 0px 0px;
          }
          .v-list-item__content {
            padding: 8px 0px;
            .v-list-item__title {
              font-size: 16px;
              line-height: 18px !important;
            }
          }
        }
      }
      .filter-header {
        padding: 5px 10px 5px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3 {
          display: inline-block;
          font-size: 17px;
        }
      }

      .clear-text {
        color: #21a7e0 !important;
        cursor: pointer;
      }
      .resetFilters {
        display: flex;
        padding: 15px 15px 15px 15px;
        justify-content: space-between;
        .resetText {
          color: #21a7e0 !important;
          cursor: pointer;
        }
      }
      .disabledResetFilters {
        display: flex;
        padding: 15px 0px 15px 15px;
        padding: 15px 15px 15px 15px;
        justify-content: space-between;
        .v-icon,
        .resetText {
          color: grey;
          margin: 0px 0px 0px 5px;
          cursor: default;
        }
      }
    }
  }
  .go-top {
    position: fixed;
    bottom: 6px;
    left: 48%;
    background-color: #21a7e0;
    padding: 3px 10px;
    cursor: pointer;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    color: #fff !important;
    font-size: 13px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-shadow: 0 -1px 5px 1px rgb(64 60 67 / 16%);
    // animation: fadein 2s linear 1 forwards;
    // opacity: 0;

    // &.show {
    //   animation: fadein 2s linear 1 forwards;
    //   opacity: 0;
    // }

    // &.hide {
    //   animation: fadein 2s linear 1 forwards;
    //   opacity: 0;
    // }

    .top-arrow {
      margin-right: 5px;
      margin-bottom: 3px;
      font-size: 14px;
    }
  }
}
::v-deep .v-menu__content {
  .v-picker {
    .v-picker__body {
      .v-date-picker-table {
        table {
          tbody {
            tr {
              td {
                .v-btn {
                  color: #21a7e0 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
::v-deep .v-input {
  .v-input__control {
    .v-input__slot {
      .v-input__append-inner {
        margin: 0;
        padding: 4px 0px 0px 4px;
      }
      &::before,
      &::after {
        border: none !important;
      }
    }
  }
}
</style>
