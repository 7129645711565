export const slideLimit = {
  convertTemplate: 30,
  synthesize: 30,
  anonymize: 100,
  // redesign: 30,
  // autoGenerate: 30,
};

export const mainActions = [
  {
    id: 'addSlide',
    label: 'build.step3.addSlideText',
    tooltip: 'build.step3.addSlideFromGallery',
    icon: 'mdi-plus',
    isCustomIcon: false,
    dataPendoId: 'story-builder-add-slide',
  },
  {
    id: 'editSlide',
    labelText: 'Edit slide',
    tooltipText: 'Edit slide',
    icon: 'mdi-file-edit-outline',
    isCustomIcon: false,
    dataPendoId: 'story-builder-edit-slide',
  },
  {
    id: 'convertTemplate',
    labelText: 'Convert template',
    tooltipText: 'Convert template',
    icon: 'comply-icon',
    isCustomIcon: true,
    dataPendoId: 'prez-tc-btn',
  },
  {
    id: 'changeAudiance',
    labelText: ' Change audience',
    tooltipText: 'Change audience',
    icon: 'mdi-account-group',
    isCustomIcon: false,
    dataPendoId: 'prez-audience-btn',
  },
  {
    id: 'modifyStoryline',
    labelText: 'Modify storyline',
    tooltipText: 'Modify storyline',
    icon: 'mdi-pencil-outline',
    isCustomIcon: false,
    dataPendoId: 'prez-storyline-btn',
  },
  {
    id: 'synthesize',
    labelText: 'Synthesize',
    tooltipText: 'Synthesize',
    icon: 'synthesize-icon',
    isCustomIcon: true,
    dataPendoId: 'prez-synthesize-btn',
  },
  {
    id: 'anonymize',
    labelText: 'Anonymize',
    tooltipText: 'Anonymize',
    icon: 'mdi-list-box-outline',
    isCustomIcon: false,
    dataPendoId: 'prez-anonymize-btn',
  },
  // {
  //   id: 'convertTemplate',
  //   labelText: 'Convert template',
  //   tooltipText: 'Convert template',
  //   icon: 'comply-icon',
  //   isCustomIcon: true,
  // },
  // {
  //   id: 'generateSlide',
  //   labelText: 'Generate slide',
  //   tooltipText: 'Generate slide',
  //   icon: 'mdi-auto-fix',
  //   isCustomIcon: false,
  // },
];

export const slideActions = {
  editSlide: [
    {
      id: 'changeLayout',
      labelText: 'Change layout',
      tooltipText: 'Change layout',
      icon: 'mdi-view-split-vertical',
      isCustomIcon: false,
      pendoId: 'slide-action-change-layout',
    },
    {
      id: 'changeVisual',
      labelText: 'Replace visuals',
      tooltipText: 'Replace visuals',
      icon: 'mdi-chart-box-outline',
      isCustomIcon: false,
      pendoId: 'slide-action-change-visual',
    },
    {
      id: 'changeNodeCount',
      labelText: 'Edit node count',
      tooltipText: 'Edit node count',
      icon: 'mdi-file-tree-outline',
      isCustomIcon: false,
      pendoId: 'slide-action-change-node-count',
    },
    {
      id: 'changeEmotion',
      labelText: 'Change emotion',
      tooltipText: 'Change emotion',
      icon: 'emotion-icon',
      isCustomIcon: true,
      pendoId: 'slide-action-change-emotion',
    },
    {
      id: 'changeFormat',
      labelText: 'Change format',
      tooltipText: 'Change format',
      icon: 'format-icon',
      isCustomIcon: true,
      pendoId: 'slide-action-change-format',
    },
    {
      id: 'dataStyle',
      labelText: 'Modify data style',
      tooltipText: 'Modify data style',
      icon: 'data-style-icon',
      isCustomIcon: true,
      pendoId: 'slide-action-change-data-style',
    },
    {
      id: 'changeTone',
      labelText: 'Change tone',
      tooltipText: 'Change tone',
      icon: 'tone-icon',
      isCustomIcon: true,
      pendoId: 'slide-action-change-tone',
    },
    {
      id: 'editTag',
      labelText: 'Edit tags',
      tooltipText: 'Edit tags',
      icon: 'mdi-tag-multiple-outline',
      isCustomIcon: false,
    },
    {
      id: 'editBestPractices',
      labelText: 'Edit best practices',
      tooltipText: 'Edit best practices',
      icon: 'mdi-lightbulb-on-outline',
      isCustomIcon: false,
    },
    {
      id: 'addToSlides',
      labelText: 'Add to Slide Library',
      tooltipText: 'Add to Slide Library',
      icon: 'mdi-tray-arrow-up',
      isCustomIcon: false,
      noRightPanel: true,
    },
    {
      id: 'duplicateSlide',
      labelText: 'Duplicate slide',
      tooltipText: 'Duplicate Slide',
      icon: 'mdi-content-copy',
      isCustomIcon: false,
      noRightPanel: true,
      pendoId: 'slide-action-more-options',
    },
    {
      id: 'deleteSlide',
      labelText: 'Delete slide',
      tooltipText: 'Delete Slide',
      icon: 'mdi-trash-can-outline',
      isCustomIcon: false,
      noRightPanel: true,
      pendoId: 'slide-action-more-options',
    },
  ],
};
