<template>
  <div
    v-if="Object.keys(prezData).length > 0"
    class="prezentation__container"
    :data-pendo-id="index === 0 ? 'prezentations-card' : null"
  >
    <v-card
      style="
         {
          color: white;
        }
      "
      class="mx-auto card-wrapper"
    >
      <div
        class="carouselOrImgWrapper"
        @mouseenter="handleGetCarouselThumbnails"
        @mouseleave="handleCardLeave"
        @click="onClick(prezData)"
      >
        <div class="icons-wrapper" v-if="isDownloaded">
          <v-tooltip
            bottom
            max-width="200"
            content-class="tooltip-content"
            fixed
          >
            <template v-slot:activator="{ on, attrs }">
              <v-img
                v-bind="attrs"
                v-on="on"
                src="/assets/img/already-downloaded.svg"
                alt
                width="34px"
                height="34px"
              />
            </template>
            <span>{{ $t('common.downloaded') }}</span>
          </v-tooltip>
        </div>
        <v-carousel
          :key="carouselKey"
          hide-delimiters
          cycle
          :show-arrows="false"
          :interval="3500"
        >
          <v-carousel-item
            v-for="(item, i) in prezData.prezentationCarouselsThumbnails"
            :key="i"
          >
            <v-img
              :src="item"
              alt="image"
              min-width="200"
              :aspect-ratio="16 / 9"
              :elevation="isCardHovered === true ? 12 : 2"
              class="blue--text align-end"
              lazy-src="/assets/img/slides/placeholder-slide.svg"
              @load="onCarouselImageLoad(i)"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5" />
                </v-row>
              </template>
            </v-img>
          </v-carousel-item>
        </v-carousel>
        <div class="image-wrapper">
          <div class="badge-container">
            <v-badge
              v-if="showNewContentTag"
              :content="$t('common.newContent')"
              overlap
              tile
              offset-x="0"
              offset-y="0"
              :style="{ zIndex: 4 }"
            ></v-badge>
          </div>
          <v-img
            :src="thumbnailUrl"
            alt="image"
            min-width="200"
            :aspect-ratio="16 / 9"
            :elevation="isCardHovered === true ? 12 : 2"
            class="blue--text align-end"
            lazy-src="/assets/img/slides/placeholder-slide.svg"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5" />
              </v-row>
            </template>
          </v-img>
        </div>
      </div>
      <div
        class="prezInfoAndActions"
        :data-pendo-id="index === 0 ? 'prezentations-summary' : null"
      >
        <div class="nameAndActions">
          <div class="name" v-if="!isEditing" @click="onClick(prezData)">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ prezData.name }}
                </span>
                <img
                  v-if="isPrezentSourceBestPractice"
                  src="/assets/img/prezent_avatar.svg"
                  alt
                  width="22px"
                />
              </template>
              <span>{{ prezData.name }}</span>
            </v-tooltip>
          </div>
          <div class="card-edit-name" v-if="isEditing">
            <div class="errorText" v-if="prezData.name.length > 85">
              {{ $t('prezentationsList.nameCannotExceed') }}
            </div>
            <v-text-field
              v-model="prezData.name"
              hide-details
              :label="prezData.name"
              solo
              @focus="handleEditNameFocus()"
              @blur="handleEditNameBlur()"
              autofocus
            >
              <template #append>
                <v-btn
                  color="white"
                  rounded
                  min-width="25"
                  height="40"
                  @click="handleSubmit"
                  :disabled="prezData.name === '' || prezData.name.length > 85"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </div>
          <div
            class="actions"
            :data-pendo-id="index === 0 ? 'prezentations-quick-actions' : null"
          >
            <v-progress-circular
              indeterminate
              color="primary"
              v-if="isChangingFavorite === true"
              :size="22"
            />
            <template v-else>
              <v-tooltip
                bottom
                max-width="200"
                content-class="tooltip-content"
                fixed
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="!isFavorite"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleAddPrezAsFav(prezData)"
                  >
                    mdi-heart-outline
                  </v-icon>
                  <v-icon
                    v-else
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleRemovePrezAsFav(prezData)"
                  >
                    mdi-heart
                  </v-icon>
                </template>
                <span>
                  {{
                    `${
                      isFavorite && isFavorite === true
                        ? $t('bestPracticesGuide.knowSlideCard.removeFavorites')
                        : $t('bestPracticesGuide.knowSlideCard.addFavorites')
                    }`
                  }}
                </span>
              </v-tooltip>
            </template>
            <v-progress-circular
              indeterminate
              color="primary"
              v-if="isLocalDownloading"
              :size="22"
            />
            <Download
              v-else
              :mode="'icon'"
              :file-name="getFilename()"
              :download-file-name="getDownloadFilename()"
              :merged-url="getMergedUrl"
              :item="prezData"
              :log-download="logDownload"
              :on-download="() => handlePrezDownload()"
              origin="prezentation"
              :speedDialPosTop="'-140'"
              :speedDialPosRight="'0'"
              limitsKey="prezentation"
            />
            <v-menu
              top
              absolute
              offset-y
              :close-on-click="true"
              :close-on-content-click="closeEllipsisMenu"
              :position-x="menuPos.x"
              :position-y="menuPos.y"
              :key="menuKey"
            >
              <template #activator="menu">
                <v-tooltip bottom>
                  <template #activator="tooltip">
                    <v-icon
                      v-on="{ ...menu.on, ...tooltip.on }"
                      color="primary"
                    >
                      mdi-dots-vertical
                    </v-icon>
                  </template>
                  <span>{{ $t('slides.moreActions') }}</span>
                </v-tooltip>
              </template>

              <v-list>
                <v-list-item
                  v-for="(action, index) in getActionsWithPermissions(
                    prezData.prezType,
                    prezData.permission_level,
                  )"
                  :key="index"
                  @click="handleDotOptionClick(action.name)"
                >
                  <v-list-item-title>
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <div
                          v-on="on"
                          v-if="action.name === 'Copy link'"
                          class="copyLink"
                        >
                          <div class="copyIconAndTitle">
                            <div class="icon">
                              <v-icon>{{ action.icon }}</v-icon>
                            </div>
                            <div class="actionName">
                              {{ $t(action.display_title) }}
                            </div>
                          </div>
                          <div v-if="showCopied === true" class="copiedStatus">
                            <v-icon small color="#21a7e0">mdi-check</v-icon>
                            <div>{{ $t('common.copied') }}</div>
                          </div>
                        </div>
                        <div v-else v-on="on" class="otherActions">
                          <div class="icon">
                            <v-icon>{{ action.icon }}</v-icon>
                          </div>
                          <div class="actionName">
                            {{ $t(action.display_title) }}
                          </div>
                        </div>
                      </template>
                      <span>
                        {{ $t(action.display_title) }}
                      </span>
                    </v-tooltip>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <div class="typeAndDate" @click="onClick(prezData)">
          <div class="type">
            {{ $t('prezentationsList.storylineType', { type: prezData.type }) }}
          </div>
          <template v-if="!isPrezentSourceBestPractice">
            <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
            <div class="date">
              {{
                `${
                  prezData.prezentationSource === 'IOCUpload' ||
                  prezData.prezentationSource === 'User Uploaded'
                    ? $t('build.step3.updatedOn')
                    : prezData.prezentationSource === 'Generated'
                    ? $t('build.step3.generatedOn')
                    : prezData.prezentationSource === 'Redesign'
                    ? $t('build.step3.redesignedOn')
                    : prezData.prezentationSource === 'Comply'
                    ? $t('build.step3.addedOn')
                    : $t('build.step3.createdOn')
                } ${getDateWithMoment(prezData.createdAt)}`
              }}
            </div>
          </template>
        </div>
        <div class="authorAndAudience" @click="onClick(prezData)">
          <div class="author">
            {{
              $t('prezentationsList.sourceLabel', {
                sourceName: sourceName,
              })
            }}
          </div>
          <template v-if="!isPrezentSourceBestPractice">
            <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
            <div class="audience">
              {{
                $t('prezentationsList.authorLabel', {
                  authorName:
                    currentUser.user.id === prezData.ownerID
                      ? $t('build.step1.me')
                      : prezData.author || prezData.ownerName,
                })
              }}
            </div>
          </template>
        </div>
        <div class="permissionAndComments">
          <div class="permission">
            <PrezentationShareStatus
              :prezentation="prezData"
              :isEditable="isEditable"
              :prezentationUpdatedFn="handleShareSettingsUpdate"
              :origin="
                prezentation.prezentationSource === 'User Uploaded'
                  ? 'upload-prezentation'
                  : `${origin}-${matomo_thumbnail}-button`
              "
              :source="`prezentationcard`"
              :current-user="currentUser"
            />
          </div>
          <div
            :class="
              !leftSideBarStatus && advancedFilterActive === true
                ? 'commentsWithSideBarAndAdvFilterActive'
                : 'comments'
            "
            @click="onClick(prezData)"
            v-if="
              !isPrezentSourceBestPractice && isPrezentationCommentFullAccess
            "
          >
            <v-tooltip top>
              <template #activator="{ on }">
                <div v-on="on" class="iconWrapper">
                  <v-badge
                    :content="prezData.countNewComments"
                    :value="prezData.countNewComments"
                    color="primary"
                    overlap
                    size="20"
                    :disabled="prezData.countNewComments <= 0"
                  >
                    <v-icon size="20"> mdi-comment-outline </v-icon>
                  </v-badge>
                </div>
              </template>
              <span>
                {{ getCommentsStr(prezData.countNewComments || 0) }}
              </span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </v-card>
    <v-dialog v-model="dialogDelete" max-width="550" class="deletePrezModal">
      <v-card class="modalCard">
        <v-card-text class="pt-4">
          <div class="text-h2 pa-12 remove-prezentation-title">
            {{ $t('prezentationsList.deleteWarning', { name: prezData.name }) }}
          </div>
          <div class="d-flex justify-end">
            <v-btn
              class="delete-btn-prezentation ma-2 py-2"
              outlined
              rounded
              color="#20a7e0"
              width="100"
              height="30"
              :loading="deleteInProgress"
              @click="deleteConfirm"
            >
              {{ $t('common.delete') }}
            </v-btn>
            <v-btn
              style="color: white !important"
              class="cancel-btn-prezentation ma-2 py-2"
              rounded
              color="#20a7e0"
              width="155"
              height="30"
              @click="dialogDelete = false"
            >
              {{ $t('common.cancel') }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--
    <OpRatingPopup
      :opRequest="opPrezentation"
      ref="ratingPopup"
      @update="onRatingUpdate"
    />
    !-->
  </div>
</template>
<script>
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { mapState, mapActions, mapGetters } from 'vuex';
import API from '../../../utils/api';
import PrezentationShareStatus from './PrezentationShareStatus.vue';
import {
  getPrezentationThumbnailUrl,
  getThumbnailsForCarousel,
} from '../../../store/modules/helper';
import utils from '../../utils';
import {
  capitalizeFirstLetter,
  // downloadFileWithCustomName,
  // downloadFileWithAPI,
} from '../../../utils/common';
import { PrezentationViewTypes } from '../../../utils/constants';
import Download from '../../common/Download.vue';
import {
  MatomoAnalyticsHandler,
  THUMBNAIL,
} from '../../common/Analytics/MatomoAnalyticsHandler';
import { trackPrezentationEvents } from '../../common/Analytics/PrezentationEvents';
import { miscConfig } from '../../../runtimeConfig';
// import usersApi from '../../../API/users-api';
import {
  addPrezentationAsFavorite,
  removePrezentationAsFavorite,
  addPrezentationAsDownloaded,
  updatePrezentationAsDownloaded,
  getSlideDetail,
} from '@/utils/api-helper';
import { callMergeSlidesApi } from '@/utils/merge-slide-helper';
import { trackSearchEvents } from '../../common/Analytics/SearchEvents';
import {
  TD_AUD,
  TD_DOWNLOADTYPE,
  TD_DOWNLOADTYPE_DIRECT,
  TD_DOWNLOADTYPE_GS,
  TD_HYBRIDCHIP,
  TD_HYBRID_RESULTS_SECTION_CLICK,
  TD_HYBRID_RESULTS_SECTION_PREZ,
  TD_HYBRID_SEARCH,
  TD_ISDOWNLOAD,
  TD_ISFAVORITED,
  TD_NUMBEROFSLIDES,
  TD_PREZENTATIONAUTHOR,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_QUERY,
  TD_PREZENTATION_CATEGORY,
  TD_PREZENTATION_ORIGIN,
  TD_PREZENTATION_COMPANY_SOURCE,
  TD_IDEABEGIN,
  TD_IDEAMIDDLE,
  TD_IDEAEND,
  TD_SECTION_4,
  TD_SECTION_5,
  TD_DOWNLOAD_AS_PDF,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_YES,
  TD_NO,
  TD_PLACEMENT,
  TD_THUMBNAIL_VIEW,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import SharePrezentation from './SharePrezentation.vue';
import UserDownloadPreferenceSelector from '../../common/UserDownloadPreferenceSelector.vue';
import { getPrezentationByID } from '../../../utils/api-helper';
// import RequestChangeModal from '../OvernightPrezentations/RequestChangeModal.vue';
// import OpRatingPopup from '../OvernightPrezentations/OpRatingPopup';

export default {
  name: 'PrezentationCard',
  components: {
    PrezentationShareStatus,
    Download,
    // OpRatingPopup,
  },
  props: {
    prezentationStatus: {
      type: String,
      default: undefined,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    prezentation: {
      type: Object,
      required: true,
    },
    toggleFn: {
      type: Function,
      default: undefined,
    },
    onCloneFn: {
      type: Function,
      default: undefined,
    },
    onDeleteFn: {
      type: Function,
      default: undefined,
    },
    onUpdateFn: {
      type: Function,
    },
    visibility: {
      type: String,
      default: undefined,
    },
    showAuthor: {
      type: Boolean,
      default: false,
    },
    origin: {
      type: String,
      required: false,
    },
    thumbnailAsset: {
      type: String,
      required: true,
    },
    advancedFilterActive: {
      type: Boolean,
      default: false,
    },
    index: Number,
    isSearchPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogDelete: false,
      isEditing: false,
      newName: '',
      thumbnailUrl: {
        type: String,
        default: null,
      },
      permissionLevel: {
        type: Number,
        default: null,
      },
      allowedViewTypes: [
        PrezentationViewTypes.PREZENTATIONS_TO_REVIEW,
        PrezentationViewTypes.TEAM_PREZENTATIONS,
      ],
      deleteInProgress: false,
      matomo_thumbnail: THUMBNAIL,
      prezData: {},
      isCardHovered: false,
      opPrezentation: {},
      dotActions: [
        {
          name: 'Rename',
          display_title: 'overnightPresentations.rename',
          icon: 'mdi-pencil-outline',
          tooltipRequired: true,
          tooltipContent: 'Edit Prezentation Name',
        },
        {
          name: 'Duplicate',
          display_title: 'common.duplicate',
          icon: 'mdi-content-copy',
          tooltipRequired: true,
          tooltipContent: 'Duplicate Prezentation',
        },
        {
          name: 'Copy link',
          display_title: 'userUploadPrezentation.copyLink',
          icon: 'mdi-link-variant',
          tooltipRequired: true,
          tooltipContent: 'Copy Prezentation Link',
        },
        {
          name: 'Share',
          display_title: 'common.share',
          icon: 'mdi-account-check-outline',
          tooltipRequired: true,
          tooltipContent: 'Share Prezentation Access',
        },
        {
          name: 'Delete',
          display_title: 'common.delete',
          icon: 'mdi-delete-outline',
          tooltipRequired: true,
          tooltipContent: 'Delete Prezentation',
        },
      ],
      menuPos: {
        x: 0,
        y: 0,
      },
      mergedUrl: '',
      // downloading: false,
      isChangingFavorite: false,
      carouselKey: 0,
      metaData: {},
      showCopied: false,
      closeEllipsisMenu: true,
      menuKey: 0,
      cardNameBeforeUpdate: '',
    };
  },
  mounted() {
    getPrezentationThumbnailUrl(this.prezentation, this.currentUser).then(
      (url) => {
        this.thumbnailUrl = url;
      },
    );
    // this.prezData = this.prezentation;
  },
  watch: {
    thumbnailAsset(newVal, oldVal) {
      if (newVal !== oldVal) {
        getPrezentationThumbnailUrl(this.prezentation, this.currentUser).then(
          (url) => {
            this.thumbnailUrl = url;
          },
        );
      }
    },
    prezentation: {
      async handler(val) {
        if (
          val.prezentationType &&
          val.prezentationType === 'OP' &&
          this.origin === 'hybrid_search'
        ) {
          const prez = await this.getOpPrezentationThumbnailsForHybridSearch(
            val,
          );
          this.prezData = prez;
        } else {
          this.prezData = val;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'isTrialExpired',
      'themes',
      'hybridSearchQueryPayload',
      'leftSideBarStatus',
    ]),
    ...mapGetters('commonDownloads', ['getDownloadLoaders']),

    isGoogleDownloadEnabled() {
      if (this.currentUser) {
        const feature = this.currentUser.features.find(
          (f) => f.feature_name === 'google_slides_download',
        );
        return feature && feature.enabled;
      }
      return false;
    },

    isLocalDownloading() {
      const ldr = this.getDownloadLoaders.find(
        (ld) =>
          ld.loaderId ===
          (this.isSearchPage ? this.prezData.prezentationId : this.prezData.id),
      );
      return ldr && ldr.downloading;
    },
    isDownloaded() {
      if (typeof this.prezData.isDownloaded === 'boolean') {
        return this.prezData.isDownloaded;
      }
      return this.prezData?.downloaded_by?.includes(
        this.currentUser.user.num_id,
      );
    },
    isFavorite() {
      if (typeof this.prezentation.isFavorite === 'boolean') {
        return this.prezentation.isFavorite;
      }
      return this.prezentation?.favourites_by?.includes(
        this.currentUser.user.num_id,
      );
    },
    ...mapGetters('users', ['isPrezentationCommentFullAccess']),

    isBestPractices() {
      return (
        this.prezentation.prezentationType === 'Best Practices' &&
        this.isIOCUpload
      );
    },
    showNewContentTag() {
      return this.isPrezentSourceBestPractice && this.showBadge;
    },
    isPrezentSourceBestPractice() {
      return (
        this.prezentation.prezentationType === 'Best Practices' &&
        this.prezentation?.source?.toLowerCase() === 'prezent' &&
        this.isIOCUpload
      );
    },
    isIOCUpload() {
      return this.prezentation.prezentationSource === 'IOCUpload';
    },
    sourceName() {
      if (
        this.prezentation.prezentationSource === 'OP' ||
        this.prezentation.prezentationSource === 'Product'
      ) {
        return this.currentUser.company.displayName;
      }
      return capitalizeFirstLetter(this.prezentation.source);
    },
    showBadge() {
      if (!this.currentUser.user.createdAt || !this.prezData.createdAt) {
        return false;
      }
      const userCreatedAt = moment(this.currentUser.user.createdAt);
      const prezDataCreatedAt = moment(this.prezData.createdAt);
      const currentDate = moment();

      return (
        currentDate.diff(prezDataCreatedAt, 'days') < 28 &&
        userCreatedAt.isBefore(prezDataCreatedAt)
      );
    },
  },
  methods: {
    ...mapActions('prezentations', ['updatePrezentationWithAction']),
    ...mapActions('users', ['setCurrentUser']),
    ...mapActions('search', ['updateRecentQueries']),
    ...mapActions('commonDownloads', [
      'setDownloadItems',
      'setShowDownloadSnackbar',
      'setDownloadLoaders',
      'setNavFromAddToLibOrFavSnackbar',
      'setAddedToLibOrFavSnackbar',
    ]),
    openSharingOptions(prezentation) {
      let origin = `${this.origin}-${this.matomo_thumbnail}-icon`;
      if (prezentation.prezentationSource === 'User Uploaded') {
        origin = 'upload-prezentation';
      }
      this.$modal.show(
        SharePrezentation,
        {
          prezentation,
          prezentationUpdatedFn: this.handleShareSettingsUpdate,
          origin,
          source: 'prezentationcard',
        },
        {
          name: 'SharePrezentation',
          width: 700,
          height: 'auto',
          minHeight: 300,
          styles: { borderRadius: '20px' },
        },
      );
    },
    async getOpPrezentationThumbnailsForHybridSearch(prez) {
      const prezentation = await getPrezentationByID(prez.prezentationId);
      return { ...prez, sections: prezentation.sections };
    },
    handleEditNameFocus() {
      this.cardNameBeforeUpdate = this.prezData.name;
    },
    handleEditNameBlur() {
      this.prezData.name = this.cardNameBeforeUpdate;
      this.isEditing = false;
    },

    goToFav() {
      this.setNavFromAddToLibOrFavSnackbar({
        navigated: true,
        type: 'addToFavPrez',
        origin: 'fav_prez',
      });
      this.$router.push('/home/prezentations').catch(() => {});
    },

    async handleAddPrezAsFav(prez) {
      this.isChangingFavorite = true;
      await addPrezentationAsFavorite({
        prezentationId:
          this.origin === 'hybrid_search' ? prez.prezentationId : prez.id,
      })
        .then((resp) => {
          console.log(resp);
          this.updatePrezentationWithAction({
            prezentation: prez,
            action: 'addFav',
            origin: this.origin,
            currentUser: this.currentUser,
          });

          this.setAddedToLibOrFavSnackbar({
            show: true,
            ctaText: 'snackBarItems.goToFavorites',
            snackbarText: 'snackBarItems.prezAddedToFav',
            navFn: this.goToFav,
            snackbarType: 'goToFav',
          });

          this.isChangingFavorite = false;
          trackPrezentationEvents.prezentationsFavourited(this.currentUser, {
            ...this.getMatomoOtherData(prez),
            [TD_ISFAVORITED]: 1,
          });
        })
        .catch((err) => {
          console.log(err);
          this.isChangingFavorite = false;
        });
    },
    async handleRemovePrezAsFav(prez) {
      this.isChangingFavorite = true;
      await removePrezentationAsFavorite(
        this.origin === 'hybrid_search' ? prez.prezentationId : prez.id,
      )
        .then((resp) => {
          console.log(resp);
          this.updatePrezentationWithAction({
            prezentation: prez,
            action: 'removeFav',
            origin: this.origin,
            currentUser: this.currentUser,
          });
          this.isChangingFavorite = false;
          trackPrezentationEvents.prezentationsFavourited(this.currentUser, {
            ...this.getMatomoOtherData(prez),
            [TD_ISFAVORITED]: 0,
          });
        })
        .catch((err) => {
          console.log(err);
          this.isChangingFavorite = false;
        });
    },
    async getMetaDataForSlides(slideIds) {
      const params = {
        assetIds: slideIds,
        getMeta: true,
      };
      await getSlideDetail(params)
        .then((res) => {
          this.metaData = res.data || {};
        })
        .catch((err) => console.log(err));
    },
    getSectionDeatils(value) {
      let details = '';
      if (this.prezentation?.sections?.list[value]?.sectionName) {
        details = `${this.prezentation?.sections?.list[value]?.sectionName}_`;
        this.prezentation?.sections?.list[value]?.slides.forEach((slide) => {
          details += slide.outline;
          details += '_';
        });
      }
      return details;
    },
    async logDownload(currentPrezentation, itemReplaced, fromDrive) {
      // TODO: Use these two params when matomo events for this type of log downloads are integrated
      trackPrezentationEvents.prezentationsThumbnailDownload(this.currentUser, {
        ...this.getMatomoOtherData(currentPrezentation),
        [TD_ISDOWNLOAD]: 1,
        [TD_DOWNLOADTYPE]: fromDrive
          ? TD_DOWNLOADTYPE_GS
          : TD_DOWNLOADTYPE_DIRECT,
        [TD_COMMON_COLUMN_NAME]: TD_DOWNLOAD_AS_PDF,
        [TD_COMMON_COLUMN_VALUE]:
          currentPrezentation.permission_level === 0 ? TD_YES : TD_NO,
      });

      const otherData = {
        [TD_COMMON_COLUMN_NAME]: TD_DOWNLOAD_AS_PDF,
        [TD_COMMON_COLUMN_VALUE]:
          currentPrezentation.permission_level === 0 ? TD_YES : TD_NO,
        [TD_ISDOWNLOAD]: 1,
        [TD_DOWNLOADTYPE]: fromDrive
          ? TD_DOWNLOADTYPE_GS
          : TD_DOWNLOADTYPE_DIRECT,
      };
      trackPrezentationEvents.prezentationsThumbnailClickDownload(
        this.currentUser,
        currentPrezentation,
        otherData,
      );

      const prezUniqueIds = [];
      currentPrezentation.sections.list.forEach((section) => {
        section.slides.forEach((slide) => {
          prezUniqueIds.push(slide.uniqueID);
        });
      });
      await this.getMetaDataForSlides(prezUniqueIds);
      if (
        currentPrezentation.isDownloaded &&
        currentPrezentation.isDownloaded === true
      ) {
        await updatePrezentationAsDownloaded({
          prezentationId: currentPrezentation.prezentationId
            ? currentPrezentation.prezentationId
            : currentPrezentation.id,
        })
          .then((resp) => {
            console.log(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        await addPrezentationAsDownloaded({
          prezentationId: currentPrezentation.prezentationId
            ? currentPrezentation.prezentationId
            : currentPrezentation.id,
          downloadCount: 1,
        })
          .then((resp) => {
            console.log(resp);
            this.updatePrezentationWithAction({
              prezentation: currentPrezentation,
              action: 'downloadPrezentation',
              origin: this.origin,
              currentUser: this.currentUser,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    async handlePrezDownload() {
      // this.downloading = true;
      this.setShowDownloadSnackbar(true);
      this.setDownloadLoaders({
        loaderId: this.isSearchPage
          ? this.prezData.prezentationId
          : this.prezData.id,
        downloading: true,
      });
      this.setDownloadItems({
        status: 'assembling',
        itemIndex: uuidv4(),
        originType: 'prezLibrary',
        logDownloadCallback: () => {
          this.logDownload(this.prezData);
        },
        mergeSlidesCallback: (mergedslide) => {
          if (
            !this.prezentation.permission_level ||
            this.prezentation.permission_level === 3
          ) {
            const prezentationData = {
              id: this.isSearchPage
                ? this.prezData.prezentationId
                : this.prezData.id,
              mergedSlides: mergedslide,
            };
            this.updatePrezentation(prezentationData)
              .then((value) => {
                this.prezData = {
                  ...value.prezentationData,
                  prezentationId: value.prezentationData.id,
                  id: value.prezentationData.id,
                  isDownloaded: !this.isSearchPage
                    ? this.prezData?.isDownloaded
                    : this.prezData?.downloaded_by?.includes(
                        this.currentUser.user.num_id,
                      ),
                };
              })
              .catch((err) => {
                console.log(err);
              });
          }
        },
        downloadStatusCallback: () => {
          this.setDownloadLoaders({
            loaderId: this.isSearchPage
              ? this.prezData.prezentationId
              : this.prezData.id,
            downloading: false,
          });
        },
        metaData: {
          ...this.prezData,
          fileName: this.prezData.name,
          // fileName: this.isBestPractices
          //   ? `${this.prezData.name}`
          //   : `${this.prezData.name}_${this.prezData.type}`,
          limitsKey: 'prezentation',
          origin: 'prezentation',
        },
      });
      // const s3url = await this.getMergedUrl(true);
      // console.log('************', this.prezData, s3url);
      // const fileName = this.isBestPractices
      //   ? `${this.prezData.name}`
      //   : `${this.prezData.name}_${this.prezData.type}`;
      // // downloadFileWithCustomName(s3url, fileName, this.downloadInProgress);
      // await downloadFileWithAPI(
      //   'prezentation',
      //   s3url,
      //   `${fileName}${
      //     this.prezData.isRestricted || this.prezData.permission_level === 0
      //       ? '.pdf'
      //       : '.pptx'
      //   }`,
      // );
      // this.downloading = false;
      // this.logDownload(this.prezData);
    },
    // downloadInProgress(flag) {
    //   this.downloading = flag;
    // },
    async getMergedUrl(onlyPath = false) {
      let s = {
        sections: this.prezData.sections,
      };
      if (this.prezData.isRestricted || this.prezData.permission_level === 0) {
        s = {
          ...s,
          outputFormat: 'pdf',
        };
      }
      await callMergeSlidesApi(s)
        .then((res) => {
          const mergedslide = res.data;
          console.log(mergedslide);
          if (mergedslide.includes('.pptx')) {
            if (
              !this.prezentation.permission_level ||
              this.prezentation.permission_level === 3
            ) {
              const prezentationData = {
                id: this.isSearchPage
                  ? this.prezData.prezentationId
                  : this.prezData.id,
                mergedSlides: mergedslide,
              };
              this.updatePrezentation(prezentationData)
                .then((value) => {
                  this.prezData = {
                    ...value.prezentationData,
                    prezentationId: value.prezentationData.id,
                    id: value.prezentationData.id,
                    isDownloaded: !this.isSearchPage
                      ? this.prezData?.isDownloaded
                      : this.prezData?.downloaded_by?.includes(
                          this.currentUser.user.num_id,
                        ),
                  };
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }

          if (onlyPath) this.mergedUrl = `${mergedslide}`;
          else
            this.mergedUrl = `${miscConfig.aws_prezentation_distribution}/${mergedslide}`;
        })
        .catch((err) => {
          console.log(err);
        });
      return this.mergedUrl;
    },
    getFilename() {
      return this.prezData.name;
    },
    getDownloadFilename() {
      let fileName =
        this.isBestPractices || !this.prezData.type
          ? `${this.prezData.name}`
          : `${this.prezData.name}_${this.prezData.type}`;

      fileName = `${fileName}${
        this.prezData.isRestricted || this.prezData.permission_level === 0
          ? '.pdf'
          : '.pptx'
      }`;
      return fileName;
    },
    isPrezentationOwner() {
      return (
        this.prezData.ownerID &&
        this.currentUser &&
        this.prezData.ownerID.toLowerCase() ===
          this.currentUser.user.id.toLowerCase()
      );
    },
    getActionsWithPermissions(prezType, level) {
      let actions = [];
      if (
        this.prezentation.prezentationType === 'OP' &&
        !this.isPrezentationOwner()
      ) {
        if (level === 0 || level === 2 || level === 1) {
          actions = [...this.dotActions].filter(
            (act) => act.name === 'Copy link',
          );

          // return actions;
        }
        actions = [...this.dotActions].filter(
          (act) => act.name !== 'Duplicate',
        );
        // return actions;
      }
      if (this.isPrezentationOwner()) {
        actions = [...this.dotActions];
      } else {
        switch (level) {
          case 1:
          case 2:
            actions = [
              ...this.dotActions.filter(
                (act) =>
                  (act.name === 'Duplicate' && !this.isBestPractices) ||
                  act.name === 'Copy link',
              ),
            ];
            break;

          case 0:
            actions = [
              ...this.dotActions.filter((act) => act.name === 'Copy link'),
            ];
            break;
          case 3:
            actions = [
              ...this.dotActions.filter(
                (act) =>
                  act.name === 'Duplicate' ||
                  act.name === 'Rename' ||
                  act.name === 'Copy link',
              ),
            ];
            break;

          default:
            break;
        }
      }

      // adding download settings option
      if (this.isGoogleDownloadEnabled) {
        actions.push({
          name: 'Download settings',
          display_title: 'common.downloadSettingsText',
          icon: 'mdi-cog-outline',
          tooltipRequired: true,
          tooltipContent: 'Download settings',
        });
      }

      return actions;
    },

    openUserDownloadPreferenceModal() {
      this.$modal.show(
        UserDownloadPreferenceSelector,
        {
          compType: 'modal',
        },
        {
          name: 'UserDownloadPreferenceSelector',
          width: '500px',
          height: 'auto',
          styles: {
            borderRadius: '12px',
          },
          // clickToClose: false,
        },
      );
    },

    getCommentsStr(comments) {
      let str = '';
      if (comments <= 0) {
        str = this.$t('prezentation.noNewComments');
      } else if (comments === 1) {
        str = this.$t('prezentation.oneNewComment');
      } else {
        str = this.$t('prezentation.newComments', { count: comments });
      }
      return str;
    },
    handleDotOptionClick(type) {
      switch (type) {
        case 'Delete':
          this.showDeleteConfirmation();
          break;

        case 'Rename':
          this.cardNameBeforeUpdate = this.prezData.name;
          this.isEditing = !this.isEditing;
          break;

        case 'Duplicate':
          this.onCloneFn(this.prezData);
          break;

        case 'Copy link':
          this.copyPrezentationLink(this.prezData);
          break;

        case 'Share':
          this.openSharingOptions(this.prezData);
          break;

        // case 'Rate Prezentation':
        //   this.openRatingPopup(this.prezData);
        //   break;

        // case 'Change Request':
        //   this.handleChangeRequest();
        //   break;

        // case 'Conversation History':
        //   this.gotoHistory(this.prezData);
        //   break;

        case 'Download settings':
          this.openUserDownloadPreferenceModal();
          break;

        default:
          break;
      }
    },
    copyPrezentationLink(data) {
      this.closeEllipsisMenu = false;
      navigator.clipboard.writeText(
        `${window.location.origin}/home/myPrezentation/${data.id}`,
      );
      this.showCopied = true;
      setTimeout(() => {
        this.showCopied = false;
        this.closeEllipsisMenu = true;
        this.menuKey++;
      }, 1000);
      trackPrezentationEvents.prezentationsElipsisCopylink(this.currentUser, {
        ...this.getMatomoOtherData(this.prezData),
        [TD_PLACEMENT]: TD_THUMBNAIL_VIEW,
      });
    },
    // handleChangeRequest() {
    //   let prezSelected = [];
    //   prezSelected = this.opItems.find(
    //     (item) => item.name === this.prezentation?.name,
    //   );
    //   this.$modal.show(
    //     RequestChangeModal,
    //     {
    //       opRequest: prezSelected,
    //       reqOrigin: 'myRequests',
    //     },
    //     {
    //       name: 'RequestChangeModal',
    //       width: '650px',
    //       height: 'auto',
    //       styles: {
    //         'max-height': '75%',
    //         'max-width': '800px',
    //         overflow: 'auto',
    //       },
    //       clickToClose: true,
    //     },
    //   );
    // },
    // gotoHistory(data) {
    //   let prezSelected = [];
    //   prezSelected = this.opItems.find((item) => item.name === data.name);
    //   this.$router.push({
    //     path: `/home/my-overnight-prezentation/${prezSelected?.ticketId}?origin=overnight`,
    //   });
    // },
    // openRatingPopup(data) {
    //   let prezSelected = [];
    //   prezSelected = this.opItems.find((item) => item.name === data.name);
    //   this.opPrezentation = prezSelected;
    //   this.$refs.ratingPopup.open();
    // },
    // onRatingUpdate(payload) {
    //   this.$emit('onRatingUpdate', payload);
    // },
    handleThreeDotsClick(e) {
      this.menuPos.x = e.clientX - 110;
      this.menuPos.y = e.clientY;
    },
    getDateWithMoment(date) {
      return moment(date).format('MM/DD/YYYY');
    },
    handleCardLeave() {
      this.isCardHovered = false;
    },
    onCarouselImageLoad(imageNumber) {
      /** Log Matomo event on Prezentation card hover when the second image starts to load */
      if (imageNumber === 1) {
        try {
          trackPrezentationEvents.prezentationsThumbnailHover(
            this.currentUser,
            this.getMatomoOtherData(this.prezData),
          );
        } catch (e) {
          console.log(e);
        }
      }
    },
    handleGetCarouselThumbnails() {
      this.isCardHovered = true;
      this.carouselKey++;
      if (this.prezData && !this.prezData.prezentationCarouselsThumbnails) {
        getThumbnailsForCarousel(this.prezData, this.currentUser).then(
          (resp) => {
            this.prezData = {
              ...this.prezData,
              prezentationCarouselsThumbnails: resp,
            };
          },
        );
      }
    },
    getMatomoOtherData(prezData) {
      try {
        const {
          audienceID,
          author,
          name,
          type,
          sections,
          prezentationType,
          prezentationSource,
          source,
        } = prezData;

        let slidesLength = 0;
        sections.list.forEach((section) => {
          slidesLength += section.slides.length;
        });
        return {
          [TD_AUD]: audienceID,
          [TD_PREZENTATIONAUTHOR]: author,
          [TD_PREZNAME]: name,
          [TD_PREZTYPE]: type,
          [TD_NUMBEROFSLIDES]: slidesLength,
          [TD_PREZENTATION_CATEGORY]: prezentationType,
          [TD_PREZENTATION_ORIGIN]: prezentationSource,
          [TD_PREZENTATION_COMPANY_SOURCE]: source,
          [TD_IDEABEGIN]: this.getSectionDeatils(0),
          [TD_IDEAMIDDLE]: this.getSectionDeatils(1),
          [TD_IDEAEND]: this.getSectionDeatils(2),
          [TD_SECTION_4]: this.getSectionDeatils(3),
          [TD_SECTION_5]: this.getSectionDeatils(4),
        };
      } catch (e) {
        return {};
      }
    },
    handleShareSettingsUpdate(isPrivate) {
      this.updatePrezentationWithAction({
        prezentation: {
          ...this.prezData,
          shared: !isPrivate,
          origin: this.origin,
        },
        action: 'share',
      });
      // this.prezData.shared = !isPrivate;
    },
    handleSubmit() {
      const prezentationName = this.prezData.name.trim();
      if (!prezentationName) {
        this.isEditing = false;
        return false;
      }
      this.onUpdateFn(prezentationName, this.prezData);
      this.isEditing = false;
      return true;
    },
    async onClick(prezentation, editable) {
      if (this.origin && this.origin === TD_HYBRID_SEARCH) {
        const { selectedChip, query } = this.hybridSearchQueryPayload;
        trackSearchEvents.hybridSearchPrezentationsClick(
          this.currentUser,
          prezentation,
          {
            [TD_HYBRID_RESULTS_SECTION_CLICK]: TD_HYBRID_RESULTS_SECTION_PREZ,
            [TD_HYBRIDCHIP]: selectedChip,
            [TD_QUERY]: query,
          },
        );
        const payload = {
          type: 'Prezentations',
          value: prezentation.name,
          subvalue: prezentation.name,
          attributes: `name: 'myPrezentation.detail',
        params: {
          id:
            this.origin && this.origin === TD_HYBRID_SEARCH
              ? prezentation.prezentationId
              : prezentation.id,
          currentItemIsEditable:
            editable || prezentation.permission_level === 3,
          origin: this.origin,
        }`,
          object: {
            ...prezentation,
            id: prezentation.prezentationId
              ? prezentation.prezentationId
              : prezentation.id,
          },
        };
        this.updateRecentQueries(payload);
      } else {
        trackPrezentationEvents.prezentationsThumbnailClick(
          this.currentUser,
          prezentation,
        );
      }
      this.$router.push({
        name: 'myPrezentation.detail',
        params: {
          id: prezentation.prezentationId
            ? prezentation.prezentationId
            : prezentation.id,
          currentItemIsEditable:
            editable || prezentation.permission_level === 3,
          origin: this.origin,
        },
      });
      this.$emit('select', prezentation);
    },
    timeSince: utils.timeSince,
    showDeleteConfirmation() {
      const prezDataForMatomo = {
        accessType: this.prezData.shared ? 'Shared' : 'Private',
        ...this.prezData,
      };
      MatomoAnalyticsHandler.trackPrezDeleteIconClick(
        this.currentUser.user,
        prezDataForMatomo,
      );
      this.dialogDelete = true;
    },
    async deleteConfirm() {
      this.deleteInProgress = true;
      await this.deletePrezentation(this.prezData.id).then(() => {
        this.onDeleteFn(this.prezData);
        this.dialogDelete = false;
        this.deleteInProgress = false;
      });
    },
    async deletePrezentation(toDeleteId) {
      const path = `/prezentation/${toDeleteId}`;
      return API.delete(path);
    },
    async updatePrezentation(prezentationData) {
      const path = '/prezentation';
      return API.put(path, prezentationData);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';

.prezentation__container {
  padding: 8px;
  cursor: pointer;
  .card-wrapper {
    min-width: 200px;
    transition: all ease 0.5s;
    .badge-container {
      display: flex;
      top: 40px;
      left: 14px;
      position: absolute;
      z-index: 4;

      ::v-deep .v-badge__badge {
        background-image: $new-tag-bg;
        border-radius: 6px;
        font-size: 10px;
        font-weight: 600;
        height: fit-content;
        padding: 6px 16px;
        width: fit-content;
        z-index: 1;
      }
    }
    .carouselOrImgWrapper {
      .icons-wrapper {
        display: flex;
        position: absolute;
        right: 12px;
        top: 12px;
        z-index: 1;
      }
      .v-window {
        display: none;
      }
      .image-wrapper {
        height: 220px !important;
        padding: 12px;
        border-radius: 8px;
        cursor: pointer;
        overflow: hidden;
        position: relative;
      }
      &:hover {
        .icons-wrapper {
          display: flex;
          position: absolute;
          right: 12px;
          top: 12px;
          z-index: 1;
        }
        .v-window {
          display: block;
          height: 220px !important;
          padding: 12px;
        }
        .image-wrapper {
          display: none;
        }
      }
    }
    .prezInfoAndActions {
      border-top: 0.5px solid rgb(235 236 235);
      padding: 8px 12px;
      .nameAndActions {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 16px;
        margin: 4px 0px;
        .name {
          width: 70%;
          display: flex;
          gap: 2px;
          align-items: center;

          span {
            display: inline-block;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .card-edit-name {
          align-items: center;
          // display: flex;
          cursor: pointer;
          padding-left: 5px;
          padding-right: 5px;
          padding-top: 5px;
          .errorText {
            font-size: 10px;
            text-align: left;
            color: red;
            margin: -11px 0px 0px 13px;
          }
          ::v-deep .v-input {
            .v-input__control {
              min-height: 20px !important;
              .v-input__slot {
                .v-text-field__slot {
                  input {
                    font-size: 16px;
                    padding: 0px !important;
                    font-family: 'Lato', sans-serif;
                    font-weight: 500;
                    letter-spacing: 0.0125em;
                    word-break: break-all;
                  }
                }
              }
            }
          }
          .v-btn {
            height: 21px !important;
            margin: 0px 0px 0px 10px;
          }
        }
        .actions {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .v-icon {
            width: 25px;
            height: 25px;
            &:hover {
              background-color: #21a7e01c;
              border-radius: 20px;
            }
            &:after {
              background-color: transparent;
            }
          }
        }
      }
      .typeAndDate {
        color: rgb(130 131 130);
        font-size: 12px;
        display: flex;
        height: 16px;
        margin: 2px 0px;
        .type {
          text-align: left;
          max-width: 47%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .date {
          text-align: left;
          max-width: 47%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .authorAndAudience {
        color: rgb(130 131 130);
        font-size: 12px;
        display: flex;
        margin: 2px 0px;
        height: 16px;
        .author {
          text-align: left;
          max-width: 50%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .audience {
          text-align: left;
          max-width: 50%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .permissionAndComments {
        display: flex;
        margin: 4px 0px;
        justify-content: space-between;
        .comments {
          margin: 0px 3px 0px 0px;
        }
        .commentsWithSideBarAndAdvFilterActive {
          margin: 0;
        }
        .comments,
        .commentsWithSideBarAndAdvFilterActive {
          .iconWrapper {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    &:hover {
      box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.5);
    }
  }
}
.v-menu__content {
  .v-list {
    .v-list-item {
      cursor: pointer;
      .v-list-item__title {
        .copyLink {
          .copyIconAndTitle {
            display: flex;
            .actionName {
              padding: 2px 4px 0px 4px;
            }
          }
          .copiedStatus {
            width: 60%;
            margin: 0 auto;
            font-size: 11px;
            display: flex;
            .v-icon {
              font-size: 12px !important;
            }
          }
        }
        .otherActions {
          display: flex;
          .actionName {
            padding: 2px 4px 0px 4px;
          }
        }
      }
      &:hover {
        background-color: #d8d8d8;
      }
    }
  }
}
.modalCard {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 #68788c;
  height: 170px !important;
  width: 559px;
  .delete-btn-prezentation {
    border-radius: 25px;
    border: solid 2px #21a7e0;
    font-family: 'Roboto', sans-serif !important;
    font-size: 18px;
    height: 36px !important;
    margin: 0 30px 0 0 !important;
    padding: 11px 42px 11px 43px !important;
    text-transform: unset !important;
    letter-spacing: normal;
  }
  .cancel-btn-prezentation {
    border-radius: 25px;
    border: solid 2px #21a7e0;
    font-family: 'Roboto', sans-serif !important;
    font-size: 18px;
    height: 36px !important;
    margin: 0 30px 0 0 !important;
    padding: 11px 42px 11px 43px !important;
    text-transform: unset !important;
    max-width: 64px;
    padding-right: 48px !important;
    padding-left: 48px !important;
    letter-spacing: normal;
  }
  .remove-prezentation-title {
    color: #000;
    font-family: 'Roboto', sans-serif !important;
    font-size: 20px !important;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal !important;
    line-height: normal;
    padding-bottom: 15px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-top: 20px !important;
  }
}
</style>
