import { render, staticRenderFns } from "./ComplyDeckSuggestions.vue?vue&type=template&id=ffdab00a&scoped=true&"
import script from "./ComplyDeckSuggestions.vue?vue&type=script&lang=js&"
export * from "./ComplyDeckSuggestions.vue?vue&type=script&lang=js&"
import style0 from "./ComplyDeckSuggestions.vue?vue&type=style&index=0&id=ffdab00a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffdab00a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAvatar,VBtn,VCol,VIcon,VImg,VList,VListItem,VListItemAction,VListItemContent,VProgressLinear,VRow,VTooltip})
