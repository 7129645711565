<template>
  <div v-if="!isLoading && !isSlidesLimitCrosses" class="convert-template">
    <div v-if="convertFail" class="error-container">
      <img src="/assets/img/compliance-check-failed.svg" alt />
      <br /><br />
      <div class="error-text">
        Oops! Compliance check failed. Please try again
      </div>
      <div class="try-again-btn">
        <v-btn
          rounded
          color="primary"
          class="app-button"
          @click="handleTryAgain"
        >
          Try again
        </v-btn>
      </div>
    </div>
    <template v-else>
      <template-selection
        v-if="step === 1"
        :templatesInfo="templatesInfo"
        :selectedTemplate="
          selectedTemplate && selectedTemplate.template_internal_name
        "
        @selectedTemplate="handleChangeTemplate"
        title="Convert template"
      ></template-selection>
      <TemplateConvertSuggestions
        :updatePrezentation="updatePrezentation"
        :selectedTemplate="selectedTemplate"
        :uploadId="uploadId"
        :changeStep="changeStep"
        :origin="origin"
        v-if="step === 2"
      />
    </template>
  </div>
  <div v-else class="convert-template">
    <div class="heading-block">
      <div class="heading-block__main">Convert template</div>
      <div class="heading-block__sub">Powered by ASTRID™ AI</div>
      <p
        class="suggest-block__summary-text"
        :style="{
          marginTop: '10px',
        }"
        v-if="!isSlidesLimitCrosses"
      >
        Select the template for your prezentation
      </p>
    </div>
    <div v-if="!isSlidesLimitCrosses" class="loader-container">
      <clip-loader color="#21a7e0" :width="20" :height="20" />
    </div>
    <div
      class="suggest-block__summary-text"
      :style="{
        marginTop: '80px',
      }"
      v-else
    >
      <img src="/assets/img/compliance-check-failed.svg" alt />
      <br /><br />
      <div class="error-container">
        <div class="error-text">
          Template conversion is optimized for decks with up to 30 slides.
          Please consider removing some slides from your presentation and try
          again.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import TemplateConvertSuggestions from './TemplateConvertSuggestions.vue';
import {
  fetchSlidesInfo,
  initialComplianceCheck,
  retryLimitExceededTC,
  uploadAsyncDeck,
} from '../../../ComplianceChecker/utils/comply-helper.utils';
import TemplateSelection from '../../../ComplianceChecker/comply-settings/TemplateSelection.vue';
import { trackPrezentationEvents } from '../../../../common/Analytics/PrezentationEvents';
import {
  MY_PREZENTATION,
  TD_AUD,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_TEMPLATE,
  TD_NUMBEROFSLIDES,
  TD_PREZENTATION_ORIGIN,
  TD_PREZENTATION_COMPANY_SOURCE,
  TD_PREZENTATION_LIBRARY,
  TD_CROSS_FEATURE,
  TD_TEMPLATE_CHANGED,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  BUILD,
} from '../../../../common/Analytics/MatomoTrackingDataHelper';
import { getS3Bucket } from '../../../../../utils/common';
import {
  fetchRecomendedTemplateImages,
  fetchSlidesListByUploadId,
} from '../../../../../utils/api-helper';
import { slideLimit } from '../../config/actionConfig';

export default {
  components: {
    TemplateSelection,
    TemplateConvertSuggestions,
    ClipLoader,
  },
  props: {
    updatePrezentation: {
      type: Function,
      default: () => {},
    },
    origin: {
      type: String,
      required: false,
      default: MY_PREZENTATION,
    },
  },
  data() {
    return {
      isLoading: true,
      step: 1,
      uploadId: uuidv4(),
      templatesInfo: {
        recommended_templates: [],
      },
      selectedTemplate: null,
      signal: null,
      convertFail: false,
    };
  },
  computed: {
    ...mapState('prezentationDetails', ['prezentationData']),
    ...mapState('users', ['currentUser']),
    ...mapGetters('users', ['isReplaceBrandImageEnabled']),
    ...mapGetters('prezentationDetails', ['isPrezentSourceBestPractice']),
    isSlidesLimitCrosses() {
      return this.prezentationData.totalSlides > slideLimit.convertTemplate;
    },
  },
  watch: {
    isSlidesLimitCrosses(val) {
      if (!val) {
        this.resetComplyData();
        this.getUploadAsyncCall();
      }
    },
  },
  beforeDestroy() {
    this.signal?.abort();
  },
  beforeMount() {
    if (!this.isSlidesLimitCrosses) {
      this.resetComplyData();
      this.getUploadAsyncCall();
    }
  },
  methods: {
    ...mapActions('prezentationDetails', [
      'setLoadingAnimations',
      'setComplyDetails',
      'setSelectTemplate',
      'resetComplyData',
      'updateComplyUploadId',
      'updateComplySlideImages',
    ]),
    getUploadAsyncCall() {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { filename, isOPUploaded } =
        // eslint-disable-next-line no-unsafe-optional-chaining
        this.prezentationData?.sections?.list[0].slides[0];
      this.selectedTemplate = {
        template_internal_name: this.prezentationData.theme,
      };
      let slideCount = 0;
      this.prezentationData.sections.list?.forEach((section) => {
        section.slides.forEach(() => {
          slideCount++;
        });
      });
      const name = filename.split('/').reverse()[0];
      const payload = {
        s3: getS3Bucket(filename, isOPUploaded),
        filepath: filename
          .split('/')
          .filter((t) => t)
          .join('/'),
        filename: name,
        uploadId: this.uploadId,
        origin: this.isPrezentSourceBestPractice
          ? 'bpe-presentation'
          : 'presentation',
        noOfSlidesDeck: slideCount,
      };
      uploadAsyncDeck(payload)
        .then(async (resp) => {
          console.log(resp);
          this.getTemplatesList();
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    async getTemplatesList() {
      const attempts = 150;
      let attemptCount = 0;
      let templateResponce = null;
      const interval = setInterval(() => {
        fetchSlidesListByUploadId(this.uploadId)
          .then(async (resp) => {
            console.log(resp);

            if (
              Object.keys(resp.outputs).length > 0 &&
              resp.outputs['Recommended-Templates'] &&
              resp.outputs['Recommended-Templates'].status === 'success'
            ) {
              // eslint-disable-next-line prefer-destructuring
              templateResponce = resp.outputs['Recommended-Templates'].data[0];
              const templatesWithImages = await fetchRecomendedTemplateImages(
                templateResponce,
                [],
                'comply',
              );
              this.templatesInfo = templatesWithImages || {
                recommended_templates: [],
                other_templates: [],
              };
              this.isLoading = false;
              clearInterval(interval);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            clearInterval(interval);
            console.log(error);
          });
        if (attemptCount >= attempts) {
          this.isLoading = false;
          clearInterval(interval);
        }
        attemptCount++;
      }, 3000);
    },
    changeStep(step = 1) {
      this.step = step;
      this.convertFail = false;
      this.uploadId = uuidv4();
      this.resetComplyData();

      this.getUploadAsyncCall();
    },
    handleTryAgain() {
      this.changeStep();
    },

    getSlideImages() {
      const slideImages = {};
      let slideCount = 0;
      this.prezentationData.sections.list?.forEach((section) => {
        section.slides.forEach((slide) => {
          slideImages[slideCount] = {
            png: {
              s3_path: slide.assetId
                .split('/')
                .filter((t) => t)
                .join('/'),
              s3_bucket: getS3Bucket(slide.filename, slide.isOPUploaded),
            },
            pptx: {
              s3_path: slide.filename
                .split('/')
                .filter((t) => t)
                .join('/'),
              s3_bucket: getS3Bucket(slide.filename, slide.isOPUploaded),
            },
          };
          slideCount++;
        });
      });

      return slideImages;
    },

    getS3dir() {
      if (this.isPrezentSourceBestPractice) {
        return `protected/premium-feature/tc/${this.prezentationData.id}`;
      }
      return this.prezentationData.s3dirpath
        ? (this.prezentationData.s3dirpath.indexOf('private/') === -1
            ? `private/${this.prezentationData.s3dirpath}`
            : this.prezentationData.s3dirpath
          )
            .split('/')
            .filter((t) => t)
            .join('/')
        : `private/prezentation/${this.prezentationData.id}`;
    },
    async handleChangeTemplate(template) {
      let countOfSlides = 0;
      this.prezentationData.sections.list.forEach((section) => {
        countOfSlides += section.slides.length;
      });
      const otherData = {
        [TD_AUD]: this.prezentationData?.audienceID,
        [TD_PREZNAME]: this.prezentationData.name,
        [TD_PREZTYPE]: this.prezentationData?.type,
        [TD_TEMPLATE]: this.prezentationData?.theme,
        [TD_NUMBEROFSLIDES]: countOfSlides,
        [TD_PREZENTATION_ORIGIN]:
          this.prezentationData?.prezentationSource ||
          this.prezentationData?.prezentationType,
        [TD_PREZENTATION_COMPANY_SOURCE]: this.prezentationData?.source,
        [TD_CROSS_FEATURE]:
          this.origin === 'build' ? BUILD : TD_PREZENTATION_LIBRARY,
        [TD_COMMON_COLUMN_NAME]: TD_TEMPLATE_CHANGED,
        [TD_COMMON_COLUMN_VALUE]: template?.template_internal_name,
      };
      trackPrezentationEvents.convertTemplateTemplateSelectClick(
        this.currentUser,
        otherData,
      );
      this.selectedTemplate = template;
      this.convertFail = false;
      this.signal = new AbortController();
      const s3dirpath =
        this.prezentationData?.s3dirpath ||
        `prezentation/${this.prezentationData?.id}/`;
      await this.updatePrezentation({
        ...this.prezentationData,
        s3dirpath,
      });
      this.setLoadingAnimations({
        isLoading: true,
        animationsData: {
          showBlast: false,
          type: 'complyCheckLoader',
        },
      });
      try {
        const payload = {
          slide_images: this.getSlideImages(),
          reference_template: this.selectedTemplate?.template_internal_name,
          upload_id: this.uploadId,
          source: '',
          include_image_conversions: this.isReplaceBrandImageEnabled || false,
          s3Dir: this.getS3dir(),
        };
        try {
          const response = await initialComplianceCheck(payload);
          console.log(response);

          const slidesResponse = await fetchSlidesInfo(
            this.uploadId,
            this.signal.signal,
          );
          console.log(
            'fetchSlidesInfo response ==>',
            slidesResponse?.slide_images,
          );

          if (slidesResponse?.status === 'error') {
            this.convertFail = true;
          }
          this.updateComplyUploadId(this.uploadId);
          this.updateComplySlideImages(slidesResponse?.slide_images);
          this.setSelectTemplate(template);
          this.setComplyDetails({ ...slidesResponse, step: 1 });
          this.setLoadingAnimations({
            isLoading: true,
            animationsData: {
              showBlast: true,
              type: 'complyCheckLoader',
            },
          });
          setTimeout(() => {
            this.setLoadingAnimations({
              isLoading: false,
              animationsData: {},
            });

            this.step = 2;
          }, 3000);
        } catch (error) {
          if (error.message === 'Polling timed out') {
            await retryLimitExceededTC(this.uploadId);
          }
          console.log('error while passing initial compliance', error);
          this.convertFail = true;
          this.setSelectTemplate(null);
          this.setLoadingAnimations({
            isLoading: true,
            animationsData: {
              showBlast: true,
              type: 'complyCheckLoader',
            },
          });
          setTimeout(() => {
            this.setLoadingAnimations({
              isLoading: false,
              animationsData: {},
            });
          }, 3000);

          throw error;
        }
      } catch (error) {
        console.log(error);
        this.convertFail = true;
        this.setLoadingAnimations({
          isLoading: false,
          animationsData: {},
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.convert-template {
  gap: 12px;
  display: flex;
  flex-direction: column;

  .heading-block {
    &__main {
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &__sub {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }

  .suggest-block {
    align-self: stretch;
    &__summary-text {
      color: #212121;
      font-size: 16px;
      font-weight: 400;
      line-height: 130%; /* 20.8px */
      margin-bottom: 0;
    }
  }

  .loader-container {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .error-container {
    text-align: center;

    .error-text {
      margin: 20px auto;
      font-size: 14px;
    }
    .try-again-btn {
      text-align: center;

      .v-btn {
        font-size: 18px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-transform: none;
      }
    }
  }
}
</style>
