<template>
  <div class="slides-list-view">
    <div
      class="left-side-items-outer"
      :style="{ height: heightOfThumbnail + 'px' }"
    >
      <div class="detail-panel-wrapper">
        <v-btn class="detail-expand-btn" small @click="handleSlideListToggle">
          <v-icon dark>
            {{ isSlideListExpand ? 'mdi-chevron-left' : 'mdi-chevron-right' }}
          </v-icon>
        </v-btn>
      </div>
      <div
        class="left-side-items"
        data-pendo-id="prezentation-container"
        :style="{
          'max-width': isSlideListExpand ? '250px' : '175px',
          width: isSlideListExpand ? '250px' : '175px',
        }"
      >
        <div
          v-if="prezentationData.sections.list.length >= 5 && !isViewAccess"
          class="error-message"
        >
          {{ $t('build.step3.sectionsLimitText') }}
        </div>
        <SectionHeader
          :defaultText="
            isSlideListExpand
              ? $t('build.step3.newSection')
              : $t('build.step3.section')
          "
          :currentPrezentation="prezentationData"
          :onNameChange="(newValue) => handleAddSection(newValue)"
          :isAddNewSection="true"
          :origin="origin"
          v-if="
            !isViewAccess &&
            !isTemplateRemoved(themes) &&
            prezentationData.sections.list.length < 5 &&
            !isOpPrezentation &&
            !isPartialAccess(currentUser) &&
            !isTCUnderProcess
          "
        />
        <div
          v-if="prezentationData.sections.list.length"
          @click="handleExpandToggle"
          class="expand-all-btn"
        >
          <v-icon id="expand-collapse--auto">
            {{ !isExpanded ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
          </v-icon>
          {{
            !isExpanded
              ? $t('build.step3.expandAllText')
              : $t('build.step3.collapseAllText')
          }}
        </div>
        <v-expansion-panels
          v-model="expandedList"
          accordion
          flat
          multiple
          class="expansion-panels"
        >
          <draggable
            class="dragArea sections-drag expansion-panels v-item-group theme--light v-expansion-panels v-expansion-panels--accordion v-expansion-panels--flat"
            :list="prezentationData.sections.list"
            group="sections"
            v-bind="dragOptions"
            draggable=".expansion-panel"
            handle=".section-drag-icon"
            @start="hadleSectionDragStart"
            @end="hadleSectionDragEnd"
            @add="hadleSectionDragEnd"
          >
            <v-expansion-panel
              v-for="(section, sectionIndex) in prezentationData.sections.list"
              :key="`${section.sectionName}-${sectionIndex}`"
              class="expansion-panel"
            >
              <v-expansion-panel-header>
                <div
                  @click.stop
                  class="header-drag-icon"
                  v-if="
                    !isViewAccess &&
                    !isTemplateRemoved(themes) &&
                    !isOpPrezentation &&
                    !isTCUnderProcess
                  "
                  :class="`section-${sectionIndex}-dragarea--auto`"
                >
                  <SectionHeader
                    :data="section"
                    :onNameChange="
                      (newValue) => onSectionNameChange(sectionIndex, newValue)
                    "
                    :origin="origin"
                    :count="
                      section.slides.filter((i) => i.assetId).length.toString()
                    "
                    :onDeleteClick="
                      () => handleDeleteSection(sectionIndex, section)
                    "
                    :onEdit="(val) => (isInEditMode = val)"
                  />
                </div>
                <div class="section-header" v-else>
                  {{ section.sectionName }}
                  <v-badge
                    color="#FFE1B7"
                    class="badge"
                    :content="
                      section.slides.filter((i) => i.assetId).length.toString()
                    "
                    inline
                  ></v-badge>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-if="
                    section.slides.filter((i) => i.assetId).length === 0 &&
                    !isPartialAccess(currentUser)
                  "
                  :key="`divider-${sectionIndex}-0-top`"
                  class="slide-divider"
                >
                  <div
                    class="inner-divider"
                    v-if="
                      !isViewAccess &&
                      !isOpPrezentation &&
                      !isPartialAccess(currentUser) &&
                      !isTCUnderProcess
                    "
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="add-slides"
                          fab
                          dark
                          small
                          color="primary"
                          @click="
                            () =>
                              addSlidesInBetween(
                                sectionIndex,
                                0,
                                'Between Slides',
                              )
                          "
                        >
                          <v-icon dark> mdi-plus </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('build.step3.addSlideText') }}</span>
                    </v-tooltip>
                  </div>
                </div>
                <draggable
                  :class="`dragArea list-group sectionIndex-${sectionIndex}`"
                  :list="section.slides"
                  group="slides"
                  v-bind="dragOptions"
                  draggable=".dragable-slide"
                  handle=".drag-icon"
                  @start="hadleSlideDragStart"
                  @end="hadleSlideDragEnd"
                  @add="hadleSlideDragEnd"
                >
                  <div
                    class="dragable-slide"
                    v-for="(slide, slideIndex) in section.slides.filter(
                      (i) => i.assetId,
                    )"
                    :key="`${sectionIndex}-${slideIndex}`"
                  >
                    <div
                      v-if="slideIndex === 0"
                      :key="`divider-${sectionIndex}-${slideIndex}-top`"
                      class="slide-divider"
                    >
                      <div
                        class="inner-divider"
                        v-if="
                          !isViewAccess &&
                          !isTemplateRemoved(themes) &&
                          !isOpPrezentation &&
                          !isPartialAccess(currentUser) &&
                          !isTCUnderProcess
                        "
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              class="add-slides"
                              fab
                              dark
                              small
                              color="primary"
                              @click="
                                () =>
                                  addSlidesInBetween(
                                    sectionIndex,
                                    slideIndex,
                                    'Between Slides',
                                  )
                              "
                            >
                              <v-icon dark> mdi-plus </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('build.step3.addSlideText') }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <SlideListViewImage
                      :isSelected="
                        slideIndex === selectedSlideIndex &&
                        selectedSectionIndex === sectionIndex
                      "
                      :serialNumber="getSerialNumber(sectionIndex, slideIndex)"
                      :slideIndex="slideIndex"
                      :sectionIndex="sectionIndex"
                      :onClick="
                        () =>
                          handleLeftSideItemClick(
                            slide,
                            slideIndex,
                            sectionIndex,
                          )
                      "
                      :slide="slide"
                      :onDuplicate="
                        () =>
                          handleLeftSideDuplicate(
                            slide,
                            slideIndex,
                            sectionIndex,
                            'On Slide',
                          )
                      "
                      :onDelete="
                        () =>
                          handleLeftSideDelete(
                            slideIndex,
                            sectionIndex,
                            false,
                            'On Slide',
                          )
                      "
                      :isViewAccess="isViewAccess || isTCUnderProcess"
                      :isTemplateRemoved="isTemplateRemoved(themes)"
                      :isOpPrezentation="isOpPrezentation"
                    ></SlideListViewImage>
                    <div
                      :key="`divider-${sectionIndex}-${slideIndex}-bottom`"
                      class="slide-divider"
                      data-pendo-id="add-slide-option1"
                    >
                      <div
                        class="inner-divider"
                        v-if="
                          !isViewAccess &&
                          !isTemplateRemoved(themes) &&
                          !isOpPrezentation &&
                          !isPartialAccess(currentUser) &&
                          !isTCUnderProcess
                        "
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              class="add-slides"
                              fab
                              dark
                              small
                              color="primary"
                              @click="
                                () =>
                                  addSlidesInBetween(
                                    sectionIndex,
                                    slideIndex + 1,
                                    'Between slides',
                                  )
                              "
                            >
                              <v-icon dark> mdi-plus </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('build.step3.addSlideText') }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </div>
                </draggable>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </draggable>
        </v-expansion-panels>
      </div>
    </div>
    <div
      v-if="selectedSlideData && !loadingIllustrations && prezLoader.isIdle()"
      class="current-item-panel"
      :key="selectedSlideData.assetId"
    >
      <div
        class="marker-switch"
        v-if="selectedMainAction.id === 'convertTemplate' && selectedTemplate"
      >
        <v-switch
          v-model="showMarkers"
          label="Show markers"
          hide-details
          dense
          :disabled="isSuggestionsLoaded"
        ></v-switch>
      </div>
      <div
        class="image"
        data-pendo-id="prezentation-selected-image"
        ref="thumbnailImage"
        :id="changeReplaceId"
      >
        <v-img
          class="main-image"
          :key="selectedSlideData.assetId"
          :src="selectedSlideData.thumbnail"
          alt
          min-width="300"
          :aspect-ratio="16 / 9"
          @mouseover="showToolTip = true"
          @mouseout="showToolTip = false"
          @mousemove="trackMousePosition"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <clip-loader :color="`#21a7e0`" :width="20" :height="20" />
            </v-row>
          </template>
          <div
            v-if="
              selectedMainAction.id === 'convertTemplate' &&
              getMarkers.length &&
              showMarkers
            "
          >
            <div
              v-for="marker in getMarkers"
              class="marker"
              :class="{
                selected:
                  selectedSuggestion &&
                  selectedSuggestion.index === marker.index &&
                  selectedSuggestion.categoryIndex === marker.categoryIndex,
              }"
              :style="`left: ${marker.x}%; top:${marker.y}%; position: absolute; background-color:${marker.avatarColor}`"
              :key="`${marker.index}-${marker.avatarColor}`"
              @click.stop="handleMarkerClick(marker)"
            >
              <v-icon color="white" v-if="marker.status === 'accepted'">
                mdi-check
              </v-icon>
              <v-icon color="white" v-else-if="marker.status === 'rejected'">
                mdi-close
              </v-icon>
              <template v-else>{{ marker.index }}</template>
            </div>
          </div>
          <div
            v-else-if="
              isEditBPVisible && selectedMainAction.id !== 'convertTemplate'
            "
            class="edit-bp-container"
          >
            <EditBestPracticesTooltip
              v-for="(point, index) in getCurrentSlideBPList"
              :key="`${point.name}-${point.x}-${point.y}`"
              :bestPracticeData="point"
              :index="index"
              :onChange="handleBestPracticeOnChange"
              :onDelete="handleDeleteBestPractice"
              :isActionAllowed="isOwnerOfPrezentation || !point.isAddedByAuthor"
              :isShowToolTip="
                isOwnerOfPrezentation ||
                isBestPracticesVisible ||
                (!point.isAddedByAuthor &&
                  prezentationData.permission_level === 3)
              "
            />
          </div>
          <div
            v-else-if="
              viewBestPracticesBulb &&
              selectedMainAction.id !== 'convertTemplate'
            "
            class="best-practices-container"
          >
            <span
              class="coordinate-point"
              v-for="(point, index) in getCurrentSlideBPList"
              :style="`left: ${point.x}%; top: ${point.y}%; position: absolute;`"
              :key="`${point.x}-${point.y}`"
              :data-pendo-id="
                index === 0 ? `best-practices-bulb-${index}` : null
              "
            >
              <v-tooltip
                top
                max-width="400"
                v-if="
                  prezentationData.prezentationSource !== 'User Uploaded' ||
                  (prezentationData.prezentationSource === 'User Uploaded' &&
                    (isOwnerOfPrezentation ||
                      isBestPracticesVisible ||
                      (!point.isAddedByAuthor &&
                        prezentationData.permission_level === 3)))
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-img
                    :src="'/assets/icons/bulb.svg'"
                    width="37.5px"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span>{{ point.name }}</span>
              </v-tooltip>
            </span>
          </div>
          <div
            v-else-if="
              getShowBestPracticesForBpDecks &&
              selectedMainAction.id !== 'convertTemplate'
            "
            class="best-practices-container"
          >
            <span
              class="coordinate-point"
              v-for="(point, index) in getCurrentSlideBPList"
              :style="`left: ${point.x}%; top: ${point.y}%; position: absolute;`"
              :key="`${point.x}-${point.y}`"
              :data-pendo-id="
                index === 0 ? `best-practices-bulb-${index}` : null
              "
            >
              <v-tooltip top max-width="400">
                <template v-slot:activator="{ on, attrs }">
                  <v-img
                    :src="'/assets/icons/bulb.svg'"
                    width="37.5px"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span>{{ point.name }}</span>
              </v-tooltip>
            </span>
          </div>
        </v-img>
      </div>
      <div
        class="description-wrapper"
        v-if="
          !selectedSlideData.isUserUploaded &&
          !selectedSlideData.isUploaded &&
          !selectedSlideData.isGenerated &&
          !selectedSlideData.isRedesign &&
          selectedSlideData.source !== 'generated' &&
          selectedSlideData.source !== 'uploaded'
        "
      >
        <div class="description">
          {{
            selectedSlideData.title
              ? getDescription(selectedSlideData.title)
              : getDescription(selectedSlideData.outline)
          }}
        </div>
      </div>
    </div>
    <div v-else-if="loadingIllustrations" class="current-item-panel">
      <div class="image illustration-card">
        <v-img
          class="main-image"
          src=""
          alt
          min-width="300"
          :aspect-ratio="16 / 9"
        >
          <template v-slot:placeholder>
            <div v-if="illustrationData" class="illustration-container">
              <v-row class="fill-height ma-0" align="center" justify="center">
                <clip-loader :color="`#21a7e0`" :width="20" :height="20" />
              </v-row>
              <div class="illustration-text" v-if="illustrationData.text">
                {{ illustrationData.text }}
              </div>
              <v-img
                v-if="illustrationData.image"
                :src="illustrationData.image"
                alt
                max-width="250"
              >
              </v-img>
            </div>
          </template>
        </v-img>
      </div>
    </div>
    <div v-else-if="!prezLoader.isIdle()" class="current-item-panel">
      <div
        class="marker-switch"
        v-if="selectedMainAction.id === 'convertTemplate' && selectedTemplate"
      >
        <v-switch
          v-model="showMarkers"
          label="Show markers"
          hide-details
          dense
          :disabled="isSuggestionsLoaded"
        ></v-switch>
      </div>

      <div class="image illustration-card">
        <v-img
          class="main-image"
          src=""
          alt
          min-width="300"
          :aspect-ratio="16 / 9"
        >
          <template v-slot:placeholder>
            <VideoLoader v-model="prezLoader" v-if="!prezLoader.isIdle()" />
          </template>
        </v-img>
      </div>
    </div>

    <ConfirmationDialog
      :show="deleteSlideConfirmPopup"
      :on-primary-click="handleSideDeleteCancel"
      :on-secondary-click="handleSideDeleteConfirm"
      :on-close="handleSideDeleteCancel"
      :primary-btn-text="$t('common.cancel')"
      :secondary-btn-text="$t('common.delete')"
      :title="$t('build.step3.deleteSlide')"
      :text="$t('build.step3.deleteSlideText')"
    />
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapActions, mapGetters, mapState } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { getCopyDetails } from '../../../../utils/api-helper';
import { trackBuildEvents } from '../../../common/Analytics/BuildEvents';
import {
  BUILD,
  getBuildPrezentationData,
  NA,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_COMPANY_OF_AUD_TAILORED_FOR,
  TD_CONSTRUCT,
  TD_DECK_ID,
  TD_IDEA,
  TD_PLACEMENT,
  TD_PREZENTATION_CATEGORY,
  TD_SECTION_NAME,
  TD_SLIDEID,
  TD_SLIDE_NAME,
  TD_SLIDE_SOURCE,
  TD_STEP3,
  TD_PLACE_IN_STEP3,
  TD_ON_SLIDE,
  TD_ON_TOP,
  TD_AUD,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_TEMPLATE,
  TD_PREZENTATIONAUTHOR,
  TD_NUMBEROFSLIDES,
  TD_PLACE,
  getSlideCount,
} from '../../../common/Analytics/MatomoTrackingDataHelper';
import { trackPrezentationEvents } from '../../../common/Analytics/PrezentationEvents';
import EventBus from '../../../common/event-bus';
import { getTooltipForKeyword } from '../../../common/KeywordTooltipMapping';
import SlideListViewImage from '../../../common/SharedComponents/SlideListViewImage.vue';
import EditBestPracticesTooltip from './EditBestPractices/EditBestPracticeTooltip.vue';
import SectionHeader from '../../NewPrezentation/BuildPrezentation/Storyline/SectionHeader.vue';
import ConfirmationDialog from '../../../common/ConfirmationDialog.vue';
// import ProgressAnimationReviewDetails from '../../ComplianceChecker/ProgressAnimationReviewDetails.vue';
// import ProgressAnimationAcceptDetails from '../../ComplianceChecker/ProgressAnimationAcceptDetails.vue';
// import ProgressAnimation100Compliance from '../../ComplianceChecker/ProgressAnimation100Compliance.vue';
// import ComplyCheckingAnimationLoader from '../../ComplianceChecker/ComplyCheckingAnimationLoader.vue';
import { getTotalSuggestionsInSlide } from '../../../../utils/common';
import VideoLoader from '../../../common/VideoLoader.vue';
import { Loader, prezMapper } from '../../../../utils/loader-helper';
// import SynthesizeLoader from '../../Synthesize/SynthesizeDeckViewer/SynthesizeLoader.vue';

export default {
  components: {
    draggable,
    SectionHeader,
    SlideListViewImage,
    ClipLoader,
    ConfirmationDialog,
    EditBestPracticesTooltip,
    VideoLoader,
  },
  props: {
    origin: {
      type: String,
      default: 'Prezentation',
      required: false,
    },
    rightPanelOpen: {
      type: Function,
      default: () => {},
    },
    rightPanelClose: {
      type: Function,
      default: () => {},
    },
    updatePrezentation: {
      type: Function,
      default: () => {},
    },
    showRightPanel: {
      type: Boolean,
      default: false,
    },
    selectedAudienceData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isBestPracticesVisible: false,
      isSlideListExpand: true,
      isExpanded: true,
      heightOfThumbnail: null,
      expandedList: [],
      selectedSlideIndex: null,
      selectedSectionIndex: null,
      selectedSlideData: null,
      mouseXPos: 50,
      mouseYPos: 10,
      deleteSlideConfirmPopup: false,
      hitTheApi: false,
      isBestPracticeToggleEnabled: false,
      showBestPractices: false,
      showToolTip: false,
      bestPracticesItems: [],
      isOwnerOfPrezentation: false,
      selectedBestPractice: {},
      sectionError: '',
      illustrationData: null,
      onLeftSideSlideClick: false,
      showMarkers: true,
      deletePos: '',
      prezLoader: new Loader('synthesize'),
    };
  },
  watch: {
    loadingAnimations(val) {
      if (val) {
        this.prezLoader = new Loader(prezMapper[this.animationsData.type], 1);
      } else {
        this.prezLoader.nextState(2);
      }
    },
    showRightPanel(val) {
      if (val) {
        this.isSlideListExpand = false;
      } else {
        this.isSlideListExpand = true;
      }
    },
    selectedSlide(val) {
      if (val) {
        this.selectedSlideData = val;
        if (this.isBestPractices) {
          this.bestPracticesItems = [];
          this.setBestPracticesForBpDecks();
        } else {
          this.setBestPractices();
        }
      }
      setTimeout(() => {
        if (this.onLeftSideSlideClick) {
          this.handleDefaultSlideaction();
        }
        this.onLeftSideSlideClick = false;
      }, 100);
    },
    prezentationData(val) {
      if (val.isBestPracticesEnabled) {
        this.setBestPractices();
      }
    },
    loadingIllustrations(val) {
      if (val) {
        let timeout = 0;
        this.illustrationList.forEach((illustration, i) => {
          timeout += illustration.timeout || i * 2000;
          setTimeout(() => {
            this.illustrationData = illustration;
          }, timeout);
        });
      } else {
        this.illustrationData = null;
      }
    },
    selectedMainAction(val) {
      if (val.id === 'convertTemplate') {
        this.showMarkers = true;
      } else {
        this.showMarkers = false;
      }
    },
  },
  computed: {
    ...mapState('users', ['currentUser', 'themes']),
    ...mapState('prezentationDetails', [
      'prezentationData',
      'selectedSlideAction',
      'currentSlideBPList',
      'selectedSlide',
      'selectedMainAction',
      'isReplaceImgSelected',
      'showBPTooltips',
      'fromMosaicView',
      'loadingIllustrations',
      'loadingAnimations',
      'slideActions',
      'illustrationList',
      'animationsData',
      'complyDetails',
      'selectedSuggestion',
      'selectedTemplate',
      'galleryCurrentTab',
      'actionCompletedInfo',
    ]),
    ...mapGetters('users', [
      'getOPAccessLevel',
      'isUploadLimittedAccess',
      'isUploadFullAccess',
    ]),

    ...mapGetters('prezentationDetails', [
      'isViewAccess',
      'isRestrictedAccess',
      'isOpPrezentation',
      'isBestPractices',
      'showBestPracticeToggle',
      'isPrezentSourceBestPractice',
      'isUserUploadedPrezentation',
      'showShareOption',
      'isIOCUpload',
      'isGenerated',
      'isRedesigned',
      'isComply',
      'isTemplateRemoved',
      'isPartialAccess',
      'getCurrentSlideBPList',
      'getSelectedSectionIndex',
      'getSelectedSlideIndex',
      'getShowBestPracticesForBpDecks',
    ]),
    changeReplaceId() {
      return this.isReplaceImgSelected
        ? 'replace-main-image'
        : 'replace-main-icon';
    },

    dragOptions() {
      return {
        animation: 900,
        ghostClass: 'ghost-list',
        disabled: false,
      };
    },
    viewBestPracticesBulb() {
      return (
        (this.isGenerated || this.showBestPracticeToggle) &&
        this.selectedSlideAction.id !== 'editBestPractices' &&
        this.showBestPractices &&
        this.prezentationData.isBestPracticesEnabled &&
        this.showBPTooltips
      );
    },
    isEditBPVisible() {
      return this.selectedSlideAction.id === 'editBestPractices';
    },

    isSuggestionsLoaded() {
      return this.complyDetails.step === 1;
    },

    getMarkers() {
      return this.getCurrentSlideSuggestions
        ?.map((category) => category.suggestions)
        .flat()
        .filter((i) => i.x && i.y);
    },

    getCurrentSlideSuggestions() {
      if (!this.complyDetails?.slide_images) {
        return [];
      }

      let slideIndex = 0;
      this.prezentationData?.sections?.list?.forEach((section, i) => {
        section.slides.forEach((slide, j) => {
          if (
            i < this.selectedSectionIndex ||
            (this.selectedSectionIndex === i && j < this.selectedSlideIndex)
          ) {
            slideIndex++;
          }
        });
      });

      const slide = this.complyDetails.slide_images[slideIndex];

      return getTotalSuggestionsInSlide(slide);
    },

    isTCUnderProcess() {
      return (
        this.selectedMainAction.id === 'convertTemplate' &&
        !!this.selectedTemplate
      );
    },
  },

  mounted() {
    this.$root.$on('deleteSlide', this.handleLeftSideDelete);
    this.$root.$on('duplicateSlide', this.handleLeftSideDuplicate);
    this.$root.$on('sectionDragStart', this.hadleSectionDragStart);
    this.expandedList = [];
    this.prezentationData.sections.list.forEach((section, sectionIndex) => {
      this.expandedList.push(sectionIndex);
    });
    this.isOwnerOfPrezentation =
      this.currentUser.user.id === this.prezentationData.ownerID;
    if (
      this.selectedSlideAction?.id === 'editBestPractices' &&
      !this.fromMosaicView
    ) {
      this.setSelectedSlideAction({});
      this.setFromMosaicView(false);
    }
    if (this.fromMosaicView) {
      this.selectedSlideData = this.selectedSlide;

      this.selectedSlideIndex = this.getSelectedSlideIndex;
      this.selectedSectionIndex = this.getSelectedSectionIndex;
      this.setFromMosaicView(false);
    } else {
      // eslint-disable-next-line prefer-destructuring
      this.selectedSlideData = this.prezentationData.sections.list[0].slides[0];

      this.selectedSlideIndex = 0;
      this.selectedSectionIndex = 0;
    }

    // if (!this.isBestPractices) {
    //   this.setBestPractices();
    // }

    // this.setSelectedSlideData({
    //   data: this.selectedSlideData,
    //   sectionIndex: 0,
    //   slideIndex: 0,
    // });
    window.addEventListener('resize', this.updateLeftHeight);
    setTimeout(() => {
      this.updateLeftHeight();
    }, 10);
  },
  beforeUpdate() {
    this.updateLeftHeight();
  },
  beforeDestroy() {
    EventBus.$off('LEFTNAV_EXPANDED');
    EventBus.$off('LEFTNAV_MINIMIZED');
    EventBus.$off('PREZ_SLIDE_IMAGE_REPLACE_ON');
    this.$root.$off('deleteSlide', this.handleLeftSideDelete);
    this.$root.$off('duplicateSlide', this.handleLeftSideDuplicate);
    this.$root.$off('sectionDragStart', this.hadleSectionDragStart);
    // Removing the resize eventListener
    window.removeEventListener('resize', this.updateLeftHeight);
  },
  methods: {
    ...mapActions('prezentationDetails', [
      'setSelectedSlideData',
      'setCurrentSlideBPList',
      'setSelectedMainAction',
      'setSelectedSlideAction',
      'updatePrezentationData',
      'setFromMosaicView',
      'setSelectedSuggestion',
      'updateActionCompletedInfo',
      'resetReplaceVisualTabState',
    ]),
    handleMarkerClick(marker) {
      this.setSelectedSuggestion({
        index: marker.index,
        categoryIndex: marker.categoryIndex,
        category: marker.category,
      });
    },
    addSlidesInBetween(sectionIndex, slideIndex, origin) {
      const otherData = {
        [TD_AUD]: this.prezentationData.audienceID,
        [TD_PREZNAME]: this.prezentationData.name,
        [TD_PREZTYPE]: this.prezentationData.type,
        [TD_TEMPLATE]: this.prezentationData.theme,
        [TD_PREZENTATIONAUTHOR]: this.prezentationData?.author,
        [TD_NUMBEROFSLIDES]: getSlideCount(this.prezentationData),
        [TD_PLACEMENT]: origin,
      };
      if (this.origin.toLowerCase() === BUILD) {
        trackBuildEvents.buildAddSlideClick(this.currentUser, otherData);
      } else {
        trackPrezentationEvents.prezentationAddSlideClick(
          this.currentUser,
          this.prezentationData,
          otherData,
        );
      }
      this.setSelectedMainAction({
        id: 'addSlide',
        label: 'build.step3.addSlideText',
        tooltip: 'build.step3.addSlideFromGallery',
        icon: 'mdi-plus',
        isCustomIcon: false,
      });
      if (this.rightPanelOpen) {
        this.rightPanelOpen();
      }
      setTimeout(() => {
        this.$root.$emit(
          'addSlidesInBetween',
          sectionIndex,
          slideIndex,
          origin,
        );
      }, 1);
    },
    handleDefaultSlideaction() {
      const temp = this.slideActions.find(
        (item) => item.id === this.selectedSlideAction.id,
      );
      if (!temp && this.checkDuplicateAction()) {
        this.setSelectedSlideAction(this.slideActions[0]);
        this.rightPanelOpen();
      } else if (!temp && !this.checkDuplicateAction()) {
        this.rightPanelClose();
      }
    },
    checkDuplicateAction() {
      if (
        this.slideActions[0]?.id === 'addToSlides' ||
        this.slideActions[0]?.id === 'duplicateSlide' ||
        this.slideActions[0]?.id === 'deleteSlide'
      ) {
        return false;
      }
      return true;
    },
    handleSlideListToggle() {
      this.isSlideListExpand = !this.isSlideListExpand;
    },
    getFilePathWithoutPrependSlash(filename) {
      return filename
        ?.split('/')
        .filter((t) => t)
        .join('/');
    },
    setBestPracticesForBpDecks() {
      this.prezentationData?.bestPractices?.slideToolTips.forEach(
        (slideToolTip) => {
          const slideAssetId = this.getFilePathWithoutPrependSlash(
            this.selectedSlideData.assetId,
          );
          const toolTipAssetId = this.getFilePathWithoutPrependSlash(
            slideToolTip.assetId,
          );
          if (slideAssetId === toolTipAssetId) {
            slideToolTip.tips.forEach((tip, i) => {
              const coordinates = tip[`pro_tip${i + 1}_pos`].split(',');
              this.bestPracticesItems.push({
                name: tip[`pro_tip${i + 1}`],
                x: parseFloat(coordinates[0]),
                y: parseFloat(coordinates[1]),
              });
            });
            this.setCurrentSlideBPList(this.bestPracticesItems);
          }
        },
      );
    },
    setBestPractices() {
      this.bestPracticesItems = [];
      this.setCurrentSlideBPList(this.bestPracticesItems);
      if (!this.prezentationData.isBestPracticesEnabled) {
        this.showBestPractices = false;
        return;
      }
      if (this.prezentationData?.bestPractices?.slideToolTips.length) {
        this.prezentationData?.bestPractices?.slideToolTips.forEach(
          (slideToolTip) => {
            const slideAssetId = this.getFilePathWithoutPrependSlash(
              this.selectedSlideData.assetId,
            );
            const toolTipAssetId = this.getFilePathWithoutPrependSlash(
              slideToolTip.assetId,
            );
            if (slideAssetId === toolTipAssetId) {
              this.isBestPracticesVisible = slideToolTip.visible;
              if (slideToolTip?.tips.length && slideToolTip?.visible) {
                this.showBestPractices = true;
              }
              this.bestPracticesItems = [];
              this.setCurrentSlideBPList(this.bestPracticesItems);
              let toolTipCount = 0;
              slideToolTip.tips.forEach((tip, i) => {
                if (
                  !tip.isAddedByAuthor &&
                  this.prezentationData.permission_level === 3
                ) {
                  // for editor tooltips
                  this.showBestPractices = true;
                }
                const coordinates = tip[`pro_tip${i}_pos`].split(',');
                this.bestPracticesItems.push({
                  name: tip[`pro_tip${i}`],
                  x: parseFloat(coordinates[0]),
                  y: parseFloat(coordinates[1]),
                  isAddedByAuthor: tip.isAddedByAuthor,
                });
                this.setCurrentSlideBPList(this.bestPracticesItems);
                if (tip?.isAddedByAuthor) {
                  toolTipCount++;
                }
                if (toolTipCount === 5) {
                  this.isAllBestPracticesAddedByAuthor = true;
                }
              });
            }
          },
        );
      } else {
        this.bestPracticesItems = [];
        this.setCurrentSlideBPList(this.bestPracticesItems);
      }
    },
    handleBestPracticeOnChange(point, index) {
      point.isAddedByAuthor = this.isOwnerOfPrezentation;
      this.bestPracticesItems = this.getCurrentSlideBPList;
      this.selectedBestPractice = { ...point };
      this.bestPracticesItems[index] = { ...point };
      this.setCurrentSlideBPList(this.bestPracticesItems);
    },
    handleDeleteBestPractice(point, index) {
      this.bestPracticesItems.splice(index, 1);
      this.setCurrentSlideBPList(this.bestPracticesItems);
    },
    trackMousePosition(e) {
      if (!this.showToolTip) return;
      // added some px to avoid flickering
      this.mouseXPos = e.offsetX + 10;
      this.mouseYPos = e.offsetY + 10;
    },
    shouldEnableClick(prezentationData) {
      return prezentationData.prezentationSource === 'IOCUpload';
    },
    getDescription(keyword) {
      if (this.isGenerated || this.isRedesigned) {
        return '';
      }
      return getTooltipForKeyword(keyword);
    },
    getSerialNumber(sectionIndex, slideIndex) {
      const { list } = this.prezentationData.sections;
      let count = 0;
      for (let index = 0; index < sectionIndex; index++) {
        count += list[index].slides.filter((i) => i.assetId).length;
      }

      count += slideIndex + 1;

      return count;
    },
    updateLeftHeight() {
      //   if (!this.heightOfThumbnail) {
      if (this.$refs.thumbnailImage && this.$refs.thumbnailImage.clientHeight) {
        this.heightOfThumbnail = this.$refs.thumbnailImage.clientHeight;
      } else {
        this.heightOfThumbnail =
          this.$refs?.thumbnailImageDefault?.clientHeight;
      }
      //   }
    },
    handleExpandToggle() {
      this.isExpanded = !this.isExpanded;
      if (this.isExpanded) {
        this.expandedList = [];
        this.prezentationData.sections.list.forEach((section, sectionIndex) => {
          this.expandedList.push(sectionIndex);
        });
      } else {
        this.expandedList = [];
      }
    },

    hadleSlideDragStart() {
      this.tempExpandedList = [...this.expandedList];
      this.expandedList = [];

      this.prezentationData.sections.list.forEach((section, sectionIndex) => {
        this.expandedList.push(sectionIndex);
      });
    },
    hadleSlideDragEnd(e) {
      if (e.from.className === e.to.className) {
        this.selectedSlideIndex = e.newIndex || 0;
      } else {
        let sectionIndex = 0;
        e.to?.classList?.forEach((c) => {
          if (c.indexOf('sectionIndex-') !== -1) {
            const [, index] = c.split('-');
            sectionIndex = Number(index);
          }
        });
        this.selectedSectionIndex = sectionIndex;
        this.selectedSlideIndex = e.newIndex || 0;
      }
      const payload = this.handlePayload(
        this.selectedSectionIndex,
        this.selectedSlideIndex,
        'single slide',
      );
      if (this.hitTheApi && e.from.classList[3] === 'from-gallery') {
        getCopyDetails(payload)
          .then((resp) => {
            const slidesList = {};
            resp.data?.slides.forEach((slide) => {
              slidesList[slide.uniqueID] = slide;
            });
            const presentation = JSON.parse(
              JSON.stringify(this.prezentationData),
            );
            presentation.s3dirpath = resp.data?.s3dirpath;

            if (this.prezentationData.sections?.list?.length) {
              presentation.sections.list =
                this.prezentationData?.sections.list.map((section) => {
                  section.slides = section.slides.map((slide) => {
                    if (slidesList[slide.uniqueID]) {
                      return {
                        ...slide,
                        ...slidesList[slide.uniqueID],
                      };
                    }
                    return slide;
                  });
                  return section;
                });
              this.updatePrezentation(presentation);
            }
          })
          .catch((err) => console.log(err));
        this.hitTheApi = false;
      } else {
        this.updatePrezentation(this.prezentationData);
      }
      this.prezentationData.sections.list = JSON.parse(
        JSON.stringify(this.prezentationData.sections.list),
      );
      setTimeout(() => {
        this.selectedSlideData =
          this.prezentationData.sections.list[
            this.selectedSectionIndex
          ]?.slides[this.selectedSlideIndex];

        this.setSelectedSlideData({
          data: this.selectedSlideData,
          sectionIndex: this.selectedSectionIndex,
          slideIndex: this.selectedSlideIndex,
        });
      }, 1);

      if (e.from.classList[3] === 'from-gallery') {
        if (this.origin.toLowerCase() === BUILD) {
          trackBuildEvents.buildIdeaDragged(this.currentUser, {
            ...getBuildPrezentationData(this.prezentationData),
            [TD_PLACEMENT]: 'Step 3',
            [TD_PREZENTATION_CATEGORY]: this.prezentationData.prezentationType,
            [TD_IDEA]:
              this.selectedSlideData?.category ||
              this.selectedSlideData?.categories[0],
            [TD_SLIDE_SOURCE]: this.getSlideSource(this.selectedSlideData),
            [TD_CONSTRUCT]: this.selectedSlideData?.constructs
              ? this.selectedSlideData?.constructs[0]
              : '',
            [TD_SLIDE_NAME]: this.selectedSlideData?.name,
            [TD_SLIDEID]: this.getSlideId(this.selectedSlideData),
            [TD_DECK_ID]: this.getDeckId(this.selectedSlideData),
            [TD_COMMON_COLUMN_NAME]: 'Tab',
            [TD_COMMON_COLUMN_VALUE]: this.galleryCurrentTab || '',
            [TD_COMPANY_OF_AUD_TAILORED_FOR]: this.selectedAudienceData
              ? this.selectedAudienceData?.companyName
              : '',
          });
        } else {
          trackPrezentationEvents.prezentationsIdeaDragged(
            this.currentUser,
            this.prezentationData,
            {
              [TD_PLACEMENT]: TD_STEP3,
              [TD_IDEA]:
                this.selectedSlideData?.category ||
                this.selectedSlideData?.categories[0],
              [TD_SLIDE_SOURCE]: this.getSlideSource(this.selectedSlideData),
              [TD_CONSTRUCT]: this.selectedSlideData?.constructs
                ? this.selectedSlideData?.constructs[0]
                : '',
              [TD_SLIDE_NAME]: this.selectedSlideData?.name,
              [TD_SLIDEID]: this.getSlideId(this.selectedSlideData),
              [TD_DECK_ID]: this.getDeckId(this.selectedSlideData),
              [TD_COMMON_COLUMN_NAME]: 'Tab',
              [TD_COMMON_COLUMN_VALUE]: this.galleryCurrentTab || '',
              [TD_COMPANY_OF_AUD_TAILORED_FOR]: this.selectedAudienceData
                ? this.selectedAudienceData?.companyName
                : '',
            },
          );
        }
      }
    },
    handlePayload(sectionIndex, slideIndex, category) {
      const temp = {
        s3dirpath: this.prezentationData?.s3dirpath || null,
        prezentationID: this.prezentationData.id,
        slides: [],
      };

      if (
        category === 'single slide' &&
        this.haveTheFlag(
          this.prezentationData.sections.list[sectionIndex].slides[slideIndex],
        )
      ) {
        this.hitTheApi = true;
        const {
          assetId,
          filename,
          uniqueID,
          isSynthesis,
          isComply,
          isGenerated,
          isAnonymized,
          isUserUploaded,
          isRedesign,
        } =
          this.prezentationData.sections.list[sectionIndex].slides[slideIndex];
        temp.slides[0] = {
          assetId,
          filename,
          uniqueID,
          ...(isSynthesis ? { isSynthesis } : {}),
          ...(isComply ? { isComply } : {}),
          ...(isGenerated ? { isGenerated } : {}),
          ...(isAnonymized ? { isAnonymized } : {}),
          ...(isUserUploaded ? { isUserUploaded } : {}),
          ...(isRedesign ? { isRedesign } : {}),
        };
      }
      if (category === 'Section') {
        const sections =
          this.prezentationData.sections.list[sectionIndex].slides;
        sections.forEach((item) => {
          if (this.haveTheFlag(item)) {
            const {
              assetId,
              filename,
              uniqueID,
              isSynthesis,
              isComply,
              isGenerated,
              isAnonymized,
              isUserUploaded,
              isRedesign,
            } = item;
            const object = {
              assetId,
              filename,
              uniqueID,
              ...(isSynthesis ? { isSynthesis } : {}),
              ...(isComply ? { isComply } : {}),
              ...(isGenerated ? { isGenerated } : {}),
              ...(isAnonymized ? { isAnonymized } : {}),
              ...(isUserUploaded ? { isUserUploaded } : {}),
              ...(isRedesign ? { isRedesign } : {}),
            };
            temp.slides.push(object);
          }
        });
        if (temp.slides.length > 0) {
          this.hitTheApi = true;
        }
      }
      return temp;
    },
    haveTheFlag(Obj) {
      const keysList = [
        'isSynthesis',
        'isComply',
        'isGenerated',
        'isAnonymized',
        'isUserUploaded',
        'isRedesign',
      ];
      for (const key in Obj) {
        if (keysList.includes(key) && Obj[key]) {
          return true;
        }
      }
      return false;
    },
    hadleSectionDragStart() {
      this.tempExpandedList = [...this.expandedList];
      this.expandedList = [];
    },
    onSectionDragEnd(origin) {
      if (this.origin.toLowerCase() === BUILD) {
        const otherData = {
          [TD_PREZENTATION_CATEGORY]: origin,
          ...getBuildPrezentationData(this.prezentationData),
        };
        trackBuildEvents.buildMaximizeMinimizeClick(
          this.currentUser,
          otherData,
        );
      }
    },

    handleSideDeleteCancel() {
      this.deleteSlideConfirmPopup = false;
      this.tempSlideIndexData = null;
    },
    handleSideDeleteConfirm() {
      if (this.tempSlideIndexData) {
        const { slideIndex, sectionIndex } = this.tempSlideIndexData;

        if (
          this.selectedSlideData?.isSynthesis &&
          slideIndex === 0 &&
          sectionIndex === 0 &&
          this.actionCompletedInfo?.synthesisCompleted
        ) {
          this.updateActionCompletedInfo({ synthesisCompleted: false });
        }
        this.prezentationData.sections.list[sectionIndex].slides.splice(
          slideIndex,
          1,
        );

        this.selectedSlideData = null;
        this.setSelectedSlideData({
          data: this.selectedSlideData,
          sectionIndex,
          slideIndex,
        });
        const { list } = this.prezentationData.sections;

        if (!list[sectionIndex]?.slides[slideIndex]) {
          let indexObj = {
            seIndex: 0,
            slIndex: 0,
          };
          list.forEach((section, seIndex) => {
            if (section.slides.length) {
              section.slides.forEach((slide, slIndex) => {
                if (
                  indexObj.seIndex <= sectionIndex &&
                  indexObj.slIndex <= slideIndex
                ) {
                  indexObj = { seIndex, slIndex };
                }
                if (
                  indexObj.seIndex >= sectionIndex &&
                  indexObj.slIndex >= slideIndex &&
                  !indexObj.found
                ) {
                  indexObj = { seIndex, slIndex, found: true };
                }
              });
            }
          });

          this.selectedSectionIndex = indexObj.seIndex;
          this.selectedSlideIndex = indexObj.slIndex;
        } else {
          this.selectedSectionIndex = sectionIndex;
          this.selectedSlideIndex = slideIndex;
        }

        if (!this.selectedSlideData) {
          this.selectedSlideData =
            list[this.selectedSectionIndex].slides[this.selectedSlideIndex];

          this.setSelectedSlideData({
            data: this.selectedSlideData,
            sectionIndex: this.selectedSectionIndex,
            slideIndex: this.selectedSlideIndex,
          });
        }
        this.updatePrezentation(this.prezentationData);
      }
      if (
        this.origin.toLowerCase() === 'my_prezentation_copied' ||
        this.origin.toLowerCase() === 'my_prezentation'
      ) {
        trackPrezentationEvents.prezentationsIdeaDelete(
          this.currentUser,
          this.prezentationData,
          {
            [TD_IDEA]: this.selectedSlideData?.outline,
            [TD_PLACEMENT]: TD_STEP3,
            [TD_COMMON_COLUMN_NAME]: TD_PLACE,
            [TD_COMMON_COLUMN_VALUE]: this.deletePos,
            [TD_SLIDE_SOURCE]: this.getSlideSource(this.selectedSlideData),
            [TD_DECK_ID]: this.getDeckId(this.selectedSlideData),
            [TD_SLIDEID]: this.getSlideId(this.selectedSlideData),
            [TD_COMPANY_OF_AUD_TAILORED_FOR]: this.selectedAudienceData
              ? this.selectedAudienceData?.companyName
              : '',
          },
        );
      } else if (this.origin.toLowerCase() === BUILD) {
        trackBuildEvents.buildIdeaDelete(this.currentUser, {
          ...getBuildPrezentationData(this.prezentationData),
          [TD_IDEA]: this.selectedSlideData?.outline,
          [TD_PLACEMENT]: 'Step 3',
          [TD_COMMON_COLUMN_NAME]: TD_PLACE_IN_STEP3,
          [TD_COMMON_COLUMN_VALUE]: this.deletePos,
          [TD_PREZENTATION_CATEGORY]: this.prezentationData.prezentationType,
          [TD_SLIDE_SOURCE]: this.getSlideSource(this.selectedSlideData),
          [TD_DECK_ID]: this.getDeckId(this.selectedSlideData),
          [TD_SLIDEID]: this.getSlideId(this.selectedSlideData),
          [TD_COMPANY_OF_AUD_TAILORED_FOR]: this.selectedAudienceData
            ? this.selectedAudienceData?.companyName
            : '',
        });
      }
      this.deleteSlideConfirmPopup = false;
      if (this.isDeleteFromSlideDetail) this.closeSlideDetail();
    },
    getDeckId(slideData) {
      let uniqueID;
      if (!slideData) {
        return NA;
      }

      if (slideData?.prefs?.is_know === 'yes') {
        ({ unique_id: uniqueID } = slideData);
      } else {
        ({ uniqueID } = slideData);
      }
      if (!uniqueID?.includes('deck')) {
        return 'Deck ID not available';
      }
      return uniqueID?.substring(0, uniqueID.indexOf('deck') + 5);
    },
    getSlideSource(slideData) {
      if (!slideData) {
        return NA;
      }
      if (slideData.source !== undefined) {
        return slideData.source;
      }
      if (slideData?.prefs?.source) {
        return slideData?.prefs?.source;
      }
      return 'Source not available';
    },

    getSlideId(slideData) {
      let uniqueID;
      if (!slideData) {
        return NA;
      }
      if (slideData?.prefs?.is_know === 'yes') {
        ({ unique_id: uniqueID } = slideData);
      } else {
        ({ uniqueID } = slideData);
      }
      if (['redesign', 'generated', 'uploaded'].includes(slideData?.source))
        return uniqueID;
      return uniqueID?.split('deck1_')[1]?.split('_')[0];
    },
    hadleSectionDragEnd(e) {
      if (this.tempExpandedList) {
        this.expandedList = [...this.tempExpandedList];
      }

      this.prezentationData.sections.list = JSON.parse(
        JSON.stringify(this.prezentationData.sections.list),
      );
      this.selectedSlideIndex = 0;
      this.selectedSectionIndex = e.newIndex;
      const payload = this.handlePayload(
        this.selectedSectionIndex,
        null,
        'Section',
      );
      if (this.hitTheApi && e.from.classList[3] === 'from-gallery') {
        getCopyDetails(payload)
          .then((resp) => {
            const slidesList = {};
            resp.data?.slides.forEach((slide) => {
              slidesList[slide.uniqueID] = slide;
            });
            const presentation = JSON.parse(
              JSON.stringify(this.prezentationData),
            );

            if (this.prezentationData.sections?.list?.length) {
              presentation.sections.list =
                this.prezentationData?.sections.list.map((section) => {
                  section.slides = section.slides.map((slide) => {
                    if (slidesList[slide.uniqueID]) {
                      return {
                        ...slide,
                        ...slidesList[slide.uniqueID],
                      };
                    }
                    return slide;
                  });
                  return section;
                });
              this.updatePrezentation(presentation);
            }
          })
          .catch((err) => console.log(err));
        this.hitTheApi = false;
      } else {
        this.updatePrezentation(this.prezentationData);
      }
      if (
        this.prezentationData.sections.list[this.selectedSectionIndex]?.slides
          .length === 0
      ) {
        if (this.selectedSectionIndex === 0) {
          this.selectedSectionIndex = 1;
        } else {
          this.selectedSectionIndex -= 1;
        }
      }

      this.expandedList.push(this.selectedSectionIndex);
      // eslint-disable-next-line prefer-destructuring
      setTimeout(() => {
        this.selectedSlideData =
          this.prezentationData.sections.list[
            this.selectedSectionIndex
          ]?.slides[0];

        this.setSelectedSlideData({
          data: this.selectedSlideData,
          sectionIndex: this.selectedSectionIndex,
          slideIndex: 0,
        });
      }, 1);
      if (this.origin.toLowerCase() === BUILD) {
        if (e.from.classList[3] === 'from-gallery') {
          const otherData = {
            [TD_PLACEMENT]: 'Step 3',
            ...getBuildPrezentationData(this.prezentationData),
            [TD_SECTION_NAME]:
              this.prezentationData?.sections?.list[e.newIndex]?.sectionName,
          };
          trackBuildEvents.buildSectionDragged(this.currentUser, otherData);
        }
        if (e.type === 'end') {
          trackBuildEvents.buildSectionPositionChanged(this.currentUser, {
            ...getBuildPrezentationData(this.prezentationData),
            [TD_SECTION_NAME]:
              this.prezentationData?.sections?.list[e.newIndex]?.sectionName,
            [TD_PLACEMENT]: 'Step 3',
          });
        }
      } else {
        if (e.from.classList[3] === 'from-gallery')
          trackPrezentationEvents.prezentationsSectionDragged(
            this.currentUser,
            this.prezentationData,
            {
              [TD_SECTION_NAME]:
                this.prezentationData?.sections?.list[e.newIndex]?.sectionName,
              [TD_PLACEMENT]: TD_STEP3,
            },
          );
        if (e.type === 'end')
          trackPrezentationEvents.prezentationsSectionPositionChanged(
            this.currentUser,
            this.prezentationData,
            {
              [TD_SECTION_NAME]:
                this.prezentationData?.sections?.list[e.newIndex]?.sectionName,
              [TD_PLACEMENT]: TD_STEP3,
            },
          );
      }
    },
    handleLeftSideDuplicate(slide, slideIndex, sectionIndex, pos) {
      this.prezentationData.sections.list[sectionIndex].slides.splice(
        slideIndex,
        0,
        slide,
      );

      this.updatePrezentation(this.prezentationData);

      if (
        this.origin.toLowerCase() === 'my_prezentation_copied' ||
        this.origin.toLowerCase() === 'my_prezentation'
      ) {
        trackPrezentationEvents.prezentationsIdeaDuplicate(
          this.currentUser,
          this.prezentationData,
          {
            [TD_IDEA]: slide?.outline,
            [TD_PLACEMENT]: TD_STEP3,
            [TD_SLIDE_SOURCE]: this.getSlideSource(slide),
            [TD_DECK_ID]: this.getDeckId(slide),
            [TD_SLIDEID]: this.getSlideId(slide),
            [TD_COMMON_COLUMN_NAME]: TD_PLACE,
            [TD_COMMON_COLUMN_VALUE]:
              pos === TD_ON_SLIDE ? TD_ON_SLIDE : TD_ON_TOP,
            [TD_COMPANY_OF_AUD_TAILORED_FOR]: this.selectedAudienceData
              ? this.selectedAudienceData?.companyName
              : '',
          },
        );
      } else if (this.origin.toLowerCase() === BUILD) {
        trackBuildEvents.buildIdeaDuplicate(this.currentUser, {
          ...getBuildPrezentationData(this.prezentationData),
          [TD_IDEA]: slide?.outline,
          [TD_PLACEMENT]: 'Step 3',
          [TD_COMMON_COLUMN_NAME]: TD_PLACE_IN_STEP3,
          [TD_COMMON_COLUMN_VALUE]:
            pos === TD_ON_SLIDE ? TD_ON_SLIDE : TD_ON_TOP,
          [TD_PREZENTATION_CATEGORY]: this.prezentationData.prezentationType,
          [TD_SLIDE_SOURCE]: this.getSlideSource(slide),
          [TD_DECK_ID]: this.getDeckId(slide),
          [TD_SLIDEID]: this.getSlideId(slide),
          [TD_COMPANY_OF_AUD_TAILORED_FOR]: this.selectedAudienceData
            ? this.selectedAudienceData?.companyName
            : '',
        });
      }
    },
    handleLeftSideDelete(slideIndex, sectionIndex, isFromSlideDetail, pos) {
      if (this.selectedSlideAction.id === 'changeVisual') {
        this.resetReplaceVisualTabState(true);
      }
      this.tempSlideIndexData = { slideIndex, sectionIndex };
      this.deleteSlideConfirmPopup = true;
      this.isDeleteFromSlideDetail = !!isFromSlideDetail;
      this.deletePos = pos === TD_ON_SLIDE ? TD_ON_SLIDE : TD_ON_TOP;
    },

    handleLeftSideItemClick(slide, slideIndex, sectionIndex) {
      if (this.selectedSlideAction.id === 'changeVisual') {
        this.resetReplaceVisualTabState(true);
      }

      this.selectedSlideData = JSON.parse(JSON.stringify(slide));
      this.setSelectedSlideData({
        data: this.selectedSlideData,
        sectionIndex,
        slideIndex,
      });
      this.selectedSlideIndex = slideIndex;
      this.selectedSectionIndex = sectionIndex;

      // if (!this.isBestPractices) {
      //   this.setBestPractices();
      // }
      this.onLeftSideSlideClick = true;
    },
    checkBestPractice() {
      if (!this.prezentationData.isBestPracticesEnabled) {
        this.isBestPracticeToggleEnabled = false;
        return;
      }
      if (this.prezentationData.bestPractices?.slideToolTips?.length) {
        this.bestPracticesGuideObject =
          this.prezentationData.bestPractices.slideToolTips.reduce(
            (prev, current) => {
              prev[current.assetId] = current.tips.map((tip) => {
                const keys = Object.keys(tip);
                let coords = [];
                let desc = '';
                keys.forEach((k) => {
                  if (k.includes('_pos')) {
                    coords = tip[k] ? tip[k].split(',') : [];
                  } else if (k !== 'isAddedByAuthor') {
                    desc = tip[k];
                  }
                });
                return {
                  x: coords[0],
                  y: coords[1] ? coords[1].replace('\r', '') : null, // this replace is reqired PM-4718
                  tooltip: desc,
                  isAddedByAuthor: tip.isAddedByAuthor,
                };
              });
              prev[current.assetId] = prev[current.assetId].filter((p) => p.x);
              return prev;
            },
            {},
          );
        this.isBestPracticeToggleEnabled =
          !!this.bestPracticesGuideObject[this.selectedSlideData.assetId]
            ?.length;
      }
    },
    handleAddSection(newName) {
      if (newName) {
        if (this.prezentationData.sections.list.length >= 5) {
          this.sectionError = 'sections error here....';
        } else {
          this.sectionError = '';
          this.selectedSectionIndex += 1;
          this.prezentationData.sections.list.unshift({
            sectionName: newName,
            slides: [],
          });
          const slideData =
            this.prezentationData.sections?.list[this.selectedSectionIndex]
              ?.slides[this.selectedSlideIndex];

          this.selectedSlideData = slideData;
          this.setSelectedSlideData({
            data: slideData,
            sectionIndex: this.selectedSectionIndex,
            slideIndex: this.selectedSlideIndex,
          });

          this.updatePrezentationData(
            JSON.parse(JSON.stringify(this.prezentationData)),
          );
        }
      }
    },
    onSectionNameChange(sectionIndex, newValue) {
      this.prezentationData.sections.list[sectionIndex].sectionName = newValue;
      const presentation = JSON.parse(JSON.stringify(this.prezentationData));
      this.updatePrezentation(presentation);
    },
    handleDeleteSection(sectionIndex) {
      const isSelectedSlideFromDeletedSection =
        this.selectedSectionIndex === sectionIndex;
      if (this.prezentationData.sections.list.length >= 5) {
        this.sectionError = 'sections error here....';
      } else {
        this.sectionError = '';
      }
      this.prezentationData.sections.list.splice(sectionIndex, 1);
      let isSlidesPresent = false;

      this.prezentationData.sections.list.forEach((item, index) => {
        if (item.slides.length) {
          isSlidesPresent = true;
          if (isSelectedSlideFromDeletedSection) {
            if (index < sectionIndex) {
              this.selectedSectionIndex = index;
              this.selectedSlideIndex = item.slides.length - 1;
            }
            if (index >= sectionIndex && !this.selectedSlideData) {
              this.selectedSectionIndex = index;
              this.selectedSlideIndex = 0;
              // eslint-disable-next-line prefer-destructuring
              this.selectedSlideData = item.slides[0];
            }
          }
        }
      });
      if (
        !isSelectedSlideFromDeletedSection &&
        sectionIndex < this.selectedSectionIndex
      ) {
        this.selectedSectionIndex =
          this.selectedSectionIndex > 0
            ? this.selectedSectionIndex - 1
            : this.selectedSectionIndex;
      }
      if (
        !this.selectedSlideData &&
        this.selectedSectionIndex !== null &&
        this.prezentationData.sections.list.length
      ) {
        this.selectedSlideData =
          this.prezentationData.sections?.list[
            this.selectedSectionIndex
          ].slides[this.selectedSlideIndex];
      }

      if (!isSlidesPresent) {
        this.selectedSlideData = null;
        this.selectedSectionIndex = null;
        this.selectedSlideIndex = null;
      }
      this.setSelectedSlideData({
        data: this.selectedSlideData,
        sectionIndex: this.selectedSectionIndex,
        slideIndex: this.selectedSlideIndex,
      });
      const presentation = JSON.parse(JSON.stringify(this.prezentationData));
      this.updatePrezentation(presentation);
    },
  },
};
</script>

<style lang="scss" scoped>
.slides-list-view {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 0;
  margin-top: 20px;

  .detail-panel-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .detail-expand-btn {
      bottom: -24px;
    }
  }

  .detail-expand-btn {
    position: absolute;
    min-width: 24px !important;
    height: 24px !important;
    background-color: #fff !important;
    border-radius: 0 !important;
    padding: 0 !important;
    z-index: 1;

    &:hover {
      background-color: #fff;
    }
  }

  &.less-width {
    width: calc(100% - 340px);
  }

  .left-side-items-outer {
    .detail-panel-wrapper {
      justify-content: flex-end;

      .detail-expand-btn {
        right: -24px;
        display: none;
      }
    }

    &:hover {
      .detail-expand-btn {
        display: block;
      }
    }
  }
  .main-image {
    .edit-bp-container {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }
  }

  ::v-deep .v-responsive {
    overflow: unset;
    // z-index: 1;
  }
  .left-side-items {
    height: 100%;
    max-width: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 10px 0px 0px;
    flex-grow: 1.5;
    &::-webkit-scrollbar {
      width: 6px;
    }

    .error-message {
      color: #d32f2f;
      margin: 0 0 10px 4px;
    }

    .dragArea {
      position: relative;
    }

    // .sections-drag {
    //   padding-bottom: 50px;
    // }
    .left-side-item {
      border-radius: 8px;
      box-shadow: 1px 1px 4px rgb(0 0 0 / 10%), -1px -1px 4px rgb(0 0 0 / 10%);
      // margin-bottom: 15px;
      overflow: hidden;
      width: 100%;
      &.selected {
        border: 2px solid $zen-blue-default;
      }

      &:last-child {
        margin-bottom: 15px;
      }
    }

    .left-side-item:hover {
      box-shadow: 2px 2px 8px rgb(0 0 0 / 30%), -1px -1px 4px rgb(0 0 0 / 30%);
    }

    .add-new {
      margin-top: 0 !important;
    }
  }
  .current-item-panel {
    flex-grow: 3.5;
    padding-bottom: 60px;
    padding-left: 20px;
    width: 80%;

    .marker-switch {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      ::v-deep .v-input--selection-controls {
        margin-top: 0;
        padding-top: 0;
      }
      ::v-deep .v-input--selection-controls__input + .v-label {
        color: #000000;
        font-size: 14px;
        top: -2px;
      }
    }

    .marker {
      display: flex;
      align-items: center;
      justify-content: center;
      // transform: translate(-50%, -50%);
      transform-origin: center center;
      border-radius: 50%;
      height: 24px;
      width: 24px;
      color: white;
      cursor: pointer;
      transition: all 0.2s ease-in;
      box-sizing: content-box;

      &.selected {
        z-index: 2;
        height: 26px;
        width: 26px;
        border: 3px solid currentColor;
        border-color: color-mix(in srgb, currentColor 30%, transparent);
        transition: all 0.2s ease-in;
        box-shadow: -1px -1px 8px 0px rgba(0, 0, 0, 0.5),
          1px 1px 4px 0px rgba(0, 0, 0, 0.1);
      }

      &:hover {
        border: 3px solid currentColor;
        border-color: color-mix(in srgb, currentColor 30%, transparent);
        transition: all 0.2s ease-in;
        box-shadow: -1px -1px 8px 0px rgba(0, 0, 0, 0.5),
          1px 1px 4px 0px rgba(0, 0, 0, 0.1);
      }
    }

    .image {
      border-radius: 8px;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
        -1px -1px 4px rgba(0, 0, 0, 0.1);
      // cursor: pointer;
      margin-bottom: 30px;

      &.default-cursor {
        cursor: default;
      }

      ::v-deep .v-image__image {
        border-radius: 8px;
      }
    }

    .illustration-card {
      z-index: 30;
      position: relative;
      background-color: #fff;
    }

    .illustration-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 7%;
    }

    .illustration-text {
      margin: 20px 0;
      font-size: 18px;
    }

    .description-wrapper {
      align-items: center;
      display: grid;
      grid-template-columns: 1fr 170px;
      margin-top: -20px;
    }

    .description {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
    }
  }
}

.v-expansion-panel-header {
  padding: 0px;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.expand-all-btn {
  cursor: pointer;
  color: #21a7e0;
  .v-icon {
    color: #21a7e0;
  }
}
::v-deep .badge {
  padding-left: 5px;
  justify-content: flex-end;
  .v-badge__badge {
    color: #4d5358 !important;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
  }
}

.expansion-panels {
  .section-name {
    margin: 0 !important;
    margin-left: -10px;
  }

  ::v-deep .title-text::after {
    content: '' !important;
    display: none !important;
  }

  .mosaic-view-col {
    display: flex;
  }

  .slide-divider {
    padding: 10px 0;

    &.mosaic-view {
      padding: 0 10px;
      display: flex;
      align-items: center;

      .inner-divider {
        height: 90%;
        width: 0;
        display: flex;
        align-items: stretch;
      }

      .add-slides {
        margin: auto 0;
        position: relative !important;
      }
    }
    .inner-divider {
      display: flex;
      border: 1px solid transparent;
      position: relative;
      justify-content: center;
      align-items: center;

      .add-slides {
        position: absolute;
        z-index: 1;
        display: none;
      }
    }

    &:hover {
      .inner-divider {
        border: 1px solid #ccc;
      }
      .add-slides {
        display: block;
      }
    }
  }
}
</style>
