var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-actions-row"},_vm._l((_vm.actions),function(action,index){return _c('div',{key:("item-" + index)},[(_vm.isPrezentSourceBestPractice && _vm.showTooltip)?_c('v-tooltip',{attrs:{"top":"","content-class":"tooltip-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{key:("button-" + index),class:action.id === _vm.selectedMainAction.id &&
              _vm.selectedMainAction.id !== 'editSlide'
                ? 'active'
                : '',attrs:{"rounded":"","color":"primary","text":"","data-pendo-id":action.dataPendoId ? action.dataPendoId : null,"name--auto":("" + (action.id) + (action.id === _vm.selectedMainAction.id ? '--active' : '')),"disabled":action.disabled},on:{"click":function () { return _vm.handleIconClick(action); }}},[_c('div',{staticClass:"main-action-content"},[(action.isCustomIcon)?_c(_vm.getIcon(action),{tag:"component",attrs:{"iconWidth":24,"iconHeight":24,"iconColor":action.disabled ? 'rgba(0, 0, 0, 0.26)' : '#21a7e0',"iconStrokeWidth":0.5}}):_vm._e(),_c('v-icon',{staticClass:"main-action-icon",attrs:{"dark":"","size":"20"}},[_vm._v(" "+_vm._s(action.icon)+" ")]),_c('span',[_vm._v(_vm._s(action.labelText || _vm.$t(action.label)))])],1)])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(action.tooltipText))])]):_c('v-btn',{key:("button-" + index),class:action.id === _vm.selectedMainAction.id &&
        _vm.selectedMainAction.id !== 'editSlide'
          ? 'active'
          : '',attrs:{"rounded":"","color":"primary","text":"","data-pendo-id":action.dataPendoId ? action.dataPendoId : null,"name--auto":("" + (action.id) + (action.id === _vm.selectedMainAction.id ? '--active' : '')),"disabled":action.disabled},on:{"click":function () { return _vm.handleIconClick(action); }}},[_c('div',{staticClass:"main-action-content"},[(action.isCustomIcon)?_c(_vm.getIcon(action),{tag:"component",attrs:{"iconWidth":24,"iconHeight":24,"iconColor":action.disabled ? 'rgba(0, 0, 0, 0.26)' : '#21a7e0',"iconStrokeWidth":0.5}}):_vm._e(),_c('v-icon',{staticClass:"main-action-icon",attrs:{"dark":"","size":"20"}},[_vm._v(" "+_vm._s(action.icon)+" ")]),_c('span',[_vm._v(_vm._s(action.labelText || _vm.$t(action.label)))])],1)]),(
        action.id === _vm.selectedMainAction.id &&
        _vm.selectedMainAction.id === 'editSlide'
      )?_c('div',{key:("divider-" + index),staticClass:"divider"}):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }