<template>
  <div class="slides-mosaic-view col-12">
    <div
      v-if="prezentationData.sections.list.length >= 5 && !isViewAccess"
      class="error-message"
    >
      {{ $t('build.step3.sectionsLimitText') }}
    </div>
    <div
      class="col-2 pa-0"
      v-if="
        !isViewAccess &&
        !isTemplateRemoved(themes) &&
        prezentationData.sections.list.length < 5
      "
    >
      <SectionHeader
        :onNameChange="(newValue) => handleAddSection(newValue)"
        :origin="origin"
        :isAddNewSection="true"
        v-if="
          !isViewAccess &&
          !isTemplateRemoved(themes) &&
          prezentationData.sections.list.length < 5 &&
          !isOpPrezentation &&
          !isPartialAccess(currentUser)
        "
      />
    </div>
    <div
      v-if="prezentationData.sections.list.length"
      @click="handleExpandToggle"
      class="expand-all-btn"
    >
      <v-icon id="expand-collapse--auto">
        {{ !isExpanded ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
      </v-icon>
      {{
        !isExpanded
          ? $t('build.step3.expandAllText')
          : $t('build.step3.collapseAllText')
      }}
    </div>
    <v-expansion-panels
      v-if="prezentationData.sections.list.length"
      v-model="expandedList"
      accordion
      flat
      multiple
      class="expansion-panels"
    >
      <draggable
        :class="`dragArea sections-drag expansion-panels v-item-group theme--light v-expansion-panels v-expansion-panels--accordion v-expansion-panels--flat`"
        :list="prezentationData.sections.list"
        group="sections"
        v-bind="dragOptions"
        draggable=".expansion-panel"
        handle=".section-drag-icon"
        @start="handleSectionDragStart"
        @end="hadleSectionDragEnd"
        @add="hadleSectionDragEnd"
      >
        <v-expansion-panel
          v-for="(section, sectionIndex) in prezentationData.sections.list"
          :key="`${section.sectionName}-${sectionIndex}`"
          class="expansion-panel"
        >
          <v-expansion-panel-header>
            <div
              @click.stop
              class="header-drag-icon"
              v-if="
                !isViewAccess && !isTemplateRemoved(themes) && !isOpPrezentation
              "
              :class="`section-${sectionIndex}-dragarea--auto`"
            >
              <SectionHeader
                :data="section"
                :onNameChange="
                  (newValue) => onSectionNameChange(sectionIndex, newValue)
                "
                :origin="origin"
                :count="
                  section.slides.filter((i) => i.assetId).length.toString()
                "
                :onDeleteClick="
                  () => handleDeleteSection(sectionIndex, section)
                "
                :onEdit="(val) => (isInEditMode = val)"
              />
            </div>
            <div class="section-header" v-else>
              {{ section.sectionName }}
              <v-badge
                color="#FFE1B7"
                class="badge"
                :content="
                  section.slides.filter((i) => i.assetId).length.toString()
                "
                inline
              ></v-badge>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              v-if="
                section.slides.filter((i) => i.assetId).length === 0 &&
                !isPartialAccess(currentUser)
              "
              :key="`divider-${sectionIndex}-0-top`"
              class="slide-divider no-slides-divider"
            >
              <div
                class="inner-divider"
                v-if="
                  !isViewAccess &&
                  !isPartialAccess(currentUser) &&
                  !isOpPrezentation
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="add-slides"
                      fab
                      dark
                      small
                      color="primary"
                      @click="
                        () =>
                          addSlidesInBetween(sectionIndex, 0, 'Between slides')
                      "
                    >
                      <v-icon dark> mdi-plus </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('build.step3.addSlideText') }}</span>
                </v-tooltip>
              </div>
            </div>
            <draggable
              :class="`dragArea list-group row sectionIndex-${sectionIndex}`"
              :list="section.slides"
              group="slides"
              v-bind="dragOptions"
              draggable=".dragable-slide"
              handle=".drag-icon"
              @start="hadleSlideDragStart"
              @end="hadleSlideDragEnd"
              @add="hadleSlideDragEnd"
            >
              <div
                class="slides-container dragable-slide"
                v-for="(slide, slideIndex) in section.slides.filter(
                  (i) => i.assetId,
                )"
                :key="`${sectionIndex}-${slideIndex}`"
              >
                <div
                  :key="`divider-${sectionIndex}-${slideIndex}-top`"
                  class="slide-divider mosaic-view"
                >
                  <div
                    class="inner-divider"
                    v-if="
                      !isViewAccess &&
                      !isTemplateRemoved(themes) &&
                      !isPartialAccess(currentUser)
                    "
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="add-slides"
                          fab
                          dark
                          small
                          color="primary"
                          @click="
                            () =>
                              addSlidesInBetween(
                                sectionIndex,
                                slideIndex,
                                'Between slides',
                              )
                          "
                        >
                          <v-icon dark> mdi-plus </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('build.step3.addSlideText') }}</span>
                    </v-tooltip>
                  </div>
                </div>
                <div class="mosaic-slide-container">
                  <SlideMosaicViewImage
                    :isSelected="
                      slideIndex === selectedSlideIndex &&
                      selectedSectionIndex === sectionIndex
                    "
                    :key="`${sectionIndex}-${slideIndex}`"
                    :serialNumber="getSerialNumber(sectionIndex, slideIndex)"
                    :slideIndex="slideIndex"
                    :sectionIndex="sectionIndex"
                    :onClick="
                      () =>
                        handleRightSideImageClick(
                          slide,
                          slideIndex,
                          sectionIndex,
                        )
                    "
                    :slide="slide"
                    :onDuplicate="
                      () =>
                        handleLeftSideDuplicate(slide, slideIndex, sectionIndex)
                    "
                    :onDelete="
                      () => handleLeftSideDelete(slideIndex, sectionIndex)
                    "
                    :isViewAccess="isViewAccess"
                    :isTemplateRemoved="isTemplateRemoved(themes)"
                  ></SlideMosaicViewImage>
                </div>
                <div
                  v-if="
                    slideIndex ===
                    section.slides.filter((i) => i.assetId).length - 1
                  "
                  :key="`divider-${sectionIndex}-${slideIndex}-bottom`"
                  class="slide-divider mosaic-view"
                >
                  <div
                    class="inner-divider"
                    v-if="
                      !isViewAccess &&
                      !isTemplateRemoved(themes) &&
                      !isPartialAccess(currentUser)
                    "
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="add-slides"
                          fab
                          dark
                          small
                          color="primary"
                          @click="
                            () =>
                              addSlidesInBetween(
                                sectionIndex,
                                slideIndex + 1,
                                'Between slides',
                              )
                          "
                        >
                          <v-icon dark> mdi-plus </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('build.step3.addSlideText') }}</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </draggable>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </draggable>
    </v-expansion-panels>
    <ConfirmationDialog
      :show="deleteSlideConfirmPopup"
      :on-primary-click="handleSideDeleteCancel"
      :on-secondary-click="handleSideDeleteConfirm"
      :on-close="handleSideDeleteCancel"
      :primary-btn-text="$t('common.cancel')"
      :secondary-btn-text="$t('common.delete')"
      :title="$t('build.step3.deleteSlide')"
      :text="$t('build.step3.deleteSlideText')"
    />
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import { mapState, mapGetters, mapActions } from 'vuex';
import { getCopyDetails } from '../../../../utils/api-helper';
import ConfirmationDialog from '../../../common/ConfirmationDialog.vue';
import SectionHeader from '../../NewPrezentation/BuildPrezentation/Storyline/SectionHeader.vue';
import SlideMosaicViewImage from '../../../common/SharedComponents/SlideMosaicViewImage.vue';
import { trackBuildEvents } from '../../../common/Analytics/BuildEvents';
import {
  BUILD,
  TD_AUD,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_TEMPLATE,
  TD_PREZENTATIONAUTHOR,
  TD_NUMBEROFSLIDES,
  TD_PLACEMENT,
  getSlideCount,
} from '../../../common/Analytics/MatomoTrackingDataHelper';
import { trackPrezentationEvents } from '../../../common/Analytics/PrezentationEvents';

export default {
  components: {
    draggable,
    SectionHeader,
    SlideMosaicViewImage,
    ConfirmationDialog,
  },
  props: {
    origin: {
      type: String,
      default: 'Prezentation',
      required: false,
    },
    rightPanelOpen: {
      type: Function,
      default: () => {},
    },
    updatePrezentation: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isExpanded: true,
      deleteSlideConfirmPopup: false,
      expandedList: [],
      selectedSlideIndex: null,
      selectedSectionIndex: null,
      hitTheApi: false,
      sectionError: '',
    };
  },
  watch: {
    expandedList() {
      if (this.isExpanded !== !!this.expandedList.length)
        this.isExpanded = !!this.expandedList.length;
    },
  },
  computed: {
    ...mapState('users', ['currentUser', 'themes']),
    ...mapState('prezentationDetails', ['prezentationData']),
    ...mapGetters('prezentationDetails', [
      'isViewAccess',
      'isTemplateRemoved',
      'isOpPrezentation',
      'isPartialAccess',
    ]),
    dragOptions() {
      return {
        animation: 900,
        ghostClass: 'ghost-list',
        disabled: false,
      };
    },
  },
  methods: {
    ...mapActions('prezentationDetails', [
      'setSelectedMainAction',
      'setInListViewMode',
      'setSelectedSlideData',
      'setFromMosaicView',
      'updatePrezentationData',
    ]),
    handleRightSideImageClick(slide, selectedSlideIndex, selectedSectionIndex) {
      this.setFromMosaicView(true);
      this.setSelectedSlideData({
        data: slide,
        sectionIndex: selectedSectionIndex,
        slideIndex: selectedSlideIndex,
      });
      this.setInListViewMode(true);
    },
    getSerialNumber(sectionIndex, slideIndex) {
      const { list } = this.prezentationData.sections;
      let count = 0;
      for (let index = 0; index < sectionIndex; index++) {
        count += list[index].slides.filter((i) => i.assetId).length;
      }

      count += slideIndex + 1;

      return count;
    },
    handleExpandToggle() {
      this.isExpanded = !this.isExpanded;
      if (this.isExpanded) {
        this.expandedList = [];
        this.prezentationData.sections.list.forEach((section, sectionIndex) => {
          this.expandedList.push(sectionIndex);
        });
      } else {
        this.expandedList = [];
      }
    },
    handleLeftSideDuplicate(slide, slideIndex, sectionIndex) {
      this.prezentationData.sections.list[sectionIndex].slides.splice(
        slideIndex,
        0,
        slide,
      );

      this.updatePrezentation(this.prezentationData);
    },
    handleLeftSideDelete(slideIndex, sectionIndex, isFromSlideDetail) {
      this.tempSlideIndexData = { slideIndex, sectionIndex };
      this.deleteSlideConfirmPopup = true;
      this.isDeleteFromSlideDetail = !!isFromSlideDetail;
    },
    handleSideDeleteCancel() {
      this.deleteSlideConfirmPopup = false;
      this.tempSlideIndexData = null;
    },
    handleSideDeleteConfirm() {
      if (this.tempSlideIndexData) {
        const { slideIndex, sectionIndex } = this.tempSlideIndexData;
        this.prezentationData.sections.list[sectionIndex].slides.splice(
          slideIndex,
          1,
        );

        const { list } = this.prezentationData.sections;

        if (!list[sectionIndex]?.slides[slideIndex]) {
          let indexObj = {
            seIndex: 0,
            slIndex: 0,
          };
          list.forEach((section, seIndex) => {
            if (section.slides.length) {
              section.slides.forEach((slide, slIndex) => {
                if (
                  indexObj.seIndex <= sectionIndex &&
                  indexObj.slIndex <= slideIndex
                ) {
                  indexObj = { seIndex, slIndex };
                }
                if (
                  indexObj.seIndex >= sectionIndex &&
                  indexObj.slIndex >= slideIndex &&
                  !indexObj.found
                ) {
                  indexObj = { seIndex, slIndex, found: true };
                }
              });
            }
          });

          this.selectedSectionIndex = indexObj.seIndex;
          this.selectedSlideIndex = indexObj.slIndex;
        } else {
          this.selectedSectionIndex = sectionIndex;
          this.selectedSlideIndex = slideIndex;
        }

        this.updatePrezentation(this.prezentationData);
      }
      this.deleteSlideConfirmPopup = false;
      if (this.isDeleteFromSlideDetail) this.closeSlideDetail();
    },
    addSlidesInBetween(sectionIndex, slideIndex, origin) {
      const otherData = {
        [TD_AUD]: this.prezentationData.audienceID,
        [TD_PREZNAME]: this.prezentationData.name,
        [TD_PREZTYPE]: this.prezentationData.type,
        [TD_TEMPLATE]: this.prezentationData.theme,
        [TD_PREZENTATIONAUTHOR]: this.prezentationData?.author,
        [TD_NUMBEROFSLIDES]: getSlideCount(this.prezentationData),
        [TD_PLACEMENT]: origin,
      };
      if (this.origin.toLowerCase() === BUILD) {
        trackBuildEvents.buildAddSlideClick(this.currentUser, otherData);
      } else {
        trackPrezentationEvents.prezentationAddSlideClick(
          this.currentUser,
          this.prezentationData,
          otherData,
        );
      }
      this.setSelectedMainAction({
        id: 'addSlide',
        label: 'build.step3.addSlideText',
        tooltip: 'build.step3.addSlideFromGallery',
        icon: 'mdi-plus',
        isCustomIcon: false,
      });
      if (this.rightPanelOpen) {
        this.rightPanelOpen();
      }
      setTimeout(() => {
        this.$root.$emit(
          'addSlidesInBetween',
          sectionIndex,
          slideIndex,
          origin,
        );
      }, 1);
    },
    hadleSlideDragStart() {
      this.tempExpandedList = [...this.expandedList];
      this.expandedList = [];

      this.prezentationData.sections.list.forEach((section, sectionIndex) => {
        this.expandedList.push(sectionIndex);
      });
    },
    hadleSlideDragEnd(e) {
      if (e.from.className === e.to.className) {
        this.selectedSlideIndex = e.newIndex || 0;
      } else {
        let sectionIndex = 0;
        e.to?.classList?.forEach((c) => {
          if (c.indexOf('sectionIndex-') !== -1) {
            const [, index] = c.split('-');
            sectionIndex = Number(index);
          }
        });
        this.selectedSectionIndex = sectionIndex;
        this.selectedSlideIndex = e.newIndex || 0;
      }
      const payload = this.handlePayload(
        this.selectedSectionIndex,
        this.selectedSlideIndex,
        'single slide',
      );
      if (this.hitTheApi && e.from.classList[3] === 'from-gallery') {
        getCopyDetails(payload)
          .then((resp) => {
            const slidesList = {};
            resp.data?.slides.forEach((slide) => {
              slidesList[slide.uniqueID] = slide;
            });
            const presentation = JSON.parse(
              JSON.stringify(this.prezentationData),
            );
            presentation.s3dirpath = resp.data?.s3dirpath;

            if (this.prezentationData.sections?.list?.length) {
              presentation.sections.list =
                this.prezentationData?.sections.list.map((section) => {
                  section.slides = section.slides.map((slide) => {
                    // console.log(slide);

                    if (slidesList[slide.uniqueID]) {
                      return {
                        ...slide,
                        ...slidesList[slide.uniqueID],
                      };
                    }
                    return slide;
                  });
                  return section;
                });
              this.updatePrezentation(presentation);
            }
          })
          .catch((err) => console.log(err));
        this.hitTheApi = false;
      } else {
        this.updatePrezentation(this.prezentationData);
      }
      this.prezentationData.sections.list = JSON.parse(
        JSON.stringify(this.prezentationData.sections.list),
      );
    },
    handleSectionDragStart() {
      this.tempExpandedList = [...this.expandedList];
      this.expandedList = [];
    },
    hadleSectionDragEnd(e) {
      if (this.tempExpandedList) {
        this.expandedList = [...this.tempExpandedList];
      }

      this.prezentationData.sections.list = JSON.parse(
        JSON.stringify(this.prezentationData.sections.list),
      );
      this.selectedSlideIndex = 0;
      this.selectedSectionIndex = e.newIndex;
      const payload = this.handlePayload(
        this.selectedSectionIndex,
        null,
        'Section',
      );
      if (this.hitTheApi && e.from.classList[3] === 'from-gallery') {
        getCopyDetails(payload)
          .then((resp) => {
            const slidesList = {};
            resp.data?.slides.forEach((slide) => {
              slidesList[slide.uniqueID] = slide;
            });
            const presentation = JSON.parse(
              JSON.stringify(this.prezentationData),
            );

            if (this.prezentationData.sections?.list?.length) {
              presentation.sections.list =
                this.prezentationData?.sections.list.map((section) => {
                  section.slides = section.slides.map((slide) => {
                    // console.log(slide);

                    if (slidesList[slide.uniqueID]) {
                      return {
                        ...slide,
                        ...slidesList[slide.uniqueID],
                      };
                    }
                    return slide;
                  });
                  return section;
                });
              this.updatePrezentation(presentation);
            }
          })
          .catch((err) => console.log(err));
        this.hitTheApi = false;
      } else {
        this.updatePrezentation(this.prezentationData);
      }
      if (
        this.prezentationData.sections.list[this.selectedSectionIndex]?.slides
          .length === 0
      ) {
        if (this.selectedSectionIndex === 0) {
          this.selectedSectionIndex = 1;
        } else {
          this.selectedSectionIndex -= 1;
        }
      }

      this.expandedList.push(this.selectedSectionIndex);
    },
    handlePayload(sectionIndex, slideIndex, category) {
      const temp = {
        s3dirpath: this.prezentationData?.s3dirpath || null,
        prezentationID: this.prezentationData.id,
        slides: [],
      };

      if (
        category === 'single slide' &&
        this.haveTheFlag(
          this.prezentationData.sections.list[sectionIndex].slides[slideIndex],
        )
      ) {
        this.hitTheApi = true;
        const {
          assetId,
          filename,
          uniqueID,
          isSynthesis,
          isComply,
          isGenerated,
          isAnonymized,
          isUserUploaded,
          isRedesign,
        } =
          this.prezentationData.sections.list[sectionIndex].slides[slideIndex];
        temp.slides[0] = {
          assetId,
          filename,
          uniqueID,
          ...(isSynthesis ? { isSynthesis } : {}),
          ...(isComply ? { isComply } : {}),
          ...(isGenerated ? { isGenerated } : {}),
          ...(isAnonymized ? { isAnonymized } : {}),
          ...(isUserUploaded ? { isUserUploaded } : {}),
          ...(isRedesign ? { isRedesign } : {}),
        };
      }
      if (category === 'Section') {
        const sections =
          this.prezentationData.sections.list[sectionIndex].slides;
        sections.forEach((item) => {
          if (this.haveTheFlag(item)) {
            const {
              assetId,
              filename,
              uniqueID,
              isSynthesis,
              isComply,
              isGenerated,
              isAnonymized,
              isUserUploaded,
              isRedesign,
            } = item;
            const object = {
              assetId,
              filename,
              uniqueID,
              ...(isSynthesis ? { isSynthesis } : {}),
              ...(isComply ? { isComply } : {}),
              ...(isGenerated ? { isGenerated } : {}),
              ...(isAnonymized ? { isAnonymized } : {}),
              ...(isUserUploaded ? { isUserUploaded } : {}),
              ...(isRedesign ? { isRedesign } : {}),
            };
            temp.slides.push(object);
          }
        });
        if (temp.slides.length > 0) {
          this.hitTheApi = true;
        }
      }
      return temp;
    },
    haveTheFlag(Obj) {
      const keysList = [
        'isSynthesis',
        'isComply',
        'isGenerated',
        'isAnonymized',
        'isUserUploaded',
        'isRedesign',
      ];
      for (const key in Obj) {
        if (keysList.includes(key) && Obj[key]) {
          return true;
        }
      }
      return false;
    },
    onSectionNameChange(sectionIndex, newValue) {
      this.prezentationData.sections.list[sectionIndex].sectionName = newValue;
      const presentation = JSON.parse(JSON.stringify(this.prezentationData));
      this.updatePrezentation(presentation);
    },
    handleDeleteSection(sectionIndex) {
      const isSelectedSlideFromDeletedSection =
        this.selectedSectionIndex === sectionIndex;
      if (this.prezentationData.sections.list.length >= 5) {
        this.sectionError = 'sections error here....';
      } else {
        this.sectionError = '';
      }
      this.prezentationData.sections.list.splice(sectionIndex, 1);
      let isSlidesPresent = false;

      this.prezentationData.sections.list.forEach((item, index) => {
        if (item.slides.length) {
          isSlidesPresent = true;
          if (isSelectedSlideFromDeletedSection) {
            if (index < sectionIndex) {
              this.selectedSectionIndex = index;
              this.selectedSlideIndex = item.slides.length - 1;
            }
            if (index >= sectionIndex && !this.selectedSlideData) {
              this.selectedSectionIndex = index;
              this.selectedSlideIndex = 0;
              // eslint-disable-next-line prefer-destructuring
              this.selectedSlideData = item.slides[0];
            }
          }
        }
      });
      if (
        !isSelectedSlideFromDeletedSection &&
        sectionIndex < this.selectedSectionIndex
      ) {
        this.selectedSectionIndex =
          this.selectedSectionIndex > 0
            ? this.selectedSectionIndex - 1
            : this.selectedSectionIndex;
      }
      if (
        !this.selectedSlideData &&
        this.selectedSectionIndex !== null &&
        this.prezentationData.sections.list.length
      ) {
        this.selectedSlideData =
          this.prezentationData.sections?.list[
            this.selectedSectionIndex
          ].slides[this.selectedSlideIndex];
      }

      if (!isSlidesPresent) {
        this.selectedSlideData = null;
        this.selectedSectionIndex = null;
        this.selectedSlideIndex = null;
      }
      this.setSelectedSlideData({
        data: this.selectedSlideData,
        sectionIndex: this.selectedSectionIndex,
        slideIndex: this.selectedSlideIndex,
      });
      const presentation = JSON.parse(JSON.stringify(this.prezentationData));
      this.updatePrezentation(presentation);
    },
    handleAddSection(newName) {
      if (newName) {
        if (this.prezentationData.sections.list.length >= 5) {
          this.sectionError = 'sections error here....';
        } else {
          this.sectionError = '';
          this.selectedSectionIndex += 1;
          this.prezentationData.sections.list.unshift({
            sectionName: newName,
            slides: [],
          });
          const slideData =
            this.prezentationData.sections?.list[this.selectedSectionIndex]
              ?.slides[this.selectedSlideIndex];

          this.selectedSlideData = slideData;
          this.setSelectedSlideData({
            data: slideData,
            sectionIndex: this.selectedSectionIndex,
            slideIndex: this.selectedSlideIndex,
          });

          this.updatePrezentationData(
            JSON.parse(JSON.stringify(this.prezentationData)),
          );
        }
      }
    },
  },
  mounted() {
    this.expandedList = [];
    this.prezentationData.sections.list.forEach((section, sectionIndex) => {
      this.expandedList.push(sectionIndex);
    });
    this.selectedSlideIndex = 0;
    this.selectedSectionIndex = 0;
  },
};
</script>

<style lang="scss" scoped>
.slides-mosaic-view {
  transition: width 0.3s, visibility 0.3s, opacity 0.3s ease-out;
  padding-left: 0;
  // max-width: 95%;
  height: calc(100vh - 250px);
  overflow-y: auto;
  overflow-x: hidden;
}

.slides-mosaic-view {
  .section-name {
    width: fit-content !important;
  }
  .error-message {
    color: #d32f2f;
    margin: 0 0 10px 4px;
  }

  ::v-deep .badge {
    padding-left: 5px;
    justify-content: flex-end;
    .v-badge__badge {
      color: #4d5358 !important;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.5px;
    }
  }

  .v-expansion-panel-header {
    padding: 0px;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  ::v-deep .v-expansion-panel-header__icon {
    margin: 0px;
  }

  ::v-deep .v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
    flex: none;
  }

  .list-group {
    align-items: flex-start !important;
    padding: 8px !important;
  }

  .slides-container {
    display: flex;
  }

  .no-slides-divider {
    width: 330px;
    margin-left: 25px;
  }
}

.mosaic-slide-container {
  width: 350px;
  max-width: 380px;
  min-width: 200px;
}

.expand-all-btn {
  cursor: pointer;
  color: #21a7e0;
  .v-icon {
    color: #21a7e0;
  }
}

.expansion-panels {
  z-index: 0;

  .section-name {
    margin: 0 !important;
    margin-left: -10px;
  }

  .section-header {
    padding-left: 4px;
  }

  ::v-deep .title-text::after {
    content: '' !important;
    display: none !important;
  }

  .mosaic-view-col {
    display: flex;
  }

  .header-drag-icon {
    width: 100%;
  }

  .slide-divider {
    padding: 10px 0;

    &.mosaic-view {
      padding: 0 10px;
      display: flex;
      align-items: center;

      .inner-divider {
        height: 90%;
        width: 0;
        display: flex;
        align-items: stretch;
      }

      .add-slides {
        margin: auto 0;
        position: relative !important;
      }
    }
    .inner-divider {
      display: flex;
      border: 1px solid transparent;
      position: relative;
      justify-content: center;
      align-items: center;

      .add-slides {
        position: absolute;
        z-index: 1;
        display: none;
      }
    }

    &:hover {
      .inner-divider {
        border: 1px solid #ccc;
      }
      .add-slides {
        display: block;
      }
    }
  }
}
</style>
