<template>
  <div class="comply-score-main">
    <template v-if="!getDeckFullComplianceInfo && getComplianceScore !== 100">
      <div class="suggest-block-score-section">
        <v-progress-circular
          class="score-circular"
          :rotate="-90"
          :size="150"
          :width="20"
          :color="complianceColor"
          :value="getComplianceScore"
          data-pendo-id="comply-status-score-circular"
        >
          <span class="score">{{ getComplianceScore }}%</span>
        </v-progress-circular>
        <div class="score-summary">
          <p class="mb-0 text-center">
            Your prezentation is
            <span class="score-percentage">{{ getComplianceScore }}%</span>
            compliant with brand guidelines for your selected template.
          </p>
        </div>
      </div>
      <div class="cta-block">
        <v-btn
          class="app-button"
          color="primary"
          rounded
          data-pendo-id="comply-make-hundred-compliant-button"
          name--auto="make_100_percent_compliant"
          @click="applyDeckCompliance"
        >
          Make 100% compliant
        </v-btn>
        <v-checkbox
          v-if="isReplaceBrandImageEnabled && allowReplaceImageOverall"
          data-pendo-id="include-image-conversion"
          class="include-image-conversion-check-box"
          v-model="includeImageConversion"
          label="Include image conversions"
          hide-details
        ></v-checkbox>
        <v-btn
          text
          rounded
          class="app-button"
          color="primary"
          data-pendo-id="comply-review-details-button"
          name--auto="comply_score_review_details"
          @click="onReviewDetailsCta"
        >
          Review details
        </v-btn>
      </div>
    </template>
    <v-row v-else>
      <v-col>
        <div class="complied-message">
          <v-img
            max-height="45%"
            src="/assets/img/comply/brand-compliance-achieved.svg"
          ></v-img>
          <div class="complied-message__wrapper">
            <div>
              Congratulations! Your prezentation is
              <span class="score-percentage"> 100% </span>
              compliant with the brand guidelines for your selected template.
            </div>
          </div>
          <div class="complied-message__actions">
            <v-btn
              text
              rounded
              class="app-button"
              color="primary"
              @click="handleSelectDifferentTemplate"
            >
              Select a different template
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { trackPrezentationEvents } from '../../../../common/Analytics/PrezentationEvents';
import { trackComplyEvents } from '../../../../common/Analytics/ComplyEvents';
import {
  MY_PREZENTATION,
  TD_AUD,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_TEMPLATE,
  TD_PREZENTATION_ORIGIN,
  TD_PREZENTATION_COMPANY_SOURCE,
  TD_PREZENTATION_LIBRARY,
  TD_CROSS_FEATURE,
  TD_NUMBEROFSLIDES,
  BUILD,
  TD_TIME_SPENT_IN_SECS,
} from '../../../../common/Analytics/MatomoTrackingDataHelper';

export default {
  props: {
    reviewDetails: {
      type: Function,
      default: () => false,
    },
    fullDeckChanges: {
      type: Function,
      default: () => false,
    },
    updateIncludeImageConversion: {
      type: Function,
      default: () => false,
    },
    changeStep: {
      type: Function,
      default: () => false,
    },
    origin: {
      type: String,
      required: false,
      default: MY_PREZENTATION,
    },
  },
  data() {
    return {
      includeImageConversion: false,
    };
  },
  watch: {
    includeImageConversion(val) {
      this.updateIncludeImageConversion(val);
    },
  },
  computed: {
    ...mapState('prezentationDetails', ['complyDetails', 'prezentationData']),
    ...mapState('users', ['currentUser']),
    ...mapGetters('users', [
      'isReplaceBrandImageEnabled',
      'allowReplaceImageOverall',
    ]),

    getDeckFullComplianceInfo() {
      return this.complyDetails?.compliance_score === 100;
    },
    getComplianceScore() {
      return this.complyDetails?.compliance_score;
    },
    getSuggestionCount() {
      return this.complyDetails?.intial_suggestion_count;
    },
    complianceColor() {
      if (this.getComplianceScore < 50) {
        return '#D32F2F'; // Red for score < 50%
      }
      if (this.getComplianceScore >= 50 && this.getComplianceScore <= 75) {
        return '#FDD835'; // Yellow for score 50% - 75%
      }
      return '#00925A'; // Green for score > 75%
    },
  },
  methods: {
    async onReviewDetailsCta() {
      const eventStartTime = new Date();
      await this.reviewDetails(this.includeImageConversion);
      let countOfSlides = 0;
      this.prezentationData.sections.list.forEach((section) => {
        countOfSlides += section.slides.length;
      });
      const otherData = {
        [TD_AUD]: this.prezentationData?.audienceID,
        [TD_PREZNAME]: this.prezentationData?.name,
        [TD_PREZTYPE]: this.prezentationData?.type,
        [TD_TEMPLATE]: this.prezentationData?.theme,
        [TD_NUMBEROFSLIDES]: countOfSlides,
        [TD_PREZENTATION_ORIGIN]:
          this.prezentationData?.prezentationSource ||
          this.prezentationData?.prezentationType,
        [TD_PREZENTATION_COMPANY_SOURCE]: this.prezentationData?.source,
        [TD_CROSS_FEATURE]:
          this.origin === 'build' ? BUILD : TD_PREZENTATION_LIBRARY,
        [TD_TIME_SPENT_IN_SECS]: (new Date() - eventStartTime) / 1000,
      };
      trackComplyEvents.complianceCheckerReviewDetails(
        this.currentUser,
        otherData,
      );
    },
    applyDeckCompliance() {
      this.fullDeckChanges();
    },
    handleSelectDifferentTemplate() {
      this.changeStep();
      const otherData = {
        [TD_AUD]: this.prezentationData?.audienceID,
        [TD_PREZNAME]: this.prezentationData.name,
        [TD_PREZTYPE]: this.prezentationData?.type,
        [TD_TEMPLATE]: this.prezentationData?.theme,
        [TD_PREZENTATION_ORIGIN]:
          this.prezentationData?.prezentationSource ||
          this.prezentationData?.prezentationType,
        [TD_PREZENTATION_COMPANY_SOURCE]: this.prezentationData?.source,
        [TD_CROSS_FEATURE]:
          this.origin === 'build' ? BUILD : TD_PREZENTATION_LIBRARY,
      };
      trackPrezentationEvents.convertTemplateSelectDifferentTemplate(
        this.currentUser,
        otherData,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.comply-score-main {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .heading-block {
    &__main {
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &__sub {
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }

  .suggest-block {
    align-self: stretch;
    &__summary-text {
      color: #212121;
      font-size: 16px;
      font-weight: 400;
      line-height: 130%; /* 20.8px */
      margin-bottom: 0;
    }
  }

  .suggest-block-score-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 164px;

    > .score-circular {
      > .v-progress-circular__info .score {
        color: #000;
        text-align: center;
        font-size: 2rem;
        font-weight: 400;
        letter-spacing: 0.14px;
      }
    }
    > .score-summary {
      color: #000;
      font-size: 20px;
      font-weight: 400;
      line-height: 130%;
    }

    .score-percentage {
      color: #21a7e0;
      font-size: 20px;
      font-weight: 700;
    }
  }

  .comply-score-main {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .complied-message {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    gap: 40px;
    justify-content: center;
    padding-top: 64px;
    &__wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 14px;
      max-width: 70%;
      text-align: center;
    }
    &__description {
      font-weight: 600;
    }
    &__actions {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    .score-percentage {
      color: #21a7e0;
      font-size: 20px;
      font-weight: 700;
    }
  }
  ::v-deep .v-input--checkbox > .v-input__control > .v-input__slot {
    label {
      color: black;
    }
  }
  .include-image-conversion-check-box {
    margin-top: 0;
    height: 37px !important;
    align-items: center;
  }

  .cta-block {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    padding: 0 38px;
    text-transform: none;
  }
  .app-button {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
    letter-spacing: normal;
  }
}
</style>
