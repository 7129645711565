<template>
  <div class="bulk-action-container v-application--is-ltr">
    <v-btn class="close-btn" icon @click="onCancel">
      <v-icon :size="16" dark v-text="`mdi-close`" />
    </v-btn>
    <div class="header">
      {{ header }}
    </div>
    <div class="subtext">
      {{ subText }}
    </div>
    <v-switch
      v-if="type === 'overnight'"
      v-model="opEnabled"
      :label="opEnabled ? $t('profile.enabled') : $t('profile.disabled')"
      color="#21A7E0"
      hide-details
    ></v-switch>
    <v-select
      dense
      v-else-if="type === 'access'"
      class="user-input"
      v-model="accessLevel"
      :items="accessLevelList"
      :placeholder="$t('profile.bulkActionModal.selectAccessLevel')"
      item-value="value"
      item-text="title"
    ></v-select>
    <v-select
      dense
      v-else
      v-model="activeState"
      class="user-input"
      :items="activeStateList"
      :placeholder="$t('profile.bulkActionModal.selectOne')"
      item-value="value"
      item-text="title"
    ></v-select>
    <div class="actions">
      <v-btn
        class="primary-btn ma-2 py-2"
        outlined
        rounded
        color="#21A7E0"
        @click="onCancel"
      >
        {{ $t('common.cancel') }}
      </v-btn>
      <v-btn
        class="secondary-btn ma-2 py-2"
        rounded
        color="#21A7E0"
        @click="update"
        :loading="loading"
        :disabled="activeState === null && !accessLevel && type !== 'overnight'"
      >
        {{ $t('common.update') }} ({{ usersLengthForText }})
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { updateBulkAction } from '../../../utils/api-helper';

export default {
  name: 'BulkActionModal',
  components: {},
  props: {
    users: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default: 'op',
    },
    updateTeam: {
      type: Function,
      default: () => {},
    },
    isUpdateAll: {
      type: Boolean,
      default: false,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    isLocalSearchActive: {
      type: Boolean,
      default: false,
    },
    searchText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      opEnabled: true,
      accessLevel: null,
      accessLevelList: [
        {
          title: 'User',
          value: 'User',
        },
        {
          title: 'Admin',
          value: 'Admin',
        },
      ],
      activeState: null,
      activeStateList: [
        {
          title: 'Enable',
          value: true,
        },
        {
          title: 'Disable',
          value: false,
        },
      ],
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    header() {
      if (this.type === 'overnight') {
        return `Access to Overnight Prezentations`;
      }
      if (this.type === 'access') {
        return 'Team member access level';
      }
      return 'Enable/Disable members';
    },
    usersLengthForText() {
      const currUser = this.currentUser.user;
      const searchQuery =
        this.searchText.length > 0 ? this.searchText.toLowerCase().trim() : '';
      const searchMatchesCurrUser =
        currUser.id.toLowerCase().trim().includes(searchQuery) ||
        currUser.fullName.toLowerCase().trim().includes(searchQuery) ||
        currUser.firstName.toLowerCase().trim().includes(searchQuery) ||
        currUser.lastName.toLowerCase().trim().includes(searchQuery);
      const diffTotal = searchMatchesCurrUser
        ? this.totalCount
        : this.totalCount + 1;
      return this.isUpdateAll ? diffTotal : this.users.length;
    },
    subText() {
      if (this.type === 'overnight') {
        return `Set access to Overnight Prezentations for ${
          this.usersLengthForText
        } selected member${this.usersLengthForText > 1 ? 's' : ''}.`;
      }
      if (this.type === 'access') {
        return `Set access level for ${
          this.usersLengthForText
        } selected member${this.usersLengthForText > 1 ? 's' : ''}.`;
      }
      return `Enable or disabled ${this.usersLengthForText} selected member${
        this.usersLengthForText > 1 ? 's' : ''
      }.`;
    },
  },
  methods: {
    onCancel() {
      this.$modal.hide('BulkActionModal');
    },
    async update() {
      this.loading = true;
      const body = {
        type: this.type,
      };
      if (this.isUpdateAll) {
        switch (this.type) {
          case 'overnight':
            body.type = 'overnight-selectall';
            body.status = this.opEnabled;
            body.filter = this.searchText;
            break;
          case 'active':
            body.type = 'active-selectall';
            body.status = this.activeState;
            body.filter = this.searchText;
            break;
          default:
            body.type = 'access-selectall';
            body.role = this.accessLevel;
            body.filter = this.searchText;
            break;
        }
      } else {
        switch (this.type) {
          case 'overnight':
            body.status = this.opEnabled;
            body.users = this.users.map((i) => ({
              id: i.email,
              active: i.status !== 'Disabled',
            }));
            break;
          case 'active':
            body.status = this.activeState;
            body.users = this.users.map((i) => ({
              id: i.email,
              active: i.status !== 'Disabled',
            }));
            break;
          default:
            body.role = this.accessLevel;
            body.users = this.users.map((i) => ({
              id: i.email,
              role: i.accessLevel,
              firstName: i.firstName,
              lastName: i.lastName,
            }));
            break;
        }
      }
      await updateBulkAction(body)
        .then((resp) => {
          this.loading = false;
          this.updateTeam(
            this.type,
            // eslint-disable-next-line no-nested-ternary
            this.type === 'overnight'
              ? this.opEnabled
              : this.type === 'access'
              ? this.accessLevel
              : this.activeState,
          );
          console.log(resp);
          this.$modal.hide('BulkActionModal');
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/app.scss', '@/scss/variables.scss';

.bulk-action-container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 24px;
  gap: 16px;

  .close-btn {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .header {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  .subtext {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */

    display: flex;
    align-items: center;

    color: #000000;
  }

  .user-input {
    width: 250px;
  }

  .primary-btn {
    display: flex;
    flex-direction: row;
    width: 119px;
    height: 31px;
    text-transform: none !important;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .secondary-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    gap: 8px;

    width: 106px;
    height: 31px;

    background: #21a7e0;
    border-radius: 25px;
    color: white;
    text-transform: none !important;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }
}
</style>
