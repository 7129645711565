<template>
  <div class="compliance-deck-suggestions-container">
    <div class="compliance-deck-suggestions" v-if="!getDeckFullComplianceInfo">
      <v-row class="slide-compliance-deck-suggestions">
        <v-col cols="8" class="score-text-col">
          <span class="score-text" data-pendo-id="comply-deck-compliance-score">
            Brand Compliance Score
          </span>
          <span class="score-percentage">{{ getComplianceScore }}%</span>
        </v-col>
        <v-col cols="4" class="progress-col">
          <v-progress-linear
            :value="getComplianceScore"
            :color="complianceColor"
            height="7"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </div>
    <template>
      <v-row v-if="!getDeckFullComplianceInfo" class="suggestions-list">
        <v-col>
          <v-list>
            <v-list-item
              v-for="(category, i) in getDeckLevelCategorySuggestions"
              :key="i"
              class="list-wrapper"
            >
              <v-list-item-content>
                <div class="suggestion-details">
                  <div class="suggestion-details__item">
                    <v-avatar
                      :color="category.avatarColor"
                      :size="16"
                    ></v-avatar>
                    <span class="suggestion-details__item-text">
                      {{ category.text }}
                    </span>
                    <v-icon :color="'#D9D9D9'">mdi-circle-small</v-icon>
                    <span class="suggestion-details__total">
                      {{ getSuggestionsCountText(category) }}
                    </span>
                  </div>

                  <v-list-item-action
                    v-if="
                      category.key !== 'template' &&
                      !isSuggestionsResolved(category)
                    "
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="applyDeckCompliance(category.key, 'accept')"
                        >
                          <v-icon color="#00925a">mdi-check</v-icon>
                        </v-btn>
                      </template>
                      <span>Accept</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="applyDeckCompliance(category.key, 'reject')"
                        >
                          <v-icon color="#d32f2f">mdi-close</v-icon>
                        </v-btn>
                      </template>
                      <span>Reject</span>
                    </v-tooltip>
                  </v-list-item-action>
                </div>
                <div
                  class="suggestion-details__description"
                  v-if="
                    !(isReplaceBrandImageEnabled && allowReplaceImageOverall) &&
                    category.key === 'images'
                  "
                >
                  No brand images available to check compliance.
                  <a class="text-link" @click="onGetInTouchclick">
                    Get in touch
                  </a>
                  to add brand images.
                </div>
                <div class="suggestion-details__description" v-else>
                  {{ category.description }}
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row v-if="getDeckFullComplianceInfo">
        <v-col>
          <div class="complied-message">
            <v-img
              max-height="45%"
              src="/assets/img/comply/brand-compliance-achieved.svg"
            ></v-img>
            <div class="complied-message__wrapper">
              <div>
                Congratulations! Your prezentation is
                <span class="score-percentage"> 100% </span>
                compliant with brand guidelines for your selected template.
              </div>
            </div>
            <div class="complied-message__actions">
              <v-btn
                text
                rounded
                class="app-button"
                color="primary"
                @click="handleSelectDifferentTemplate"
              >
                Select a different template
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </template>
    <v-row v-if="!getDeckFullComplianceInfo" class="suggestions-actions">
      <v-col class="d-flex align-baseline">
        <div class="buttons-content">
          <v-btn
            rounded
            color="primary"
            class="app-button"
            data-pendo-id="comply-make-hundred-compliant-button"
            @click="applyFullDeckCompliance"
          >
            Accept all suggestions
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { sendEmail } from '../../../../../utils/api-helper';
import GetInTouchDetail from '../../../Profile/GetInTouchDetail.vue';
import { trackPrezentationEvents } from '../../../../common/Analytics/PrezentationEvents';
import { trackComplyEvents } from '../../../../common/Analytics/ComplyEvents';
import {
  MY_PREZENTATION,
  TD_AUD,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_TEMPLATE,
  TD_NUMBEROFSLIDES,
  TD_PREZENTATION_ORIGIN,
  TD_PREZENTATION_COMPANY_SOURCE,
  TD_PREZENTATION_LIBRARY,
  TD_CROSS_FEATURE,
  TD_PLACEMENT,
  TD_COMMON_COLUMN_VALUE,
  TD_COMMON_COLUMN_NAME,
  TD_CATEGORY_REJECTED,
  TD_DECK,
  BUILD,
  TD_TIME_SPENT_IN_SECS,
} from '../../../../common/Analytics/MatomoTrackingDataHelper';

export default {
  props: {
    reviewDetails: {
      type: Function,
      default: () => false,
    },
    fullDeckChanges: {
      type: Function,
      default: () => false,
    },
    applyDeckChanges: {
      type: Function,
      default: () => false,
    },
    changeStep: {
      type: Function,
      default: () => false,
    },
    origin: {
      type: String,
      required: false,
      default: MY_PREZENTATION,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapState('prezentationDetails', ['complyDetails', 'prezentationData']),

    ...mapGetters('users', [
      'isReplaceBrandImageEnabled',
      'allowReplaceImageOverall',
    ]),

    getDeckFullComplianceInfo() {
      return this.complyDetails?.compliance_score === 100;
    },
    getComplianceScore() {
      return this.complyDetails?.compliance_score;
    },
    getSuggestionCount() {
      return this.complyDetails?.intial_suggestion_count;
    },
    complianceColor() {
      if (this.getComplianceScore < 50) {
        return '#D32F2F'; // Red for score < 50%
      }
      if (this.getComplianceScore >= 50 && this.getComplianceScore <= 75) {
        return '#FDD835'; // Yellow for score 50% - 75%
      }
      return '#00925A'; // Green for score > 75%
    },
    getDeckLevelCategorySuggestions() {
      const categoriesData = [
        {
          text: 'Colors',
          key: 'color',
          avatarColor: '#075689',
          suggestionsCount: this.complyDetails.color_suggestions_count,
          description:
            this.complyDetails.color_suggestions_count > 0
              ? `Use template colors in ${this.complyDetails.color_suggestions_count} elements across your prezentation`
              : `Your presentation follows all brand guidelines on colors`,
          isSuggestionsResolved:
            this.complyDetails.color_suggestions_count_resolved !== undefined
              ? this.complyDetails.color_suggestions_count_resolved
              : false,
        },
        {
          text: 'Font',
          key: 'font',
          avatarColor: '#AF4FB3',
          suggestionsCount: this.complyDetails.font_suggestions_count,
          description:
            this.complyDetails.font_suggestions_count > 0
              ? ` Apply template font styles to ${this.complyDetails.font_suggestions_count} elements across your prezentation`
              : `Your presentation follows all brand guidelines on font styles`,
          isSuggestionsResolved:
            this.complyDetails.font_suggestions_count_resolved !== undefined
              ? this.complyDetails.font_suggestions_count_resolved
              : false,
        },
        {
          text: 'Images',
          key: 'images',
          avatarColor: '#5C00D8',
          suggestionsCount: this.complyDetails.image_suggestions_count,
          description:
            this.complyDetails.image_suggestions_count > 0
              ? `Utilize brand images for ${this.complyDetails.image_suggestions_count} slides in your prezentation`
              : `Your presentation follows all brand guidelines on brand images`,
          isSuggestionsResolved:
            this.complyDetails.image_suggestions_count_resolved !== undefined
              ? this.complyDetails.image_suggestions_count_resolved
              : false,
        },
        {
          text: 'Template',
          key: 'template',
          avatarColor: '#EB7224',
          description:
            'Your prezentation follows all brand guidelines on master template',
        },
        // { text: 'Icons', key: 'icons', avatarColor: '#2AA08A' },
        // { text: 'Template', key: 'template', avatarColor: '#EB7224' },
      ];

      return categoriesData.map((cat) => ({
        text: cat.text,
        key: cat.key,
        avatarColor: cat.avatarColor,
        type: 'format',
        suggestionsCount: cat.suggestionsCount,
        description: cat.description,
        isSuggestionsResolved: cat.isSuggestionsResolved,
      }));
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      if (!string) return '';
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    getSuggestionsCountText(category) {
      if (category.isSuggestionsResolved) return 'All suggestions resolved';
      if (category.key === 'template') {
        return 'All suggestions resolved';
      }
      if (category.suggestionsCount === 0) return 'No suggestions available';
      return category.suggestionsCount === 1
        ? `${category.suggestionsCount} suggestion unresolved`
        : `${category.suggestionsCount} suggestions unresolved`;
    },

    handleSelectDifferentTemplate() {
      this.changeStep();
      const otherData = {
        [TD_AUD]: this.prezentationData?.audienceID,
        [TD_PREZNAME]: this.prezentationData.name,
        [TD_PREZTYPE]: this.prezentationData?.type,
        [TD_TEMPLATE]: this.prezentationData?.theme,
        [TD_PREZENTATION_ORIGIN]:
          this.prezentationData?.prezentationSource ||
          this.prezentationData?.prezentationType,
        [TD_PREZENTATION_COMPANY_SOURCE]: this.prezentationData?.source,
        [TD_CROSS_FEATURE]:
          this.origin === 'build' ? BUILD : TD_PREZENTATION_LIBRARY,
      };
      trackPrezentationEvents.convertTemplateSelectDifferentTemplate(
        this.currentUser,
        otherData,
      );
    },
    isSuggestionsResolved(category) {
      return category.suggestionsCount === 0;
    },

    onGetInTouchclick() {
      try {
        const userName = `${this.currentUser.user.firstName} ${this.currentUser.user.lastName}`;

        const params = {
          type: 'brand-image-request',
          meta: {
            // message: mBody,
            requester: this.currentUser.user.firstName,
            // subject: `${userName} has expressed interest in adding brand images`,
            requesterFullName: userName,
            requesterID: this.currentUser.user.id,
          },
        };
        sendEmail(params)
          .then((response) => console.log(response))
          .catch((err) => console.log(err));
      } catch (err) {
        console.error(err);
      }

      this.$modal.show(
        GetInTouchDetail,
        {
          title: 'prezentation.thankForYourInterestInBrandImages',
        },
        {
          name: 'GetInTouchDetail',
          width: '564',
          height: '507px',
        },
      );
    },
    async applyDeckCompliance(categorySelected, action = 'accept') {
      const eventStartTime = new Date();
      await this.applyDeckChanges(categorySelected, action);
      let countOfSlides = 0;
      this.prezentationData.sections.list.forEach((section) => {
        countOfSlides += section.slides.length;
      });
      if (action === 'reject') {
        const otherData = {
          [TD_AUD]: this.prezentationData?.audienceID,
          [TD_PREZNAME]: this.prezentationData.name,
          [TD_PREZTYPE]: this.prezentationData?.type,
          [TD_TEMPLATE]: this.prezentationData?.theme,
          [TD_NUMBEROFSLIDES]: countOfSlides,
          [TD_PREZENTATION_ORIGIN]:
            this.prezentationData?.prezentationSource ||
            this.prezentationData?.prezentationType,
          [TD_PREZENTATION_COMPANY_SOURCE]: this.prezentationData?.source,
          [TD_CROSS_FEATURE]:
            this.origin === 'build' ? BUILD : TD_PREZENTATION_LIBRARY,
          [TD_PLACEMENT]: TD_DECK,
          [TD_COMMON_COLUMN_NAME]: TD_CATEGORY_REJECTED,
          [TD_COMMON_COLUMN_VALUE]:
            this.capitalizeFirstLetter(categorySelected),
          [TD_TIME_SPENT_IN_SECS]: (new Date() - eventStartTime) / 1000,
        };
        trackComplyEvents.complianceCheckerReviewDetailsCategoryReject(
          this.currentUser,
          otherData,
        );
      }
    },
    applyFullDeckCompliance() {
      this.fullDeckChanges(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.compliance-deck-suggestions-container {
  height: 100%;
  .suggestions-actions {
    position: absolute;
    bottom: 12px;
    background-color: white;
    border-radius: 16px;
  }
}
.text-link {
  background: none;
  border: none;
  padding: 1px !important;
  font-size: inherit;
  cursor: pointer;
  text-transform: none;
  letter-spacing: normal;
}
.compliance-deck-suggestions {
  padding: 16px 0;
  margin: 16px;
}

.slide-compliance-deck-suggestions {
  border-radius: 8px;
  background: #f2f4f8;
  align-items: center;
}

.score-text-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.score-text {
  font-size: 16px;
  font-weight: 600;
}

.score-percentage {
  font-size: 20px;
  font-weight: 700;
}

.list-wrapper {
  border-radius: 8px;
  border: 1px solid #f2f4f8;
  // height: 88px;
  margin-bottom: 16px;
  // margin-right: 5px;

  ::v-deep .v-list-item-content {
    padding: 16px 0;
  }
}

.suggestion-details {
  display: flex;
  // flex-direction: row;
  // align-items: baseline;
  font-size: 16px;
  // gap: 8px;
  height: 24px;
  // width: 442px;
  // justify-content: space-between;
  // margin-top: 12px;

  &__item {
    display: flex;
    align-items: center;
  }

  &__total {
    color: #697077;
    font-size: 12px;
  }

  &__item-text {
    margin-left: 10px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-right: 2px;
  }

  &__description {
    color: #212121;
    font-weight: 400;
    letter-spacing: 0.24px;
    padding: 18px 0 16px 25px;
    // padding: 16px 0;
    // width: 412px;
  }

  ::v-deep .v-list-item__action--stack {
    align-items: flex-end;
    align-self: center;
    white-space: nowrap;
    flex-direction: row;

    // > button {
    //   margin-right: 10px; // Adds space between buttons, adjust the value as needed
    // }
  }
}

.score {
  margin-left: 8px;
}

.suggestions-list {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100% - 230px);
}

.app-button {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: none;
  letter-spacing: normal;
}

.complied-message {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  gap: 40px;
  justify-content: center;

  &__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 14px;
    max-width: 70%;
    text-align: center;
  }

  &__description {
    font-weight: 600;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .score-percentage {
    color: #21a7e0;
    font-size: 20px;
    font-weight: 700;
  }
}

.loader-wrapper {
  color: #000;
  display: flex;
  width: 540px;
  height: 120px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;

  border-radius: 12px;
  background: var(--color-styles-old-white, #fff);

  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16),
    0px 0px 6px 0px rgba(0, 0, 0, 0.16);
}
</style>
