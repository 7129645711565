<template>
  <div class="slide-actions-row" ref="actionsContainer" v-if="actions.length">
    <div class="horz-actions">
      <template v-for="(action, index) in horzActionList">
        <v-btn
          :key="index"
          rounded
          color="primary"
          text
          @click="() => handleActionIconClick(action)"
          :class="action.id === selectedSlideAction.id ? 'active' : ''"
          :disabled="action.disabled"
          v-if="action.visible"
          :data-pendo-id="action.pendoId ? action.pendoId : null"
          :name--auto="`${action.automationText}${
            action.id === selectedSlideAction.id ? '--active' : ''
          }`"
        >
          <div class="slide-action-content">
            <component
              :is="getIcon(action)"
              :iconWidth="20"
              :iconHeight="20"
              iconColor="#21a7e0"
              :iconStrokeWidth="0.5"
              v-if="action.isCustomIcon"
            />
            <v-icon dark size="20" class="slide-action-icon">
              {{ action.icon }}
            </v-icon>
            <span>{{ action.labelText || $t(action.label) }}</span>
          </div>
        </v-btn>
      </template>
    </div>
    <div
      class="more-actions"
      data-pendo-id="slide-action-more-options"
      ref="moreOption"
      v-if="moreOptionList.length"
    >
      <v-menu
        top
        absolute
        offset-y
        :close-on-click="true"
        :position-x="0"
        :position-y="0"
      >
        <template #activator="menu">
          <v-btn rounded color="primary" text v-on="{ ...menu.on }">
            <v-tooltip top max-width="200" fixed>
              <template v-slot:activator="{ on, attrs }">
                <div class="tooltip-activator" v-bind="attrs" v-on="on">
                  <v-icon dark size="20"> mdi-dots-vertical </v-icon>
                  <span>More options</span>
                </div>
              </template>
              <span>{{ $t('slides.moreActions') }}</span>
            </v-tooltip>
          </v-btn>
        </template>
        <v-list>
          <template v-for="(action, index) in moreOptionList">
            <v-list-item
              :key="index"
              :disabled="action.disabled"
              @click="() => handleActionIconClick(action)"
              v-if="action.visible"
              :name--auto="action.automationText"
            >
              <v-list-item-title>
                <div
                  class="otherActions"
                  :class="{ disabled: action.disabled }"
                >
                  <div class="icon">
                    <component
                      :is="getIcon(action)"
                      :iconWidth="20"
                      :iconHeight="20"
                      :iconColor="
                        selectedSlideAction.id === action.id
                          ? `#21a7e0`
                          : `rgba(0, 0, 0, 0.54)`
                      "
                      :iconStrokeWidth="0.5"
                      v-if="action.isCustomIcon"
                    />
                    <v-icon
                      v-else
                      size="20"
                      :color="
                        selectedSlideAction.id === action.id
                          ? `#21a7e0`
                          : `balck`
                      "
                    >
                      {{ action.icon }}
                    </v-icon>
                  </div>
                  <div
                    class="actionName"
                    :style="{
                      color: `${
                        selectedSlideAction.id === action.id
                          ? `#21a7e0`
                          : `balck`
                      }`,
                    }"
                  >
                    {{ action.labelText || $t(action.label) }}
                  </div>
                </div>
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { slideActions } from '../config/actionConfig';
import {
  TD_AUD,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_TEMPLATE,
  TD_PREZENTATIONAUTHOR,
} from '../../../common/Analytics/MatomoTrackingDataHelper';
import { trackPrezentationEvents } from '../../../common/Analytics/PrezentationEvents';
import { trackBuildEvents } from '../../../common/Analytics/BuildEvents';
import FormatIcon from '../../../common/Icons/FormatIcon.vue';
import EmotionIcon from '../../../common/Icons/EmotionIcon.vue';
import ToneIcon from '../../../common/Icons/ToneIcon.vue';
import DataStyleIcon from '../../../common/Icons/DataStyleIcon.vue';

export default {
  props: {
    rightPanelOpen: {
      type: Function,
      default: () => {},
    },
    showRightPanel: {
      type: Boolean,
    },
    origin: {
      type: String,
      required: false,
      default: 'prezentation',
    },
  },
  data() {
    return {
      actions: [],
      horzActionList: [],
      moreOptionList: [],
      iconMap: {
        'format-icon': FormatIcon,
        'emotion-icon': EmotionIcon,
        'tone-icon': ToneIcon,
        'data-style-icon': DataStyleIcon,
      },
    };
  },
  watch: {
    selectedMainAction(val, oldVal) {
      console.log(val, oldVal);
      if (val?.id)
        this.actions = JSON.parse(JSON.stringify(slideActions[val?.id] || []));
      else this.actions = [];
      setTimeout(() => {
        this.getDimensions();
        if (val?.id && val?.id !== oldVal?.id && val?.id === 'editSlide') {
          this.handleDefaultSlideaction();
        }
      }, 300);
    },
    selectedSlide: {
      handler() {
        this.getDimensions();
      },
      deep: true,
    },
    selectedSlideAction() {
      setTimeout(() => {
        this.getDimensions();
      }, 1);
    },
    inListViewMode() {
      this.getDimensions();
    },
    showRightPanel() {
      setTimeout(() => {
        this.getDimensions();
      }, 300);
    },
  },
  computed: {
    ...mapState('prezentationDetails', [
      'selectedMainAction',
      'selectedSlideAction',
      'selectedSlide',
      'selectedSlideIndex',
      'selectedSectionIndex',
      'inListViewMode',
      'prezentationData',
      'slideActions',
      'actionCompletedInfo',
    ]),
    ...mapState('users', ['currentUser', 'themes']),
    ...mapGetters('users', [
      'getUploadAccessLevel',
      'allowReplaceImageOverall',
      'allowReplaceIconOverall',
      'allowReplaceImageTabs',
      'allowReplaceIconTabs',
    ]),
    ...mapGetters('prezentationDetails', [
      'isViewAccess',
      'isRestrictedAccess',
      'isOpPrezentation',
      'isBestPractices',
      'showBestPracticeToggle',
      'isPrezentSourceBestPractice',
      'isUserUploadedPrezentation',
      'showShareOption',
      'isIOCUpload',
      'isGenerated',
      'isRedesigned',
      'isComply',
      'isTemplateRemoved',
      'currentItemIsEditable',
      'isPartialAccess',
    ]),

    isOwnerOfPrezentation() {
      return this.prezentationData?.ownerID === this.currentUser?.user?.id;
    },
  },
  methods: {
    ...mapActions('prezentationDetails', [
      'setSelectedSlideAction',
      'setSelectedQuickAction',
      'setInListViewMode',
      'setAddSlideActions',
      'setFromMosaicView',
    ]),
    getIcon(action) {
      return this.iconMap[action?.icon];
    },
    handleMatomoEvents(slideAction) {
      const otherData = {
        [TD_AUD]: this.prezentationData.audienceID,
        [TD_PREZNAME]: this.prezentationData.name,
        [TD_PREZTYPE]: this.prezentationData.type,
        [TD_TEMPLATE]: this.prezentationData.theme,
        [TD_PREZENTATIONAUTHOR]:
          slideAction === 'addToSlides' ? this.prezentationData.author : '',
      };
      if (this.origin === 'build') {
        switch (slideAction) {
          case 'changeLayout':
            trackBuildEvents.buildChangeLayoutClick(
              this.currentUser,
              otherData,
            );
            break;
          case 'changeVisual':
            trackBuildEvents.buildReplaceVisualClick(
              this.currentUser,
              otherData,
            );
            break;
          case 'changeNodeCount':
            trackBuildEvents.buildEditNodeCountClick(
              this.currentUser,
              otherData,
            );
            break;
          case 'editTag':
            trackBuildEvents.buildEditTagtClick(this.currentUser, otherData);
            break;
          default:
            break;
        }
      } else {
        switch (slideAction) {
          case 'changeLayout':
            trackPrezentationEvents.prezentationsEditSlideChangeLayoutClick(
              this.currentUser,
              otherData,
            );
            break;
          case 'changeVisual':
            trackPrezentationEvents.prezentationsReplaceVisualsClicked(
              this.currentUser,
              otherData,
            );
            break;
          case 'changeNodeCount':
            trackPrezentationEvents.prezentationsEditNodeCountClicked(
              this.currentUser,
              otherData,
            );
            break;
          case 'editTag':
            trackPrezentationEvents.prezentationsEditTagClicked(
              this.currentUser,
              otherData,
            );
            break;
          case 'editBestPractices':
            trackPrezentationEvents.prezentationsEditBestPracticesClicked(
              this.currentUser,
              otherData,
            );
            break;
          case 'addToSlides':
            trackPrezentationEvents.prezentationsThumbnailClickAddToSlides(
              this.currentUser,
              otherData,
            );
            break;
          default:
            break;
        }
      }
    },
    handleAddSlideActions() {
      const temp = [];
      if (this.selectedMainAction?.id === 'editSlide') {
        this.actions.forEach((itm) => {
          const avaliableAction = this.getAction(itm);
          if (avaliableAction.visible) {
            const tempObj = {
              ...avaliableAction,
              id: avaliableAction.id,
              labelText: avaliableAction.labelText,
              tooltipText: avaliableAction.tooltipText,
              icon: avaliableAction.icon,
              isCustomIcon: avaliableAction.isCustomIcon,
            };
            if (avaliableAction.noRightPanel) {
              tempObj.noRightPanel = avaliableAction.noRightPanel;
            }
            temp.push(tempObj);
          }
        });
      }
      this.setAddSlideActions(temp);
    },
    navigateToUploadFlow() {
      const prezentationId = (this.prezentationData || {}).id;
      const route = `/home/upload/${prezentationId}/transfer`;
      this.$router.push(route);
    },
    handleActionIconClick(data) {
      if (
        data.id !== 'deleteSlide' &&
        data.id !== 'duplicateSlide' &&
        !this.inListViewMode
      ) {
        this.setFromMosaicView(true);
        this.setInListViewMode(true);
      }
      if (data.id === 'deleteSlide') {
        this.$root.$emit(
          'deleteSlide',
          this.selectedSlideIndex,
          this.selectedSectionIndex,
        );
      } else if (data.id === 'duplicateSlide') {
        this.$root.$emit(
          'duplicateSlide',
          this.selectedSlide,
          this.selectedSlideIndex,
          this.selectedSectionIndex,
        );
      } else if (data.id === 'addToSlides') {
        this.navigateToUploadFlow();
      }

      if (this.rightPanelOpen) this.rightPanelOpen(!data.noRightPanel);
      this.setSelectedQuickAction({});
      if (
        data.id !== 'deleteSlide' &&
        data.id !== 'duplicateSlide' &&
        data.id !== 'addToSlides'
      ) {
        this.setSelectedSlideAction(data);
      }
      this.handleMatomoEvents(data.id);
      setTimeout(() => {
        this.getDimensions();
      }, 1);
    },

    getAction(action) {
      // Note: Update getAction in PrezentationDetails as well for smart tools
      switch (action.id) {
        case 'editTag':
          if (
            this.selectedSlide &&
            (this.selectedSlide.isUserUploaded ||
              this.selectedSlide.isGenerated ||
              this.selectedSlide.isRedesign ||
              this.selectedSlide.isSynthesis ||
              this.selectedSlide.isAnonymized ||
              this.selectedSlide.source === 'generated' ||
              this.selectedSlide.source === 'synthesis' ||
              this.selectedSlide.source === 'comply') &&
            this.prezentationData.prezentationType !== 'Shell' &&
            !this.isViewAccess
          ) {
            action.disabled = false;
            action.visible = true;
          } else {
            action.disabled = true;
            action.visible = false;
          }
          return action;

        case 'changeLayout':
        case 'changeVisual':
        case 'changeNodeCount':
          if (
            this.selectedSlide &&
            (this.selectedSlide.isUserUploaded ||
              this.selectedSlide.isUploaded ||
              this.selectedSlide.isGenerated ||
              this.selectedSlide.isSynthesis ||
              this.selectedSlide.isOPUploaded ||
              this.selectedSlide.isRedesign ||
              this.selectedSlide.isComply ||
              this.selectedSlide.source === 'generated' ||
              this.selectedSlide.source === 'uploaded' ||
              this.selectedSlide.source === 'comply' ||
              this.selectedSlide.source === 'synthesis' ||
              this.selectedSlide.source === 'redesign' ||
              this.isOpPrezentation)
          ) {
            action.disabled = true;
            action.visible = false;
            if (
              action.id === 'changeVisual' &&
              !(
                this.selectedSlide.isComply ||
                this.selectedSlide.source === 'comply'
              )
            ) {
              action.disabled = false;
              action.visible = true;
            }
          } else if (
            this.selectedSlide?.prefs?.is_know === 'yes' &&
            action.id === 'changeVisual'
          ) {
            action.disabled = true;
            action.visible = false;
          } else if (
            this.selectedSlide?.isIOCUploaded &&
            action.id === 'changeVisual'
          ) {
            action.disabled = false;
            action.visible = true;
          } else if (
            this.selectedSlide?.prefs?.is_know === 'yes' &&
            action.id === 'changeLayout'
          ) {
            action.disabled = true;
            action.visible = false;
          } else if (
            (this.selectedSlide?.isAddedFromBestpracticeDecks ||
              (this.selectedSlide.isAnonymized &&
                this.prezentationData.prezentationType !== 'Shell') ||
              this.selectedSlide?.isIOCUploaded) &&
            (action.id === 'changeLayout' || action.id === 'changeNodeCount')
          ) {
            action.disabled = true;
            action.visible = false;
          } else {
            action.disabled = false;
            action.visible = true;
          }

          if (this.prezentationData.convertedTo === 'Comply') {
            if (
              action.id === 'changeNodeCount' &&
              this.prezentationData.prezentationType === 'Shell'
            ) {
              action.disabled = true;
              action.visible = true;
            } else if (
              action.id !== 'changeNodeCount' &&
              this.prezentationData.prezentationType === 'Shell'
            ) {
              action.disabled = false;
              action.visible = true;
            }

            if (
              action.id !== 'changeVisual' &&
              this.prezentationData.prezentationSource === 'Generated'
            ) {
              action.disabled = true;
              action.visible = false;
            }
          }
          if (
            action.id === 'changeVisual' &&
            !(
              (this.allowReplaceImageOverall && this.allowReplaceImageTabs) ||
              (this.allowReplaceIconOverall && this.allowReplaceIconTabs)
            )
          ) {
            action.disabled = true;
            action.visible = false;
          }

          if (
            this.selectedSlide.isSynthesis &&
            this.selectedSlideIndex === 0 &&
            this.actionCompletedInfo?.synthesisCompleted
          ) {
            action.disabled = false;
            action.visible = true;
          }

          return action;

        case 'editBestPractices':
          if (
            this.selectedSlide &&
            (this.selectedSlide.isUserUploaded ||
              this.selectedSlide.isUploaded ||
              this.selectedSlide.isGenerated ||
              this.selectedSlide.isSynthesis ||
              this.selectedSlide.isRedesign ||
              this.selectedSlide.source === 'generated' ||
              this.selectedSlide.source === 'uploaded' ||
              this.selectedSlide.source === 'comply' ||
              this.selectedSlide.source === 'synthesis' ||
              this.selectedSlide.source === 'redesign') &&
            this.prezentationData.isBestPracticesEnabled
          ) {
            action.disabled = this.isViewAccess;
            action.visible = !this.isViewAccess;
          } else if (
            this.prezentationData &&
            this.prezentationData.prezentationSource === 'Product'
          ) {
            action.visible = false;
            action.disabled = true;
          } else if (
            this.isOwnerOfPrezentation ||
            this.selectedSlide.isAnonymized
          ) {
            action.disabled = false;
            action.visible = true;
          } else {
            action.disabled = true;
            action.visible = false;
          }

          return action;

        case 'addToSlides':
          if (
            this.getUploadAccessLevel &&
            this.prezentationData &&
            this.prezentationData.prezentationSource === 'User Uploaded' &&
            this.isOwnerOfPrezentation &&
            !this.selectedSlide.isSynthesis
          ) {
            action.disabled = false;
            action.visible = true;
          } else {
            action.disabled = true;
            action.visible = false;
          }

          return action;

        case 'changeFormat':
        case 'changeEmotion':
        case 'dataStyle':
        case 'changeTone':
          if (
            !this.isViewAccess &&
            !this.isTemplateRemoved(this.themes) &&
            this.selectedSlide.isSynthesis &&
            this.selectedSlideIndex === 0 &&
            this.actionCompletedInfo?.synthesisCompleted
          ) {
            action.disabled = false;
            action.visible = true;
          } else {
            action.disabled = true;
            action.visible = false;
          }
          return action;

        case 'duplicateSlide':
        case 'deleteSlide':
          if (
            !this.isViewAccess &&
            !this.isTemplateRemoved(this.themes) &&
            !this.isOpPrezentation
          ) {
            action.disabled = false;
            action.visible = true;
          } else {
            action.disabled = true;
            action.visible = false;
          }
          return action;

        default:
          return action;
      }
    },
    getDimensions() {
      this.moreOptionList = [];
      let tempList = [...this.actions];

      if (!this.inListViewMode) {
        tempList = tempList.filter(
          (action) =>
            action.id !== 'duplicateSlide' && action.id !== 'deleteSlide',
        );
      }
      this.horzActionList = tempList
        .map((action) => ({
          ...this.getAction(action),
          automationText: action?.id,
        }))
        .filter((a) => a.visible);

      if (this.$refs.actionsContainer) {
        setTimeout(() => {
          const totalWidth = this.$refs.actionsContainer.clientWidth;

          let finalWidth = 0;
          const horzActionList = [];
          const e = this.$refs.actionsContainer.children[0];

          e.children.forEach((ele, i) => {
            finalWidth += ele.clientWidth + 2;
            if (finalWidth <= totalWidth) {
              horzActionList.push(this.horzActionList[i]);
            }
          });

          if (horzActionList.length < this.horzActionList.length) {
            horzActionList.pop();

            this.moreOptionList = [
              ...this.horzActionList.slice(horzActionList.length),
            ];
          }

          this.horzActionList = horzActionList;
        }, 1);
      }
      this.handleAddSlideActions();
    },
    handleDefaultSlideaction() {
      const temp = this.slideActions.find(
        (item) => item.id === this.selectedSlideAction.id,
      );
      if (!temp && this.checkDuplicateAction()) {
        this.setSelectedSlideAction(this.slideActions[0]);
        if (this.rightPanelOpen)
          this.rightPanelOpen(!this.slideActions[0]?.noRightPanel);
      }
    },
    checkDuplicateAction() {
      if (
        this.slideActions[0]?.id === 'addToSlides' ||
        this.slideActions[0]?.id === 'duplicateSlide' ||
        this.slideActions[0]?.id === 'deleteSlide'
      ) {
        return false;
      }
      return true;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getDimensions);
  },
  mounted() {
    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();
  },
};
</script>

<style lang="scss" scoped>
.slide-actions-row {
  margin-top: 2px;
  display: flex;

  .horz-actions {
    .slide-action-icon {
      margin-right: 2px;
    }
    .v-btn {
      padding-left: 13px;
    }
  }

  .v-btn {
    text-transform: none;
    letter-spacing: normal;

    &.active::before {
      background-color: #21a7e0;
      opacity: 0.08;
    }
  }

  .tooltip-activator {
    display: flex;
    align-items: center;
  }
}

.otherActions {
  display: flex;
  align-items: center;
  gap: 3px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #000000;

  &.disabled {
    color: rgba(0, 0, 0, 0.38);
    pointer-events: none;

    ::v-deep .v-icon {
      color: rgba(0, 0, 0, 0.38) !important;
      caret-color: rgba(0, 0, 0, 0.38) !important;
    }
  }
}
.slide-action-content {
  display: flex;
  align-items: center;
}
</style>
