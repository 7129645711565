<template>
  <div class="share-upload-prezentation">
    <template v-if="!isUploading">
      <h1 class="title">
        {{ $t('profile.shareTemplateAccess.shareWithColleague') }}
      </h1>
      <div class="manage-slides" data-pendo-id="library-selection">
        <div class="sharing-modal__title font-weight-bold">
          {{ $t('upload.selectTheLibrary') }}
        </div>
        <v-checkbox
          v-model="addToPrezentations"
          :label="$t('uploadFlow.select_library_to_add_prez_exchange_option')"
          hide-details
          :disabled="isTransferFlow"
        ></v-checkbox>
        <v-checkbox
          v-model="addToSlides"
          :label="$t('uploadFlow.select_library_to_add_slide_lib_option')"
          hide-details
        ></v-checkbox>
      </div>
    </template>
    <div class="share-wrapper">
      <SharePrezentation
        v-if="!isUploading"
        :prezentation="prezentationDetails"
        origin="upload-prezentation"
        :prezentationUpdatedFn="handleSubmitActions"
        :title="$t('tagSlides.setAccessLevel')"
        :source="`upload-feature`"
        :addToPrezentations="addToPrezentations"
        :addToSlides="addToSlides"
        parentComponent="ShareUploadPrezentation"
      />
    </div>

    <div v-if="isUploading && waitingStep === 1" class="waiting-block">
      <clip-loader
        :loading="isUploading"
        :color="`#21a7e0`"
        :width="20"
        :height="20"
      />
      <br />
      <h2>{{ $t('upload.preparingPrezentations') }}</h2>
      <div class="image">
        <img
          src="/assets/img/uploader/preparing-prezentation.svg"
          alt
          width="350px"
        />
      </div>
    </div>
    <div v-if="isUploading && waitingStep === 2" class="waiting-block">
      <clip-loader
        :loading="isUploading"
        :color="`#21a7e0`"
        :width="20"
        :height="20"
      />
      <br />
      <h2>{{ $t('upload.addingIndividualSlides') }}</h2>
      <div class="image">
        <img
          src="/assets/img/uploader/adding-individual-slides.svg"
          alt
          width="350px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { mapState } from 'vuex';
import { UploadService } from '../../../services/UploadService';
import SharePrezentation from '../Prezentations/SharePrezentation.vue';
import UploadSuccessModalVue from './UploadSuccessModal.vue';
import TransferSlidesSuccessModal from './TransferSlidesSuccessModal.vue';
import UploadPrezentationConfirmationModal from './UploadPrezentationConfirmationModal.vue';
import audienceApi from '../../../API/audience-api';

export default {
  name: 'ShareUploadPrezentation',
  components: { SharePrezentation, ClipLoader },
  props: {
    prezentationDetails: {
      type: Object,
      default: null,
    },
    setIsUploading: {
      type: Function,
      default: () => ({}),
    },
    isTransferFlow: Boolean,
    toggleIsFileUploaded: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      isUploading: false,
      addToSlides: false,
      addToPrezentations: true,
      error: '',
      waitingStep: 1,
    };
  },
  beforeMount() {
    if (this.isTransferFlow) {
      this.addToSlides = true;
      this.addToPrezentations = false;
      this.waitingStep = 2;
    }
  },
  mounted() {
    if (this.uploadFlowCTAOrigin && this.uploadFlowCTAOrigin !== '') {
      if (this.uploadFlowCTAOrigin === 'slide_library') {
        this.addToSlides = true;
        this.addToPrezentations = false;
      } else if (this.uploadFlowCTAOrigin === 'prezentation_exchange') {
        this.addToSlides = false;
        this.addToPrezentations = true;
      }
    }
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'startLoadingSlidesFlag',
      'uploadFlowCTAOrigin',
    ]),
  },
  watch: {},
  methods: {
    async getAudianceDetails() {
      const audience =
        await audienceApi.methods.getPrezentationAudienceTypesense(
          this.prezentationDetails.audienceID,
          this.prezentationDetails.audienceOwnerID,
          {
            fields: ['id', 'num_id', 'email', 'type', 'ownerID', 'fingerPrint'],
          },
        );
      if (audience) {
        return {
          ...audience,
          audience_type: audience.type,
          userId: audience.ownerID,
        };
      }
      return null;
    },
    async handleSubmitActions(data) {
      this.toggleIsFileUploaded(false);
      if (data) {
        this.setIsUploading(true);
        this.isUploading = true;

        if (this.addToSlides && !this.addToPrezentations) {
          this.waitingStep = 2;
        }

        if (this.addToSlides && this.addToPrezentations) {
          setTimeout(() => {
            this.waitingStep = 2;
          }, 1000);
        }
        const { addToPrezentations, addToSlides } = this;

        const payload = {
          addToPrezentations,
          addToSlides,
          ...this.prezentationDetails,
        };

        if (this.isTransferFlow) {
          payload.selectedAudience = await this.getAudianceDetails();
        }

        UploadService.processUploadDeck(payload, data)
          .then((response) => {
            console.log(response);

            if (addToPrezentations) {
              if (response.data?.prezentation) {
                if (addToSlides) {
                  this.$modal.show(
                    UploadPrezentationConfirmationModal,
                    {},
                    {
                      name: 'UploadPrezentationConfirmationModal',
                      width: '525px',
                      height: '494px',
                      styles: { borderRadius: '20px' },
                    },
                  );
                } else {
                  this.$modal.show(
                    UploadSuccessModalVue,
                    {
                      prezentationId: response.data.prezentation.id,
                    },
                    {
                      name: 'UploadSuccessModal',
                      width: '449px',
                      height: '520px',
                      styles: { borderRadius: '20px' },
                    },
                  );
                }
              }

              this.$router.push(`/home/prezentations`);
            } else {
              if (response.data?.slides) {
                this.$modal.show(
                  TransferSlidesSuccessModal,
                  {},
                  {
                    name: 'TransferSlidesSuccessModal',
                    width: '449px',
                    height: '380px',
                    styles: { borderRadius: '20px' },
                  },
                );
              }
              this.$router.push(`/home/slides?type=uploaded`);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isUploading = false;
            this.setIsUploading(false);
          });
      }
      this.error = data.error;
    },
  },
};
</script>

<style lang="scss" scoped>
.share-upload-prezentation {
  width: 60%;

  .waiting-block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .image {
      margin-top: 20px;
    }
  }

  .manage-slides {
    padding: 2rem 2rem 0 2rem;
  }

  .title,
  .sub-title,
  .virbage {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }

  .title {
    font-size: 28px !important;
    font-family: 'Lato' !important;
    font-weight: bold;
  }

  .sub-title {
    font-family: 'Lato' !important;
    font-size: 24px;
  }

  .virbage {
    font-family: 'Lato' !important;
    font-size: 24px;
  }

  ::v-deep .theme--light.v-label {
    color: #000000;

    &--is-disabled {
      color: rgba(0, 0, 0, 0.38);
    }
  }

  .share-wrapper {
    position: relative;
  }

  ::v-deep .close-btn {
    display: none;
  }

  .main-wrapper {
    overflow: visible !important;
  }
}

.sharing-modal__title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: #000000;
}
</style>
