var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"compliance-deck-suggestions-container"},[(!_vm.getDeckFullComplianceInfo)?_c('div',{staticClass:"compliance-deck-suggestions"},[_c('v-row',{staticClass:"slide-compliance-deck-suggestions"},[_c('v-col',{staticClass:"score-text-col",attrs:{"cols":"8"}},[_c('span',{staticClass:"score-text",attrs:{"data-pendo-id":"comply-deck-compliance-score"}},[_vm._v(" Brand Compliance Score ")]),_c('span',{staticClass:"score-percentage"},[_vm._v(_vm._s(_vm.getComplianceScore)+"%")])]),_c('v-col',{staticClass:"progress-col",attrs:{"cols":"4"}},[_c('v-progress-linear',{attrs:{"value":_vm.getComplianceScore,"color":_vm.complianceColor,"height":"7"}})],1)],1)],1):_vm._e(),[(!_vm.getDeckFullComplianceInfo)?_c('v-row',{staticClass:"suggestions-list"},[_c('v-col',[_c('v-list',_vm._l((_vm.getDeckLevelCategorySuggestions),function(category,i){return _c('v-list-item',{key:i,staticClass:"list-wrapper"},[_c('v-list-item-content',[_c('div',{staticClass:"suggestion-details"},[_c('div',{staticClass:"suggestion-details__item"},[_c('v-avatar',{attrs:{"color":category.avatarColor,"size":16}}),_c('span',{staticClass:"suggestion-details__item-text"},[_vm._v(" "+_vm._s(category.text)+" ")]),_c('v-icon',{attrs:{"color":'#D9D9D9'}},[_vm._v("mdi-circle-small")]),_c('span',{staticClass:"suggestion-details__total"},[_vm._v(" "+_vm._s(_vm.getSuggestionsCountText(category))+" ")])],1),(
                    category.key !== 'template' &&
                    !_vm.isSuggestionsResolved(category)
                  )?_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.applyDeckCompliance(category.key, 'accept')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#00925a"}},[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Accept")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.applyDeckCompliance(category.key, 'reject')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#d32f2f"}},[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Reject")])])],1):_vm._e()],1),(
                  !(_vm.isReplaceBrandImageEnabled && _vm.allowReplaceImageOverall) &&
                  category.key === 'images'
                )?_c('div',{staticClass:"suggestion-details__description"},[_vm._v(" No brand images available to check compliance. "),_c('a',{staticClass:"text-link",on:{"click":_vm.onGetInTouchclick}},[_vm._v(" Get in touch ")]),_vm._v(" to add brand images. ")]):_c('div',{staticClass:"suggestion-details__description"},[_vm._v(" "+_vm._s(category.description)+" ")])])],1)}),1)],1)],1):_vm._e(),(_vm.getDeckFullComplianceInfo)?_c('v-row',[_c('v-col',[_c('div',{staticClass:"complied-message"},[_c('v-img',{attrs:{"max-height":"45%","src":"/assets/img/comply/brand-compliance-achieved.svg"}}),_c('div',{staticClass:"complied-message__wrapper"},[_c('div',[_vm._v(" Congratulations! Your prezentation is "),_c('span',{staticClass:"score-percentage"},[_vm._v(" 100% ")]),_vm._v(" compliant with brand guidelines for your selected template. ")])]),_c('div',{staticClass:"complied-message__actions"},[_c('v-btn',{staticClass:"app-button",attrs:{"text":"","rounded":"","color":"primary"},on:{"click":_vm.handleSelectDifferentTemplate}},[_vm._v(" Select a different template ")])],1)],1)])],1):_vm._e()],(!_vm.getDeckFullComplianceInfo)?_c('v-row',{staticClass:"suggestions-actions"},[_c('v-col',{staticClass:"d-flex align-baseline"},[_c('div',{staticClass:"buttons-content"},[_c('v-btn',{staticClass:"app-button",attrs:{"rounded":"","color":"primary","data-pendo-id":"comply-make-hundred-compliant-button"},on:{"click":_vm.applyFullDeckCompliance}},[_vm._v(" Accept all suggestions ")])],1)])],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }